import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useIdentity } from 'pages/Identity/Login'
import { snackbarTypes } from 'data/constants'
import { fetchDispatch } from 'data/helpers'
import { getHospitalJobs, setSelectedFilters } from 'data/jobs/actions'
import { mapJobData } from 'data/jobs/reducer'
import { showSnackbar } from 'data/snackbar/actions'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import { capitalizeWords } from 'common/helpers'

const initialState = { job: {}, loading: false }

export const cleanJobData = job => {
  let cleanedJob = { ...job }
  if (cleanedJob.city) {
    cleanedJob.city = capitalizeWords(cleanedJob.city.trim())
  }
  if (cleanedJob.title) {
    cleanedJob.title = cleanedJob.title.trim()
  }
  return cleanedJob
}
export function useJob(jobId) {
  const dispatch = useDispatch()
  const { employerId } = useIdentity()
  const [{ job, loading }, setState] = React.useState(initialState)
  // Update local state with job info and request status
  const onResponse = React.useCallback(
    ({ job }) => setState(prev => ({ ...prev, job: mapJobData([job])[0], loading: true })),
    [setState],
  )

  // Gets the employer user information from the server and updates the redux store with the results.  If no id is specified, use the current employer user id
  const getJob = React.useCallback(
    (id = jobId) => {
      setState(prev => ({ ...prev, loading: true }))
      fetchDispatch({ path: `/api/jobs/${id}`, method: 'GET', onResponse: onResponse })
    },
    [setState, onResponse, jobId],
  )

  // Updates db with new job information, closes dialog state or handles errors, and updates local state with the response and status
  const setJob = React.useCallback(
    (values, setState, OnResponse) => {
      setState(prev => ({ ...prev, loading: true }))
      fetchDispatch({
        path: `/api/jobs/${values.id}`,
        values: cleanJobData(values),
        setState,
        method: 'PUT',
        onResponse: json => {
          showSnackbar(dispatch, 'Successfully updated job.', snackbarTypes.SUCCESS)
          setState(prev => ({ ...prev, loading: false }))
          onResponse(json) // Call internal onResponse to update the redux store
          OnResponse && OnResponse(json) // Call external OnResponse to perform any other actions necessary. I.E change route, signInSuccess, etc
          dispatch(getHospitalJobs({ employerId: employerId }))
          // clear any selected filters when a job is edited
          dispatch(setSelectedFilters([]))
        },
        onError: () => {
          showSnackbar(dispatch, 'An error occurred when updating job.', snackbarTypes.ERROR)
          setState(prev => ({ ...prev, loading: false }))
        },
      })
    },
    [onResponse],
  )

  const createJob = React.useCallback(
    (values, setState, OnResponse, isEmployerAdmin) => {
      setState(prev => ({ ...prev, loading: true }))
      fetchDispatch({
        path: '/api/jobs',
        values: cleanJobData(values),
        setState,
        onResponse: json => {
          setState(prev => ({ ...prev, loading: false }))
          showSnackbar(dispatch, 'Successfully created job.', snackbarTypes.SUCCESS)
          dispatch(
            fetchTrackEvent({
              eventName: 'job_add_success',
              eventType: ProductAnalyticsEventTypes.ACTION,
              userId: json.job.employerUser.user.email,
              employerId: json.job.employer.id,
              eventData: { jobData: json.job },
            }),
          )
          onResponse(json) // Call internal onResponse to update the redux store
          OnResponse && OnResponse(json) // Call external OnResponse to perform any other actions necessary. I.E change route, signInSuccess, etc
          const employerUserIds =
            values.employerUserId && !isEmployerAdmin
              ? { employerUserIds: [values.employerUserId] }
              : null
          dispatch(getHospitalJobs({ employerId: employerId, ...employerUserIds })) // getHospitalJobs to refresh jobs list
          // clear any selected filters when a job is created
          dispatch(setSelectedFilters([]))
        },
        onError: () => {
          showSnackbar(dispatch, 'An error occurred when creating job.', snackbarTypes.ERROR)
          setState(prev => ({ ...prev, loading: false }))
        },
      })
    },
    [onResponse],
  )

  return [job, getJob, setJob, createJob, loading]
}
