import React from 'react'
import { Grid } from '@mui/material'
import Loader from 'components/Loader'
import { useSelector, useDispatch } from 'react-redux'
import { RealtorTable } from 'components/Table/RealtorTable'
import { EditDialogButton } from 'components/Dialog/DialogButton'
import { UploadRealtorPhotoButton } from 'components/Buttons/UploadRealtorPhotoButton'
import * as RealtorDialog from 'components/Dialog/RealtorDialog'
import { fetchAllRealtors } from 'src/redux/realtor'

const Realtors = () => {
  const dispatch = useDispatch()
  const { loading, realtors } = useSelector(state => state.realtors)
  const realtorData = realtors.map(realtor => ({
    ...realtor,
    actions: (
      <Grid container direction='row'>
        <EditDialogButton
          id={RealtorDialog.dialogId}
          initialValues={realtor}
          Module={RealtorDialog}
        />
        <UploadRealtorPhotoButton entry={realtor} />
      </Grid>
    ),
  }))
  React.useEffect(() => {
    dispatch(fetchAllRealtors())
  }, [])

  return <>{loading ? <Loader /> : <RealtorTable data={realtorData} />}</>
}
export default Realtors
