import React from 'react'
import { useSelector } from 'react-redux'
import { Container, Grid } from '@mui/material'
import NavigationTabs from '../NavigationTabs'
import AboutYou from './AboutYou'
import AboutJob from './AboutJob'
import Security from './Security'

const CandidateProfile = () => {
  const candidate = useSelector(state => state.candidate)
  const [activeTab, setActiveTab] = React.useState(0)
  const tabSteps = [
    { tab: 0, label: candidate.firstName, Component: <AboutYou candidate={candidate} /> },
    { tab: 1, label: 'About Job', Component: <AboutJob candidate={candidate} /> },
    { tab: 2, label: 'Security', Component: <Security candidate={candidate} /> },
  ]


  return (
    <Container sx={{ maxWidth: '70em', marginTop: '50px' }}>
      <Grid container direction='column'>
        <NavigationTabs activeTab={activeTab} setActiveTab={setActiveTab} tabSteps={tabSteps} />
        {tabSteps[activeTab].Component}
      </Grid>
    </Container>
  )
}

export default CandidateProfile
