import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Grid } from '@mui/material'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import DialogButton from 'components/Dialog/DialogButton'
import * as SaveFilterDialog from 'components/Dialog/SaveFilterDialog'
import { fetchFilterTypes } from '../../redux/filters'

export const SaveFiltersButton = ({ listType, selectedFilters, disabled }) => {
  const dispatch = useDispatch()
  const { filterTypes } = useSelector(state => state.filters)

  React.useEffect(() => {
    dispatch(fetchFilterTypes())
  }, [dispatch])

  const selectedFiltersArray = Object.values(selectedFilters)

  return (
    <DialogButton
      Module={SaveFilterDialog}
      size='small'
      variant='text'
      initialValues={{}}
      dialogId='Save Filter'
      disabled={disabled}
      filterTypes={filterTypes}
      listType={listType}
      selectedFilters={selectedFiltersArray}
      sx={{
        color: disabled ? 'gray' : 'white',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Typography
          sx={{
            fontSize: '.8rem',
            textTransform: 'none',
            color: disabled ? 'gray' : 'white',
          }}
        >
          Save Filters
        </Typography>
        <SaveAltIcon
          sx={{
            marginLeft: '5px',
            color: disabled ? 'gray' : 'white',
          }}
        />
      </Grid>
    </DialogButton>
  )
}
