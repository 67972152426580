import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Button } from '@mui/material'
import HeaderPhotoCard from 'components/Layout/Header/HeaderPhotoCard'
import ButtonMenuList from 'components/Utilities/ButtonMenuList'
import { useNavigate, useLocation } from 'react-router'
import { useChangeRoute } from 'data/hooks'
import { useIsUser } from 'pages/Identity/Login'
import { useWidth } from 'data/layout/actions'
import { evalFunc } from 'data/helpers'
import { profileHeaderButtons } from 'components/Layout/Header/buttons'
import { useHandleLogout } from 'components/Layout/Header/helpers'

export default function HeaderUserInfo() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userId, isCandidate, isEmployerUser, isEmployerAdmin, isSuper } = useIsUser()
  const candidate = useSelector(state => state.candidate)
  const employerUser = useSelector(state => state.employerUser)
  const superUser = useSelector(state => state.superUser)

  let firstName = null
  if (isCandidate) {
    firstName = candidate.firstName
  } else if (isEmployerUser) {
    firstName = employerUser.firstName
  } else if (isSuper) {
    firstName = superUser.firstName
  }

  const { pathname } = useLocation()
  const width = useWidth()

  const changeRoute = useChangeRoute()
  const handleLogout = useHandleLogout(dispatch, navigate)

  const menuItems = profileHeaderButtons({ isEmployerAdmin, isEmployerUser, handleLogout, userId })
    .filter(b => evalFunc(b.hidden, width) !== true)
    .map(b => ({
      ...b,
      active: b.link === pathname,
      onClick: b.link
        ? e => {
            b.onClick && b.onClick(e)
            changeRoute(b.link)
          }
        : b.onClick, // Connect change route onClick handler if a link is present
    }))

  const hasActive = menuItems.some(b => b.active)

  return (
    <ButtonMenuList menuItems={menuItems}>
      <Grid container alignItems='center' style={{ maxWidth: 320 }} spacing={1}>
        <Grid item>
          <HeaderPhotoCard />
        </Grid>
        <Grid item zeroMinWidth xs>
          <Button
            variant={hasActive ? 'contained' : 'text'}
            stylevariant='header'
            active={hasActive.toString()}
            fullWidth
            style={{ overflowX: 'hidden' }}
          >
            {firstName}
          </Button>
        </Grid>
      </Grid>
    </ButtonMenuList>
  )
}
