import {
  GET_SPECIALTIES,
  SET_SPECIALTIES,
  RESIZE_APPBAR,
  RESIZE_VIEWPORT,
  UPDATE_JOB_FILTERS_HEIGHT,
} from 'data/constants'
import theme from 'src/theme'

const initialState = {
  height: undefined,
  width: undefined,
  appBarHeight: theme.layout.toolbarHeight,
  loading: false,
  jobFiltersHeight: 0,
  specialties: [],
  subspecialties: [],
  referralSources: [],
}

export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case RESIZE_APPBAR:
      return {
        ...state,
        appBarHeight: 64,
      }
    case RESIZE_VIEWPORT:
      return {
        ...state,
        height: action.payload.height,
        width: action.payload.width,
      }
    case GET_SPECIALTIES:
      return {
        ...state,
        loading: true,
      }
    case SET_SPECIALTIES:
      return {
        ...state,
        specialties: action.payload.specialties || state.specialties,
        subspecialties: action.payload.subspecialties || state.subspecialties,
        referralSources: action.payload.referralSources || state.referralSources,
        loading: false,
      }
    case UPDATE_JOB_FILTERS_HEIGHT:
      return {
        ...state,
        jobFiltersHeight: action.payload,
      }
    default:
      return state
  }
}
