import * as React from 'react'
import { useDispatch } from 'react-redux'
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material'
import CSVButton from 'src/components/Buttons/CSVButton'
import { fetchPostJobCSV } from 'src/redux/csv'
import EnhancedTableHead from './EnhancedTableHead'
import { getComparator, stableSort, filterData } from './helpers'
import { SearchBar } from './SearchBar'

export default function GenericTable({
  name = 'Default Table',
  columns = [],
  rows = [],
  showCSV = false,
  searchableFields = [],
  addComponent = null,
  externalLink = null,
}) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('name')
  const [searchQuery, setSearchQuery] = React.useState('')
  let dataFiltered = rows

  // TODO: add multiple searchable fields
  dataFiltered = filterData(searchQuery, rows, searchableFields)

  React.useEffect(() => {
    setPage(0)
  }, [dataFiltered])

  const dispatch = useDispatch()
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }
  const uploadCSV = transformedData => dispatch(fetchPostJobCSV(transformedData))

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const visibleRows = React.useMemo(
    () =>
      stableSort(dataFiltered, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, dataFiltered],
  )

  return (
    <>
      <Grid
        container
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ padding: '10px 20px' }}
      >
        <Typography variant='h5'>{name}</Typography>
        {externalLink && (
          <Grid item>
            <a href={externalLink.link} target='_blank'>
              {externalLink.title}
            </a>
          </Grid>
        )}
        {addComponent && addComponent}
      </Grid>
      <Grid
        container
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ padding: '5px 20px' }}
      >
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        {showCSV && <CSVButton uploadCSV={uploadCSV} />}
      </Grid>
      <Paper sx={{ width: '100%', overflow: 'scroll' }}>
        <TableContainer sx={{ maxHeight: 800 }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={dataFiltered.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Table stickyHeader aria-label='sticky table'>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={dataFiltered.length}
              columns={columns}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                    {columns.map(column => {
                      const value = row[column.name]
                      const key = `${index}-${column.name}`
                      return (
                        <TableCell key={key} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={dataFiltered.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}
