import React from 'react'
import { Box } from '@mui/material'
import GenericRadioGroup from 'components/UserFields/GenericRadioGroup'
import { getAvailabilityOptions } from 'components/UserFields/helpers'
import GenericDatePicker from 'components/UserFields/GenericDatePicker'

const Timeline = ({ handleChange, values }) => {
  return (
    <Box sx={{ border: '1px solid gray', padding: '20px', margin: '20px 0px' }}>
      <GenericRadioGroup
        value={values.searchStatus}
        label='I am'
        name='searchStatus'
        handleChange={handleChange}
        options={getAvailabilityOptions()}
      />
      <GenericDatePicker
        value={values.availabilityDate}
        handleChange={handleChange}
        label='Availability Date'
        name='availabilityDate'
      />
    </Box>
  )
}

export default Timeline
