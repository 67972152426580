import React, { useEffect, useState } from 'react'
import { isNil } from 'lodash'
import { Grid, Typography, Box, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useIdentity } from 'pages/Identity/Login'
import { List } from 'components/Listing/List'
import { fetchAllRealtors } from 'src/redux/realtor'
import RealtorContent from './RealtorContent'

const Realtors = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const pageSize = 50
  const { signedIn } = useIdentity()
  const [activeItem, setActiveItem] = useState()
  const realtors = useSelector(state => state.realtors.realtors) || []
  const totalRealtors = realtors ? realtors.length : 0
  const totalPages = Math.ceil(totalRealtors / pageSize)
  const leftPanelHeader = 'Realtors: ' + `${totalRealtors ? totalRealtors.toLocaleString() : 0}`
  const [page, setPage] = useState(0)
  const handleListItemClick = currId => {
    const activeRealtor = !isNil(currId)
      ? realtors.find(p => `${p.id}` === `${currId}`)
      : realtors.length > 0
        ? realtors[0]
        : null
    setActiveItem(activeRealtor)
  }

  useEffect(() => {
    dispatch(fetchAllRealtors({}))
  }, [])

  useEffect(() => {
    setActiveItem(realtors[0])
  }, [realtors])

  useEffect(() => {
    dispatch(fetchAllRealtors({}, page))
  }, [page])

  return (
    <Grid container justifyContent='center'>
      <Grid
        container
        justifyContent='center'
        sx={{ backgroundColor: theme.palette.primary.main, padding: '10px' }}
      >
        <Grid container sx={{ maxWidth: '70em' }}>
          <Grid item xs={4} md={1}>
            <Typography
              variant='h6'
              sx={{
                border: `1px solid ${theme.palette.info.main}`,
                borderRadius: '25px',
                backgroundColor: `${theme.palette.info.main}`,
                color: 'white',
                fontSize: '.8rem',
                textAlign: 'center',
                alignSelf: 'center',
                marginRight: '10px',
              }}
            >
              BETA{' '}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant='overline' style={{ color: `white` }}>
              Moving for a new job is stressful so let us ease that burden!
            </Typography>
          </Grid>
          <Box display={{ xs: 'none', sm: 'none', md: 'contents' }}>
            <Grid item xs={12}>
              <Typography variant='caption' style={{ color: `white` }}>
                We're working on creating a network of agents and brokers across the country willing
                to work with providers while offering provider specific incentives. Check in
                regularly if you're looking to make a move!
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ maxWidth: '70em' }}>
        <Grid container sm={12} md={5} direction='row' sx={{ height: '100%' }}>
          <Grid item>
            <Typography variant='h6' sx={{ padding: '10px 40px' }}>
              {leftPanelHeader}
            </Typography>
          </Grid>
          <Grid container alignItems='flex-start' sx={{ maxHeight: '100vh', overflow: 'auto' }}>
            <List
              setPage={setPage}
              page={page}
              data={realtors}
              listType={'realtor'}
              handleListItemClick={handleListItemClick}
              dataTotal={totalRealtors}
              totalPages={totalPages}
              signedIn={signedIn}
            />
          </Grid>
        </Grid>
        <Box display={{ xs: 'none', sm: 'none', md: 'contents' }}>
          <Grid container md={7} direction='column' sx={{ maxHeight: '100vh', overflow: 'auto' }}>
            {activeItem && <RealtorContent item={activeItem} signedIn={signedIn} />}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Realtors
