import React from 'react'
import { sanitize } from 'dompurify'
import { Grid, Typography, Divider, Alert, Box, Link } from '@mui/material'
import ListDataItem from 'components/Listing/ListDataItem'
import { getImage, getJobListItems } from './helpers'
import JobActionsButton from './JobActionsButton'
import RecruiterCard from './RecruiterCard'
import SignUpCard from './SignUpCard'
import EditableJobButtons from './EditableJobButtons'
import { convertNewlinesToBreaks } from './helpers'

const JobContent = ({ item = {}, signedIn, employerUserId }) => {
  const fields = getJobListItems(item, signedIn) || []
  const displayableFields = fields.filter(field => field.show)
  const employerImage = getImage(item.employer.image)
  const candidateRating = item.candidateRating ? item.candidateRating : {}
  const deletedAt = 'Archived: ' + new Date(item.deletedAt).toDateString()
  const about = item.about !== '' && item.about !== null ? item.about : item.aboutRaw
  const aboutContent = convertNewlinesToBreaks(about)

  return (
    <div className='joyride_job_content'>
      <Grid container sx={{ padding: '20px 20px', height: 'auto', marginBottom: '100px' }}>
        <Grid container item spacing={1} alignItems='center' direction='row' xs={12}>
          <Grid container item xs={9}>
            <Typography variant='h4'>
              <Link
                href={`/jobs/${item.id}`}
                target='_blank'
                sx={{
                  color: 'inherit',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  },
                }}
              >
                <b>{item.title}</b>
              </Link>
            </Typography>
          </Grid>
          <>
            <Grid container item xs={9}>
              <Typography variant='h6'>
                <b>{item.employerName}</b>
              </Typography>
            </Grid>
            {item.deletedAt !== null && (
              <Grid container item xs={9}>
                <Alert icon={false} severity='info'>
                  {deletedAt}
                </Alert>
              </Grid>
            )}
            {signedIn && (
              <Grid container item xs={12} justifyContent='flex-start' spacing={2}>
                {employerUserId ? (
                  <EditableJobButtons job={item} />
                ) : (
                  <JobActionsButton jobId={item.id} candidateRating={candidateRating} />
                )}
              </Grid>
            )}
          </>
        </Grid>
        <Grid item xs={12} sx={{ margin: '10px 0px' }}>
          <Divider sx={{ height: '1px', marginBottom: '10px' }} />
        </Grid>
        <Grid container item xs={12} direction='row'>
          {signedIn ? (
            <Grid container item sm={12}>
              <RecruiterCard
                recruiter={item.employerUser}
                jobId={item.id}
                signedIn={signedIn}
                employerUserId={employerUserId}
              />
            </Grid>
          ) : (
            <Grid container item sm={12} sx={{ marginBottom: '50px' }}>
              <SignUpCard />
            </Grid>
          )}
          <Grid
            container
            item
            justifyContent='space-between'
            sm={12}
            md={12}
            rowSpacing={1}
            sx={{ marginBottom: '10px' }}
          >
            {displayableFields.map((field, index) => (
              <Grid item xs={6} key={index}>
                <ListDataItem
                  icon={field.icon}
                  boolValue={field.value}
                  text={field.text}
                  isLink={field.isLink}
                  link={field.link}
                />
              </Grid>
            ))}
          </Grid>
          {employerImage && (
            <Grid container item xs={12}>
              <Box
                component='img'
                sx={{
                  height: '100px',
                  width: '100%',
                  overflow: 'hidden',
                  objectFit: 'cover',
                  margin: '20px 0px',
                }}
                alt={item.employerName}
                src={employerImage}
              />
            </Grid>
          )}
          {about && (
            <Grid
              item
              xs={12}
              justifyContent='flex-start'
              sx={{ marginTop: '10px', marginBottom: '50px' }}
            >
              <Divider sx={{ height: '1px', margin: '20px 0px' }} />
              <Typography
                variant='body1'
                dangerouslySetInnerHTML={{ __html: sanitize(aboutContent) }}
              ></Typography>
            </Grid>
          )}
          {!about && item.link && !employerUserId && (
            <Typography variant='body1' sx={{ marginTop: '10px' }}>
              For more information about this job, please visit,{' '}
              <Link href={item.link} target='_blank'>
                {item.employerName}'s website
              </Link>
            </Typography>
          )}
          {item.employer.about && (
            <Grid
              item
              xs={12}
              justifyContent='flex-start'
              sx={{ marginTop: '10px', marginBottom: '50px' }}
            >
              <Divider sx={{ height: '1px' }} />
              <Typography variant='h4'>About {item.employerName}</Typography>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    dangerouslySetInnerHTML={{ __html: sanitize(item.employer.about) }}
                  ></Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default JobContent
