import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { isNil } from 'lodash'
import { useIdentity } from 'pages/Identity/Login'
import { Grid, Typography, Box } from '@mui/material'
import { List } from 'components/Listing/List'
import Loader from 'components/Loader'
import { getCandidateJobs, getHospitalJobs } from 'data/jobs/actions'
import DialogButton from 'components/Dialog/DialogButton'
import { fetchJobFilterValues } from 'src/redux/filters'
import JobContent from './JobContent'
import { Filters } from '../../components/Filters'
import { FilterType } from '../../components/Filters/filterDefinitions'
import * as JobDialog from 'components/Dialog/JobDialog'
import { fetchUpdateCandidate } from 'src/redux/candidate'
import AlertWrapper from 'components/Alerts/AlertWrapper'
import JobsPageTutorial from './JobsPageTutorial'
import { applyFilters } from 'components/Filters/helpers'
import RecruiterJobPageTutorial from './RecruiterJobPageTutorial'
const Jobs = () => {
  const signedIn = useSelector(state => state.identity.signedIn)
  const setupSeen = useSelector(state => state.candidate.setupSeen)
  const dispatch = useDispatch()
  const { employerId, employerUserId, candidateId } = useIdentity()
  const [activeItem, setActiveItem] = useState()
  const [page, setPage] = useState(0)
  const pageSize = 50
  const { jobsList = [], isLoadingJobs } = useSelector(state => state.jobs)
  const { isLoadingFilters } = useSelector(state => state.filters).loading
  const filterValues = useSelector(state => state.filters.jobFilterValues) || {}
  const totalJobs = useSelector(state => state.jobs.totalJobs)
  const totalPages = Math.ceil(totalJobs / pageSize)
  // TODO: Add loading jobs and loader
  const dataCall = employerUserId ? getHospitalJobs : getCandidateJobs
  const [searchParams] = useSearchParams()

  const handleListItemClick = currId => {
    const activeJob = !isNil(currId)
      ? jobsList.find(j => `${j.id}` === `${currId}`)
      : jobsList.length > 0
        ? jobsList[0]
        : null
    setActiveItem(activeJob)
  }

  useEffect(() => {
    if (!signedIn) {
      dispatch(dataCall({ page, pageSize }))
    } else {
      dispatch(fetchJobFilterValues(employerId))
    }
  }, [])

  useEffect(() => {
    setActiveItem(jobsList[0])
  }, [jobsList, searchParams])

  const leftPanelHeader = 'Results: ' + `${totalJobs ? totalJobs.toLocaleString() : 0}` + ' Jobs'
  let firstName
  if (employerUserId) {
    firstName = useSelector(state => state.employerUser.firstName)
  }
  if (candidateId) {
    firstName = useSelector(state => state.candidate.firstName)
  }

  const closeSetup = () => {
    dispatch(
      fetchUpdateCandidate({
        id: candidateId,
        setupSeen: true,
        suppressSnackbar: true,
      }),
    )
  }
  const applyJobFilters = ({ searchParams }) => {
    applyFilters({
      candidateId,
      employerUserId,
      searchParams,
      dispatch,
      getData: dataCall,
      page,
      pageSize,
    })
  }

  return (
    <>
      <Grid container justifyContent='center'>
        {!employerUserId && signedIn && (
          <>
            <JobsPageTutorial />
          </>
        )}
        {employerUserId && signedIn && <RecruiterJobPageTutorial totalJobs={totalJobs} />}
        {signedIn && (
          <Grid item xs={12}>
            {!setupSeen && candidateId && (
              <AlertWrapper
                closeAction={closeSetup}
                text={
                  <>
                    <Typography display='inline'>
                      🎉 Hi {firstName}!{' '}
                      <a
                        href='/onboarding'
                        target='_blank'
                        underline='always'
                        style={{ cursor: 'pointer' }}
                      >
                        Complete your profile
                      </a>{' '}
                      to unlock a better experience!
                    </Typography>
                  </>
                }
              />
            )}
            <Filters
              filterType={FilterType.JOB}
              filterValues={filterValues}
              listType={'job'}
              loading={isLoadingFilters}
              applyFilters={applyJobFilters}
            />
          </Grid>
        )}
        {isLoadingJobs ? (
          <Loader />
        ) : (
          <Grid container sx={{ maxWidth: '70em' }}>
            <Grid
              container
              item
              sm={12}
              md={5}
              direction='row'
              alignContent='flex-start'
              sx={{ height: '100%' }}
            >
              <Grid item>
                <Typography variant='h6' sx={{ padding: '10px 16px' }} className='testing'>
                  {leftPanelHeader}
                </Typography>
              </Grid>
              <div className='joyride_job_list'>
                <Grid
                  container
                  alignItems='flex-start'
                  sx={{ maxHeight: '100vh', overflow: 'auto' }}
                >
                  <List
                    setPage={setPage}
                    page={page}
                    data={jobsList}
                    listType={'job'}
                    handleListItemClick={handleListItemClick}
                    dataTotal={totalJobs}
                    totalPages={totalPages}
                    signedIn={signedIn}
                  />
                </Grid>
              </div>
            </Grid>
            <Grid
              container
              item
              md={7}
              direction='column'
              sx={{ maxHeight: '100vh', overflow: 'auto' }}
            >
              {activeItem ? (
                <Box display={{ xs: 'none', sm: 'none', md: 'contents' }}>
                  <JobContent
                    item={activeItem}
                    signedIn={signedIn}
                    employerUserId={employerUserId}
                  />
                </Box>
              ) : employerUserId && searchParams.size === 0 ? (
                <Box display={{ xs: 'none', sm: 'none', md: 'contents' }}>
                  <Grid container item justifyContent='center' sx={{ padding: '20px 20px' }}>
                    <div className='joyride_add_first_job'>
                      <DialogButton
                        variant='contained'
                        size='small'
                        Module={JobDialog}
                        size='small'
                        tooltip='Add new job'
                        initialValues={{}}
                        s
                      >
                        Add your first job
                      </DialogButton>
                    </div>
                  </Grid>
                </Box>
              ) : (
                <Typography variant='body2' sx={{ padding: '50px' }}>
                  No jobs found, please try a different filter criteria
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default Jobs
