import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import { showSnackbar } from 'data/snackbar/actions'
import { snackbarTypes } from 'data/constants'
import { fetchUpdateRealtor } from 'src/redux/realtor'
import { useDialogState } from 'data/dialog/actions'

const variant = 'contained'
export const dialogId = 'Realtor'

export const UploadRealtorPhotoButton = ({ entry }) => {
  const dispatch = useDispatch()
  const buttonText = entry.profileImage ? 'Replace Image' : 'Upload Image'

  const [state, setDialogState] = useDialogState(dialogId)

  const onInput = e => {
    const uploadFiles = e.target.files
    if (uploadFiles) {
      const arrFiles = Array.from(uploadFiles)
      var file = arrFiles.length > 0 ? arrFiles[0] : undefined
      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          showSnackbar(dispatch, 'File exceeds 5 MB upload limit.', snackbarTypes.ERROR)
        } else {
          const values = {
            id: state.initialValues.id,
            profileImageFile: file,
          }
          dispatch(fetchUpdateRealtor(values))
        }
      }
    }
  }
  const onClick = () => {
    setDialogState({ initialValues: entry, type: 'upload' })
  }

  return (
    <div>
      <input
        name='fileselect'
        type='file'
        accept='*'
        id='fileselect'
        onChange={onInput}
        style={{ display: 'none' }}
      />
      <label htmlFor='fileselect'>
        <Button onClick={onClick} color='secondary' size='small' component='span' variant={variant}>
          {buttonText}
        </Button>
      </label>
    </div>
  )
}
