import React from 'react'
import GenericDialog from 'components/Dialog/GenericDialog'
import { useDialogState } from 'data/dialog/actions'
import Content from './Content'

export const dialogId = 'Save Filter'

export default function SaveFilterDialog({ id = dialogId }) {
  const [{ selectedFilters, open }, setState] = useDialogState(id)
  const component = () => <Content dialogId={id} />

  React.useEffect(() => {
    setState(prev => ({
      open,
      ...prev,
      RenderComponent: component,
    }))
  }, [])

  return (
    <GenericDialog open={open} selectedFilters={selectedFilters} headerTitle='Save New Filter' id={id} />
  )
}
