import React from 'react'
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  useTheme,
  IconButton,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CloseIcon from '@mui/icons-material/Close'

const GenericSelectField = ({
  options,
  value,
  label,
  name,
  disabled = false,
  handleChange,
  helperText,
  ...props
}) => {
  const theme = useTheme()
  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel
        id={`${name}-label`}
        size='small'
        sx={disabled ? { color: '#757575' } : { color: theme.palette.primary }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`${name}-label`}
        id={name}
        name={name}
        displayEmpty={true}
        IconComponent={() =>
          disabled ? null : value !== '' && value !== null ? (
            <IconButton onClick={() => handleChange({ target: { name: name, value: null } })}>
              <CloseIcon />
            </IconButton>
          ) : (
            <ArrowDropDownIcon />
          )
        }
        value={value || ''}
        label={label}
        disabled={disabled}
        size='small'
        sx={{
          color: theme.palette.primary.dark,
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
          },
          '& fieldset': disabled && { border: 'none' },
        }}
        onChange={event => handleChange(event)}
        {...props}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.id} sx={{ color: theme.palette.primary.dark }}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && !disabled && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default GenericSelectField
