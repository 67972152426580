import React, { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography, useTheme, useMediaQuery, CircularProgress } from '@mui/material'
import OnboardingStepper from './OnboardingStepper'
import Credentials from './Credentials'
import TheBasics from './TheBasics'
import AboutJob from './AboutJob'
import AboutYou from './AboutYou'
import OnboardingStepperMobile from './OnboardingStepperMobile'

const Onboarding = () => {
  const [activeStep, setActiveStep] = React.useState(0)
  const user = useSelector(state => state.identity)
  const candidate = useSelector(state => state.candidate)
  user.type = 'candidate'
  user.id = candidate.id

  const gotoNextStep = () => {
    setActiveStep(activeStep + 1)
  }

  const steps = [
    {
      step: 0,
      label: 'The Basics',
      description: 'Basic Information',
      Component: (
        <TheBasics
          user={user}
          candidate={candidate}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          gotoNextStep={gotoNextStep}
        />
      ),
    },
    {
      step: 1,
      label: 'About You',
      description: 'About You',
      Component: (
        <AboutYou
          user={user}
          candidate={candidate}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          gotoNextStep={gotoNextStep}
        />
      ),
    },
    {
      step: 2,
      label: 'Credentials',
      Component: (
        <Credentials
          user={user}
          candidate={candidate}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          gotoNextStep={gotoNextStep}
        />
      ),
    },
    {
      step: 3,
      label: 'About your job',
      description: `What does your ideal job look like? This will help us understand what you're looking for in a job`,
      Component: <AboutJob user={user} candidate={candidate} />,
    },
  ]
  const theme = useTheme()
  const below_lg = useMediaQuery(theme.breakpoints.down('md'))

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  if (candidate.loading && !candidate.photoLoading && !candidate.resumeLoading) {
    return <CircularProgress />
  } else {
    return !below_lg ? (
      <Grid container direction='row' sx={{ height: '100vh', overflow: 'auto' }}>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            backgroundColor: theme.palette.secondary.light,
            height: 'auto',
            backgroundSize: 'cover',
            padding: '20px',
            overflow: 'auto',
          }}
        >
          <OnboardingStepper activeStep={activeStep} setActiveStep={setActiveStep} steps={steps} />
        </Grid>
        <Grid item xs={12} md={9} sx={{ padding: '40px' }}>
          <Typography variant='h4'>
            <b>{steps[activeStep].label}</b>
          </Typography>
          <Grid item>{steps[activeStep].Component}</Grid>
        </Grid>
      </Grid>
    ) : (
      <Grid container direction='column'>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            backgroundColor: theme.palette.secondary.light,
          }}
        >
          <OnboardingStepperMobile
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
          />
        </Grid>
        <Grid item xs={12} md={9} sx={{ padding: '40px' }}>
          <Typography variant='h4'>
            <b>{steps[activeStep].label}</b>
          </Typography>
          <Grid item>{steps[activeStep].Component}</Grid>
        </Grid>
      </Grid>
    )
  }
}
export default Onboarding
