import React from 'react'
import { Button, Typography } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

const MoreFiltersButton = ({ areFiltersOpen, toggleFilters }) => {
  return (
    <div className='joyride_more_filters'>
      <Button
        variant='text'
        onClick={toggleFilters}
        endIcon={areFiltersOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        sx={{
          color: 'white',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Typography sx={{ fontSize: '.8rem' }}>
          {areFiltersOpen ? 'Less Filters' : 'More Filters'}
        </Typography>
      </Button>
    </div>
  )
}

export default MoreFiltersButton
