import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography, Grid, Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useHeaderHeight } from 'data/hooks'
import { isEmpty, checkEmpty, imageAddress } from 'data/helpers'
import Title from 'components/Title'
import Buttons from 'pages/Hospitals/Profile/Buttons'
import { useEmployer } from 'data/employer/actions'
import { TextEditor } from 'components/TextEditor/TextEditor'

const useStyles = makeStyles(() =>
  createStyles({
    header: ({ headerHeight, BackgroundImage }) => ({
      display: 'flex',
      flexDirection: 'row',
      height: headerHeight,
      justifyContent: 'flex-end',
      width: '100%',
      background: `#FFF`,
      backgroundSize: 'cover',
      backgroundImage: BackgroundImage ? `url(${BackgroundImage})` : undefined,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    }),
    divider: {
      backgroundColor: '#5FB49C',
      width: '100%',
      alignSelf: 'center',
      height: '4px',
      borderRadius: '8px',
      marginBottom: '10px',
    },
  }),
)

export default function HospitalProfile() {
  const [{ name, about, image, loading }, getEmployer] = useEmployer()
  const { key, fileName } = checkEmpty(image) ? {} : image
  const BackgroundImage = !checkEmpty(key) ? imageAddress(key, fileName) : undefined

  const classes = useStyles({ headerHeight: useHeaderHeight(175), BackgroundImage })
  const employerUser = useSelector(state => state.employerUser) || {}

  React.useEffect(() => {
    getEmployer(employerUser.employerId)
  }, [employerUser.employerId, getEmployer])

  return (
    <Grid container justifyContent='center' spacing={4}>
      <Grid item xs={12}>
        <Grid container className={classes.header} />
      </Grid>
      <Grid item xs={12} style={{ maxWidth: 800 }}>
        <Grid container style={{ paddingLeft: 16, paddingRight: 16 }} justifyContent='center'>
          <Grid item>
            <Title fullWidth={true} text={name} textAlign='center' marginBottom={24} />
            <Divider variant='middle' className={classes.divider} />
          </Grid>
          <Grid item xs={12}>
            <Typography align='center' variant='body1' inverted='true'>
              {isEmpty(about) ? (
                'Please update the hospital profile to display about information.'
              ) : (
                <TextEditor inverted='true' initialValue={about} isReadOnly={true} />
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={3}>
              <Buttons loading={loading} inverted='true' />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
