import React from 'react'
import { TextField, FormControl, FormLabel, useTheme } from '@mui/material'
const GenericMultiLineTextField = ({ name, value, label, disabled, placeholder, handleChange }) => {
  const theme = useTheme()
  return (
    <FormControl variant='outlined' fullWidth={true} sx={{ width: '100%' }}>
      <FormLabel id={`${name}-form`}>
        <TextField
          id={name}
          name={name}
          label={label}
          placeholder={placeholder}
          multiline
          disabled={disabled}
          rows={4}
          variant='outlined'
          value={value || ''}
          onChange={event => handleChange(event)}
          sx={{
            width: '100%',
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.primary.dark,
            },
            '& fieldset': disabled && { border: 'none' },
          }}
        />
      </FormLabel>
    </FormControl>
  )
}

export default GenericMultiLineTextField
