import React from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  TextField,
  Typography,
  MenuItem,
  InputAdornment,
  IconButton,
  useTheme,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { generateAlertName } from './helpers'
import { filterDefinitions } from './filterDefinitions'

export const AlertsButton = ({availableAlerts}) => {
  const theme = useTheme()
  const [searchParams, setSearchParams] = useSearchParams()
  const handleChange = event => {
    setSearchParams({
      userFilterAlertId: event.target.value.id,
    })
  }
  const handleClear = () => {
    setSearchParams({})
  }

  const filterDefinition = filterDefinitions.find(filter => filter.name === 'userFilterAlertId')

  const options = availableAlerts.map(item => ({
    id: item.id,
    name: generateAlertName(item.userFilterName, item.createdAt),
    queryString: filterDefinition.queryStringKey,
  }))

  const appliedAlert =
    options.find(item => item.id.toString() === searchParams.get('userFilterAlertId')) || {}

  return (
    <>
      {availableAlerts.length > 0 ? (
        <TextField
          onChange={handleChange}
          id='select-alerts'
          size='small'
          select
          fullWidth
          disabled={availableAlerts.length === 0}
          label={appliedAlert.name ? 'Selected Alert' : 'Alerts'}
          variant='outlined'
          placeholder='Alerts'
          value={appliedAlert.name ? appliedAlert : ''}
          InputLabelProps={{
            style: {
              color: 'white',
              fontSize: '0.7rem',
            },
          }}
          sx={{
            color: 'white',
            '& .MuiSvgIcon-root': { color: '#FFF' },
            '& .MuiFilledInput-root': {
              backgroundColor: appliedAlert.name ? theme.palette.secondary.main : 'transparent',
              borderColor: 'white',
              '&:hover': {
                backgroundColor: appliedAlert.name ? theme.palette.secondary.dark : 'transparent',
              },
            },
            '& .MuiOutlinedInput-root': {
              backgroundColor: appliedAlert.name ? theme.palette.secondary.main : 'transparent',
              borderColor: 'white',
              '&:hover': {
                backgroundColor: appliedAlert.name ? theme.palette.secondary.dark : 'transparent',
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '& .MuiInputBase-input': {
              color: 'white',
              fontSize: '0.8rem', // Adjust font size for small screens
              textAlign: 'left', // Align text to the left
              paddingLeft: '10px', // Add padding to move text closer to the left
            },
            '& .MuiIconButton-root': {
              padding: '4px', // Reduce padding for small screens
            },
            '& .MuiInputAdornment-root': {
              display: 'flex',
              alignItems: 'center',
            },
          }}
          InputProps={{
            endAdornment: appliedAlert.name ? (
              <InputAdornment position='end'>
                <IconButton onClick={handleClear} size='small'>
                  <ClearIcon fontSize='small' sx={{ color: 'white' }} />
                </IconButton>
              </InputAdornment>
            ) : (
              <InputAdornment position='end'>
                <NotificationsNoneIcon
                  sx={{
                    color: `${availableAlerts.length === 0 ? '#FFFFFF4D' : 'white'} !important`,
                    fontSize: '1.2rem', // Adjust icon size for small screens
                  }}
                />
              </InputAdornment>
            ),
          }}
          SelectProps={{
            IconComponent: () => null,
            renderValue: () => (
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '.8rem',
                  textTransform: 'none',
                  textAlign: 'left', // Align text to the left
                  width: '100%',
                  paddingLeft: '10px', // Add padding to move text closer to the left
                }}
              >
                {appliedAlert.name || 'Alerts'}
              </Typography>
            ),
          }}
        >
          {options.map((item, index) => (
            <MenuItem id={`saved-alert-${index}`} key={index} value={item}>
              <Typography sx={{ color: theme.palette.primary.dark, fontSize: '.8rem' }}>
                {item.name}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <></>
      )}
    </>
  )
}

export default AlertsButton
