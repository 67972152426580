import React from 'react'
import { TextField, useTheme } from '@mui/material'
const NPINumberField = ({
  value,
  disabled,
  handleChange,
  label,
  setErrors,
  errors,
  name,
  ...props
}) => {
  const theme = useTheme()

  const validate = event => {
    const { value } = event.target
    if (value) {
      if (value.trim().length !== 10) {
        setErrors({ ...errors, npiNumber: 'NPI number must be 10 digits' })
      } else {
        delete errors.npiNumber
      }
    } else {
      delete errors.npiNumber
    }
    handleChange(event)
  }
  return (
    <TextField
      id={name}
      type='number'
      name={name}
      label={label}
      error={errors.npiNumber !== undefined}
      disabled={disabled}
      helperText={errors.npiNumber}
      size='small'
      onChange={event => validate(event)}
      value={value || ''}
      sx={{
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: theme.palette.primary.dark,
        },
        '& fieldset': disabled && { border: 'none' },
      }}
      {...props}
    />
  )
}

export default NPINumberField
