import React from 'react'
import DialogButton from 'components/Dialog/DialogButton'
import * as RealtorDialog from 'components/Dialog/RealtorDialog'
import AddIcon from '@mui/icons-material/Add'

export const defaultProps = {
  name: 'Realtors',
  columns: [
    { name: 'id', header: 'Id' },
    { name: 'firstName', header: 'First Name' },
    { name: 'lastName', header: 'Last Name' },
    {
      name: 'actions',
      header: 'Actions',
    },
  ],
  addComponent: (
    <DialogButton Module={RealtorDialog} variant='outlined'>
      <AddIcon />
      Add Realtor
    </DialogButton>
  ),
  searchableFields: ['firstName', 'lastName'],
}
