import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export const TextEditor = ({ initialValue, isReadOnly, onChange, placeholder }) => {
  const editorRef = useRef(null)
  const [isEditorReady, setIsEditorReady] = useState(false)

  useEffect(() => {
    if (isEditorReady && editorRef.current && editorRef.current.editor) {
      try {
        editorRef.current.editor.setData(initialValue)
      } catch (error) {
        console.error('Error setting editor data:', error)
      }
    }
  }, [isEditorReady])

  return (
    <Typography variant='body1' component='div'>
      <CKEditor
        editor={ClassicEditor}
        data={initialValue}
        config={{
          placeholder: placeholder,
          toolbar: {
            shouldNotGroupWhenFull: true,
          },
        }}
        onReady={editor => {
          editorRef.current = { editor }
          setIsEditorReady(true)
          if (isReadOnly) {
            editor.ui.view.editable.element.style.backgroundColor = 'transparent'
            editor.ui.view.editable.element.style.borderColor = 'transparent'
            editor.ui.view.toolbar.element.style.display = 'none'
          }
        }}
        onFocus={(event, editor) => {
          if (isReadOnly) {
            editor.ui.view.editable.element.style.backgroundColor = 'transparent'
            editor.ui.view.editable.element.style.borderColor = 'transparent'
            editor.ui.view.toolbar.element.style.display = 'none'
          }
        }}
        onChange={(event, editor) => {
          const data = editor.getData()
          onChange({
            about: data,
            aboutRaw: data,
          })
        }}
        disabled={isReadOnly}
      />
    </Typography>
  )
}

TextEditor.defaultProps = {
  isReadOnly: false,
  onChange: () => {},
  value: '',
}

TextEditor.propTypes = {
  isReadOnly: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
}
