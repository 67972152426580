import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const globalTheme = createTheme({
  palette: {
    primary: {
      main: '#0E7C7B',
      light: '#F5F5F5',
      contrastText: '#FFF',
      dark: '#1B1B1B',
    },
    secondary: {
      main: '#0E467C',
      light: '#dde2ea',
    },
  },
})

let theme = createTheme(
  {
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: globalTheme.palette.primary.light,
            color: globalTheme.palette.primary.dark,
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: props => props.stylevariant === 'header' && props.variant === 'contained',
            style: {
              color: globalTheme.palette.primary.contrastText,
              backgroundColor: globalTheme.palette.primary.dark,
            },
          },
          {
            props: props => props.stylevariant === 'header' && props.variant !== 'contained',
            style: {
              color: globalTheme.palette.primary.dark,
            },
          },
        ],
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            height: '4px',
            borderRadius: '8px',
            backgroundColor: globalTheme.palette.secondary.main,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            color: globalTheme.palette.primary.main,
            fontSize: '1rem',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: globalTheme.palette.primary.main,
          },
        },
      },
      MuiSwitch: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: '#000',
        },
        colorSecondary: {
          '&$checked': {
            // Controls checked color for the thumb
            color: '#f2ff00',
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 1,
          backgroundColor: '#000',
          '$checked$checked + &': {
            // Controls checked color for the track
            opacity: 1,
            backgroundColor: '#000',
          },
        },
      },
      MuiPickersDay: {
        day: {
          color: '#707070',
        },
        daySelected: {
          backgroundColor: '#0E7C7B',
          '&:hover': {
            backgroundColor: '#0E7C7B',
          },
        },
        current: {
          color: '#0E7C7B',
        },
      },
      CKEditor: {
        styleOverrides: {
          editor: {
            height: '1000vh',
            overflow: 'auto',
            border: '1px solid #1B1B1B',
          },
        },
      },
    },
    color: props => (props.inverted ? globalTheme.palette.primary.dark : '#FFF'),
    typography: {
      color: props => (props.inverted ? globalTheme.palette.primary.dark : '#FFF'),
      h1: {
        fontFamily: `"EB Garamond", serif`,
        color: props => (props.inverted ? globalTheme.palette.primary.dark : '#1B1B1B'),
      },
      h2: {
        fontFamily: `"EB Garamond", serif`,
        color: globalTheme.palette.primary.dark,
      },
      h3: {
        fontFamily: `"Roboto", sans-serif`,
        color: props =>
          props.inverted && !props.emphasis
            ? '#FFF'
            : props.emphasis
              ? '#FFD854'
              : globalTheme.palette.primary.dark,
      },
      h4: {
        fontFamily: `"EB Garamond", serif`,
        color: globalTheme.palette.primary.dark,
      },
      h5: {
        fontFamily: `"EB Garamond", serif`,
        color: props => (props.inverted ? globalTheme.palette.primary.dark : '#1B1B1B'),
      },

      // LOGO Font
      h6: {
        fontFamily: `"Roboto", sans-serif`,
        color: globalTheme.palette.primary.dark,
      },
      body1: {
        fontFamily: `"Roboto", sans-serif`,
        color: props => (props.inverted ? '#FFF' : globalTheme.palette.primary.dark),
      },
      body2: {
        fontFamily: `"Roboto", sans-serif`,
        color: props => (props.inverted ? '#FFF' : globalTheme.palette.primary.dark),
      },
      subtitle1: {
        fontFamily: `"Roboto", sans-serif`,
        color: props => (props.inverted ? '#FFF' : globalTheme.palette.primary.dark),
      },
      subtitle2: {
        fontFamily: `"EB Garamond", serif`,
        color: props => (props.inverted ? '#9E788F' : '#FFF'),
        fontWeight: props => (props.inverted ? 'bold' : 400),
      },
      caption: {
        fontFamily: `"Roboto", sans-serif`,
        fontStyle: 'italic',
        color: props => (props.inverted ? '#FFF' : globalTheme.palette.primary.dark),
      },
      overline: {
        fontFamily: `"EB Garamond", serif`,
        color: props => (props.inverted ? '#FFF' : globalTheme.palette.primary.dark),
      },
    },
    layout: {
      toolbarHeight: 64, // Height of the top toolbar
      contentPadding: 0, // Padding around inner content
      tableContentPadding: 8, // Padding around inner content for tables
      footerHeight: 80, // Height of the footer
      tableFilterBarHeight: 56, // The height of the table filter selector
      tableFooterHeight: 20, // The height of the table footer
      contentRowSpacing: 2, // Space between horizontal content rows, for table height calculations
      multiSelectBarHeight: 56, //The height of the table's multi select toolbar
      progressSize: 24, // size of the circular progress indicator shown when dialogs are submitting
      progressSizeLarge: 80, // size of the large circular progress indicator shown when dialogs are submitting
      backgroundColor: '#FFF', // Background color of the viewport
      backdropColor: '#FFF', // Background color behind the viewport (seen if the viewport has padding or margins).
      mobileLRPadding: 24, // Left and Right padding on mobile
    },
  },
  globalTheme,
)

theme = responsiveFontSizes(theme)
export default theme
