import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GenericDialog from 'components/Dialog/GenericDialog'
import { copyToLower } from 'data/helpers'
import { useDialogState } from 'data/dialog/actions'
import { fetchUpdateOtherEmployerUser } from 'src/redux/employerUser'
import { fetchCreateEmployerUser } from 'src/redux/employerUser'
import { fetchGetEmployers } from 'src/redux/employer'
import { useIsUser, useIdentity } from 'pages/Identity/Login'
import { isEdit } from 'components/Dialog/helpers'

export const dialogId = 'Employer User'

const EmployerUserDialog = ({ id = dialogId }) => {
  const users = useSelector(state => state.users.users || [])
  const { employers = [] } = useSelector(state => state.employer || [])
  const dispatch = useDispatch()
  const { isEmployerAdmin } = useIsUser()
  const { employerId, isSuper } = useIdentity()

  const [{ type }, setState] = useDialogState(id)

  React.useEffect(() => {
    dispatch(fetchGetEmployers())
  }, [])

  const handleSubmit = values => {
    isEdit(type)
      ? dispatch(fetchUpdateOtherEmployerUser({ data: values, updateDialog: setState, isSuper }))
      : dispatch(
          fetchCreateEmployerUser(
            isEmployerAdmin
              ? { data: { ...values, employerId }, employerId: employerId, updateDialog: setState }
              : { data: values, updateDialog: setState },
          ),
        )
  }

  const handleValidation = (values, dialogState) => {
    const userEmails = users
      .filter(u => (dialogState.initialValues ? u.id != dialogState.initialValues.id : true))
      .map(u => u.email)

    var errors = copyToLower(dialogState.errors) // start with server generated errors, ensure all keys start with lowercase letter
    const { email } = values

    if (userEmails && userEmails.find(e => e === email)) {
      errors['email'] = 'The email is already in use by another user.'
    }

    return errors
  }

  return (
    <GenericDialog
      id={id}
      onSubmit={handleSubmit}
      validate={handleValidation}
      fields={[
        {
          id: 'id',
          hidden: true,
        },
        {
          id: 'email',
          label: 'Email',
          required: true,
          email: true,
        },
        {
          id: 'firstName',
          label: 'First Name',
          required: true,
        },
        {
          id: 'lastName',
          label: 'Last Name',
          required: true,
        },
        {
          id: 'employerId',
          label: 'Account',
          type: 'select',
          required: !isEmployerAdmin, // Don't require if the user is an admin
          hidden: isEmployerAdmin, // If admin user, don't show the option to change the account
          items: employers.map(employer => ({ id: employer.id, label: employer.name })),
        },
        {
          id: 'isEmployerAdmin',
          label: 'Is Employer Admin',
          type: 'select',
          items: [
            { id: false, label: 'No' },
            { id: true, label: 'Yes' },
          ],
        },
      ]}
    />
  )
}

export default EmployerUserDialog
