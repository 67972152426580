import React from 'react'
import DialogButton from 'components/Dialog/DialogButton'
import * as EmployerUserDialog from 'components/Dialog/EmployerUserDialog'
import AddIcon from '@mui/icons-material/Add'

export const defaultProps = {
  name: 'Users',
  columns: [
    {
      name: 'email',
      header: 'Email',
    },
    {
      name: 'firstName',
      header: 'First Name',
    },
    {
      name: 'lastName',
      header: 'Last Name',
    },
    {
      name: 'isEmployerAdmin',
      header: 'Is Employer Admin',
    },
    {
      name: 'employer',
      header: 'Account',
    },
    {
      name: 'actions',
      header: 'Actions',
    },
  ],
  addComponent: (
    <DialogButton Module={EmployerUserDialog} variant='outlined'>
      <AddIcon />
      Add Employer User
    </DialogButton>
  ),
  searchableFields: ['email', 'firstName', 'lastName'],
}
