export const getItems = obj => Object.keys(obj).map(k => ({ value: Number(k), label: obj[k] }))
export const getRawItems = obj => Object.keys(obj).map(k => ({ value: k, label: obj[k] }))

// TODO:BROCK these enums should probably come from endpoints... don't need the front code duplicating them
// we already ran into one bug where the visa name didn't match

export const PRACTICE_TYPE_ENUM = {
  FullTime: 'Full-Time',
  Locum: 'Locum',
  Telemedicine: 'Telemedicine',
  PartTime: 'Part-Time',
  PRN: 'PRN',
  Travel: 'Travel',
}

export const SHIFT_TYPE_ENUM = {
  EightDay: '8 Hours - Day',
  EightAfternoon: '8 Hours - Afternoon',
  EightOvernight: '8 Hours - Overnight',
  TwelveDay: '12 Hours - Day',
  TwelveNight: '12 Hours - Night',
  Mixed: 'Day/Night - Mixed',
}

export const SALARY_TYPE_ENUM = {
  Hourly: 'Hourly',
  Annual: 'Annual',
}

export const AREA_ENUM = {
  Urban: 'Urban',
  Rural: 'Rural',
  Suburban: 'Suburban',
}

export const STATUS_ENUM = {
  0: 'Open',
  1: 'Filled',
}

export const ACADEMIC_ENUM = {
  Yes: 'Yes',
  Hybrid: 'Hybrid',
  No: 'No',
}

// TODO: Make this dynamic from the db
export const VISA_ENUM = {
  'H-1B': 'H-1B',
  J1: 'J1',
  'O-1': 'O-1',
  'H-1B Or J1': 'H-1B Or J1',
}

export const CANDIDATE_TYPE_ENUM = {
  1: 'Provider',
  2: 'Nurse',
}

export const SEARCH_STATUS_ENUM = {
  1: 'ActivelyLooking',
  2: 'PassivelyLooking',
  3: 'NotLooking',
}

export const BOARD_ELIGIBILITY_ENUM = {
  1: 'BoardCertified',
  2: 'BoardEligible',
  3: 'Neither',
}
