import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GenericDialog from 'components/Dialog/GenericDialog'
import { copyToLower } from 'data/helpers'
import { useDialogState } from 'data/dialog/actions'
import { fetchCreateEmployer, fetchUpdateEmployer } from 'src/redux/employer'
export const dialogId = 'Employer'

export default function EmployerDialog({ id = dialogId }) {
  const dispatch = useDispatch()
  const { employers = [] } = useSelector(s => s.employer)
  const [{ type }, setState] = useDialogState(id)

  const handleAdd = values => {
    dispatch(fetchCreateEmployer(values))
    setState()
  }

  const handleEdit = values => {
    dispatch(fetchUpdateEmployer(values))
    setState()
  }

  const handleValidation = (values, dialog) => {
    const accountNames = employers
      .filter(a => (dialog.initialValues ? a.id != dialog.initialValues.id : true))
      .map(a => a.name)
    var errors = copyToLower(dialog.errors) // start with server generated errors, ensure all keys are lowercase
    const { name } = values

    if (accountNames && accountNames.find(n => n === name)) {
      errors['name'] = 'The name is already in use.'
    }
    return errors
  }

  return (
    <GenericDialog
      id={id}
      onSubmit={type === 'add' ? handleAdd : handleEdit}
      validate={handleValidation}
      fields={[
        {
          id: 'id',
          hidden: true,
        },
        {
          id: 'name',
          label: 'Name',
          required: true,
        },
      ]}
    />
  )
}
