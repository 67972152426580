import React from 'react'
import { useTheme } from '@mui/material'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

export const GenericDatePicker = ({ value, handleChange, label, name, disabled }) => {
  const theme = useTheme()
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          disabled={disabled}
          label={label}
          name={name}
          format='M/D/YYYY'
          value={value ? dayjs(value) : null}
          onChange={date => handleChange({ target: { name: name, value: date } })}
          slotProps={{
            field: { shouldRespectLeadingZeros: true },
            textField: { size: 'small' },
          }}
          sx={{
            '& .MuiOutlinedInput-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.primary.dark,
            },
            '& .MuiOutlinedInput-root.Mui-disabled': {
              '& fieldset': { border: 'none' },
            },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  )
}

export default GenericDatePicker
