export const onFilterDropDownChange = (event, options, filterDefinition, onChange) => {
  let selectedValues = null
  // NOTE: event.target.value is actually the "name" of the filter option name/value pair
  if (filterDefinition.isMultiple) {
    selectedValues = event.target.value
      .map(value => {
        return options.find(option =>
          typeof value === 'object' ? option.name === value.name : option.name === value,
        )
      })
      .filter(option => option) // filter out empty options
  } else {
    const singleValue = options.find(option => option.name === event.target.value)
    selectedValues = singleValue ? [singleValue] : []
  }

  const selectedFilter = {
    filterDefinition,
    selectedValues,
  }

  onChange(selectedFilter)
}
