import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GenericDialog from 'components/Dialog/GenericDialog'
import { copyToLower } from 'data/helpers'
import { fetchAddRealtor, fetchUpdateRealtor } from 'src/redux/realtor'
import { useDialogState } from 'data/dialog/actions'
import { States } from 'data/constants'

export const dialogId = 'Realtor'

export default function RealtorDialog({ id = dialogId }) {
  const dispatch = useDispatch()
  const accounts = useSelector(s => s.realtors.realtors || [])
  const [{type}, setState] = useDialogState(id)

  const handleAdd = (values) => {
    dispatch(
      fetchAddRealtor(values),
    )
    setState()
  }

  const handleEdit = (values) => {
    dispatch(
      fetchUpdateRealtor(values),
    )
    setState()
  }

  const handleValidation = (values, dialog) => {
    const accountNames = accounts
      .filter(a => (dialog.initialValues ? a.id != dialog.initialValues.id : true))
      .map(a => a.name)
    var errors = copyToLower(dialog.errors) // start with server generated errors, ensure all keys are lowercase
    const { name } = values

    if (accountNames && accountNames.find(n => n === name)) {
      errors['name'] = 'The name is already in use.'
    }
    return errors
  }

  return (
    <GenericDialog
      id={id}
      onSubmit={type === 'add' ? handleAdd : handleEdit}
      validate={handleValidation}
      fields={[
        {
          id: 'id',
          hidden: true,
        },
        {
          id: 'firstName',
          label: 'First Name',
          required: true,
        },
        {
          id: 'lastName',
          label: 'Last Name',
          required: true,
        },
        {
          id: 'email',
          label: 'Email',
          required: true,
        },
        {
          id: 'phone',
          label: 'Phone',
          type: 'phoneNumber',
          forceErrorMargin: false,
          required: false,
          variant: 'outlined',
          margin: 'dense',
        },
        {
          id: 'website',
          label: 'Website',
          type: 'website',
          forceErrorMargin: false,
          required: false,
          variant: 'outlined',
          margin: 'dense',
        },
        {
          id: 'city',
          label: 'City',
          required: false,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'state',
          label: 'State',
          type: 'autocomplete',
          required: false,
          forceErrorMargin: false,
          variant: 'outlined',
          items: Object.keys(States).map(k => ({ value: k, label: `${States[k]}` })),
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'description',
          label: 'Description',
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          multiline: true,
        },
        {
          id: 'incentive',
          label: 'Incentive',
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          multiline: true,
        },
      ]}
    />
  )
}
