import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'

const ResumeUpload = ({ handleChange, label, name, loading }) => {
  return (
    <div>
      <input
        name={name}
        type='file'
        accept='*'
        id='fileselect'
        style={{ display: 'none' }}
        onChange={event => handleChange(event)}
      />
      <label htmlFor='fileselect'>
        <LoadingButton
          variant='outlined'
          size='small'
          component='span'
          disabled={false}
          sx={{ margin: '10px' }}
          loading={loading}
        >
          {label}
        </LoadingButton>
      </label>
    </div>
  )
}

export default ResumeUpload
