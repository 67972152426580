import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchCreateCandidate } from 'src/redux/register'
import { convertValueToArray } from 'data/helpers'
import { useSelector } from 'react-redux'

const initialFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  city: null,
  state: null,
  specialtyId: null,
  subspecialtyId: null,
  password: '',
  confirmPassword: '',
  referralSourceId: null,
  referralSourceOther: null,
  registrationContact: false,
}

export const useFormControls = () => {
  const [values, setValues] = useState(initialFormValues)
  // Should this be in state?
  const [errors, setErrors] = useState({})
  const registrationErrorMessage = useSelector(state => state.register.errors)
  React.useEffect(() => {
    const tempErrors = {}
    if (registrationErrorMessage.errors) {
      Object.entries(registrationErrorMessage.errors).forEach(([key, value]) => {
        tempErrors[key] = value[0]
      })
    }
    setErrors(tempErrors)
  }, [registrationErrorMessage])
  const dispatch = useDispatch()

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('email' in fieldValues) {
      temp.email = !fieldValues.email && 'This field is required.'
      if (fieldValues.email)
        temp.email = !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email) && 'Email is not valid.'
    }

    if ('password' in fieldValues) {
      const numericRegEx = /[0-9]/
      const alphaRegEx = /(?=.*[a-z])(?=.*[A-Z])/
      const nonAlpha = /[^a-zA-Z0-9]+/
      if (fieldValues.password.length < 8) {
        temp.password = (
          <a href='https://xkcd.com/936/' target='_blank' style={{ color: 'red' }}>
            Must be at least 8 characters
          </a>
        )
      } else if (!numericRegEx.test(fieldValues.password)) {
        temp.password = (
          <a href='https://xkcd.com/936/' target='_blank' style={{ color: 'red' }}>
            Must contain at least 1 number
          </a>
        )
      } else if (!alphaRegEx.test(fieldValues.password)) {
        temp.password = (
          <a href='https://xkcd.com/936/' target='_blank' style={{ color: 'red' }}>
            Must contain one upper and one lower case character
          </a>
        )
      } else if (!nonAlpha.test(fieldValues.password)) {
        temp.password = (
          <a href='https://xkcd.com/936/' target='_blank' style={{ color: 'red' }}>
            Must contain one non-alphanumeric character
          </a>
        )
      } else if (fieldValues.password !== values.confirmPassword) {
        temp.password = 'Passwords must match.'
        temp.confirmPassword = 'Passwords must match.'
      } else {
        temp.password = false
        temp.confirmPassword = false
      }
    }
    if ('confirmPassword' in fieldValues) {
      if (values.password !== fieldValues.confirmPassword) {
        temp.password = 'Passwords must match.'
        temp.confirmPassword = 'Passwords must match.'
      } else {
        temp.password = false
        temp.confirmPassword = false
      }
    }
    if ('specialtyId' in fieldValues) {
      if (fieldValues.specialtyId === '') {
        temp.specialtyId = 'Specialty is required'
      } else {
        temp.specialtyId = null
      }
    }

    setErrors({
      ...temp,
    })
  }
  const handleInputValue = e => {
    const { name, value, checked } = e.target
    if (checked) {
      setValues({
        ...values,
        [name]: checked,
      })
    } else {
      setValues({
        ...values,
        [name]: value,
      })
    }
    validate({ [name]: value })
  }
  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.firstName &&
      fieldValues.lastName &&
      fieldValues.email &&
      fieldValues.specialtyId &&
      fieldValues.password &&
      fieldValues.confirmPassword &&
      fieldValues.referralSourceId
    Object.values(errors).every(x => x === false)

    return isValid
  }
  const handleFormSubmit = async e => {
    e.preventDefault()
    // TODO: Why are we converting this to an array?? That's a problem for another day
    const specialties = convertValueToArray(values.specialtyId)
    const subspecialties = convertValueToArray(values.subspecialtyId)
    if (formIsValid()) {
      dispatch(
        fetchCreateCandidate({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          confirmPassword: values.confirmPassword,
          specialties,
          subspecialties,
          referralSourceId: values.referralSourceId,
          referralSourceOther: values.referralSourceOther,
          registrationContact: values.registrationContact,
          city: values.city,
          state: values.state,
        }),
      )
    }
  }

  const setsSpecialtyId = specialtyId => {
    initialFormValues.specialtyId = specialtyId
  }

  return {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    values,
    setsSpecialtyId,
  }
}
