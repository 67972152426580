import React, { useState, useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import JoyRideStepper from 'components/JoyRide'
import FeatureIntroDialog from 'components/Dialog/FeatureIntroDialog'
import { useTheme, useMediaQuery, Typography } from '@mui/material'
import { JoyrideContext } from 'components/JoyRide/JoyrideContext'
import { featureReleaseDates } from 'common/featureReleaseDates'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

const JobsPageTutorial = () => {
  const [showModal, setShowModal] = useState(false)
  const [runTour, setRunTour] = useState(false)
  const { setCurrentStep } = useContext(JoyrideContext)
  const dispatch = useDispatch()
  const tourName = 'jobsTutorial'
  const theme = useTheme()
  const below_lg = useMediaQuery(theme.breakpoints.down('md'))
  const { verifiedAt, firstName } = useSelector(state => state.candidate)
  const { email } = useSelector(state => state.identity)
  const stepperStorageVariable = 'hasSeen_' + tourName
  const hasSeen = localStorage.getItem(stepperStorageVariable)

  useEffect(() => {
    if (new Date(verifiedAt) > new Date(featureReleaseDates.userFilterAlerts) && !hasSeen) {
      setShowModal(true)
    }
  }, [verifiedAt, hasSeen])

  const startTour = () => {
    setShowModal(false)
    setRunTour(true)
    dispatch(
      fetchTrackEvent({
        eventName: `tour_${tourName}_start`,
        eventType: ProductAnalyticsEventTypes.TOUR_EVENT,
        userId: email,
      }),
    )
    localStorage.setItem(stepperStorageVariable, true)
  }

  const skipTour = () => {
    setShowModal(false)
    setRunTour(false)
    localStorage.setItem(stepperStorageVariable, true)
    dispatch(
      fetchTrackEvent({
        eventName: `tour_${tourName}_skip`,
        eventType: ProductAnalyticsEventTypes.TOUR_EVENT,
        userId: email,
      }),
    )
  }

  const header = `👋 Welcome, ${firstName}!`
  const content = `Let's help you get started with your job search!`
  const buttonText = 'Show me around'
  const steps = [
    {
      target: '.joyride_job_list',
      content: (
        <Typography variant='body1'>
          Here you can view all available jobs. Click on a job to see more details and find the
          perfect fit for you.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_job_content',
      content: (
        <Typography variant='body1'>
          This section shows the details of the selected job. If you need more information, check
          the hospital's link usually provided in the post.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_contact_recruiter',
      content: (
        <Typography variant='body1'>
          Get in touch with recruiters directly via email. Remember, there are no third-party
          recruiters here, so contact the hospital recruiter listed for more information.
        </Typography>
      ),
      disableBeacon: true,
      disableScrolling: true,
      placement: 'top',
    },
    {
      target: '.joyride_job_interest',
      content: (
        <Typography variant='body1'>
          Short on time? Click this button, and MyStethi will reach out to the appropriate recruiter
          on your behalf.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_rate_job',
      content: (
        <Typography variant='body1'>
          Help us improve! If you find inaccurate information in a job post, let us know, and we'll
          make sure to update it.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_filters',
      content: (
        <Typography variant='body1'>
          Use these filters to narrow down your job search to match your preferences.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_more_filters',
      content: (
        <Typography variant='body1'>
          Click here to gain access to even more ways to filter!
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_save_filters',
      content: (
        <Typography variant='body1'>
          Save your filters for quick access later. You can also set up email notifications for new
          jobs that match your criteria.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_saved_filters',
      content: (
        <Typography variant='body1'>
          Access your saved filters here for faster searches in the future.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_profile_header',
      content: (
        <Typography variant='body1'>
          Manage your profile and settings here. Don't for get to add details about yourself to make
          your profile more engaging. You can also manage your privacy settings here.
        </Typography>
      ),
      disableBeacon: true,
      placement: 'left',
    },
    {
      target: '.joyride_header_alerts',
      content: (
        <Typography variant='body1'>
          Manage and view your email alerts here. <br />
          Happy searching!
        </Typography>
      ),
      disableBeacon: true,
      placement: 'left',
    },
  ]

  const handleJoyrideCallback = ({ step }) => {
    setCurrentStep(step.index)
  }

  return (
    !below_lg && (
      <>
        <FeatureIntroDialog
          open={showModal}
          onClose={startTour}
          header={header}
          content={content}
          buttonText={buttonText}
          onSkip={skipTour}
        />
        {runTour && (
          <JoyRideStepper
            stepperSteps={steps}
            tourName={tourName}
            callback={handleJoyrideCallback}
          />
        )}
      </>
    )
  )
}

export default JobsPageTutorial
