import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import { CircularProgress } from '@mui/material'
import LoginForm from 'pages/Identity/LoginForm'
import { useStyles } from 'common/styles'

export const useIdentity = () => useSelector(state => state.identity)

export const useIsUser = () => {
  const { userId, candidateId, employerUserId, isEmployerAdmin, isSuper, signedIn } = useIdentity()
  return {
    userId,
    candidateId,
    isCandidate: !!candidateId,
    employerUserId,
    isEmployerUser: !!employerUserId,
    isEmployerAdmin,
    isSuper,
    signedIn,
  }
}

export default () => {
  const classes = useStyles({})

  const redirectTo = useSelector(state => state.identity.redirectTo)
  const candidate = useSelector(state => state.candidate)
  const employerUser = useSelector(state => state.employerUser)
  const loading =
    useSelector(state => state.identity.loading) || candidate.loading || employerUser.loading
  const { isSuper, isEmployerAdmin, isEmployerUser, isCandidate, isSignedIn } = useIsUser()
  if (loading) {
    return <CircularProgress className={classes.submitProgress} />
  } else {
    if (redirectTo && isSignedIn) {
      return <Navigate to={redirectTo} />
    } else if (isSuper) {
      return <Navigate to='/super/employer_users' />
    } else if (isCandidate) {
      if (candidate.setupSeen) {
        return <Navigate to='/jobs' />
      } else {
        return <Navigate to='/onboarding' />
      }
    } else if (isEmployerAdmin || isEmployerUser) {
      return <Navigate to='/physicians' />
    } else {
      return <LoginForm />
    }
  }
}
