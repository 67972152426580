import React from 'react'
import { Autocomplete, Chip, TextField, useTheme } from '@mui/material'
const GenericMultipleSelect = ({
  value,
  options,
  label,
  name,
  handleChange,
  disabled,
  ...props
}) => {
  const theme = useTheme()
  return (
    <Autocomplete
      multiple
      id={name}
      name={name}
      options={options}
      disabled={disabled}
      value={value || []}
      getOptionLabel={option => option || ''}
      onChange={(event, newValue) => handleChange({ target: { name: name, value: newValue } })}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant='outlined' label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          variant='outlined'
          label={label}
          size='small'
          sx={{ color: theme.palette.primary.dark, '& fieldset': disabled && { border: 'none' } }}
        />
      )}
      {...props}
    />
  )
}

export default GenericMultipleSelect
