import React from 'react'
import Loader from 'components/Loader'
import { fetchGetEmployerUsers } from 'src/redux/users'
import { useSelector, useDispatch } from 'react-redux'
import { AdminUsersTable } from 'components/Table/AdminUsersTable'
import { EditDialogButton } from 'components/Dialog/DialogButton'
import * as EmployerUserDialog from 'components/Dialog/EmployerUserDialog'
const Users = () => {
  const dispatch = useDispatch()
  const { loading, users } = useSelector(state => state.users)
  const { employerId } = useSelector(state => state.identity)

  const usersData = users.map(user => ({
    id: user.user.id,
    email: user.user.email,
    firstName: user.user.firstName,
    lastName: user.user.lastName,
    isEmployerAdmin: user.isEmployerAdmin === true ? 'Yes' : 'No',
    employer: user.employer.name,
    actions: (
      <EditDialogButton
        id={EmployerUserDialog.dialogId}
        initialValues={user}
        Module={EmployerUserDialog}
      />
    ),
  }))
  React.useEffect(() => {
    dispatch(fetchGetEmployerUsers(employerId))
  }, [dispatch])

  return <>{loading ? <Loader /> : <AdminUsersTable data={usersData} />}</>
}
export default Users
