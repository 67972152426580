import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardHeader, CardMedia, CardActions, Button, Grid, Paper } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import ListDataItem from 'components/Listing/ListDataItem'
import { getProfileImage } from './helpers'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

const RecruiterCard = ({ recruiter, employerUserId, jobId }) => {
  const dispatch = useDispatch()
  const { email } = useSelector(state => state.identity)
  const profileImage = getProfileImage(recruiter.profileImage)
  const subHeader = recruiter.jobTitle ? `${recruiter.jobTitle} @ ${recruiter.employer.name}` : null
  const handleContactClick = () => {
    dispatch(
      fetchTrackEvent({
        eventName: 'recruiter_contact_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: email,
        eventData: { recruiterUserId: recruiter.user.id, jobId: jobId },
      }),
    )
  }
  return (
    <Paper elevation={5} sx={{ width: '100%', marginBottom: '20px' }}>
      <Card
        sx={{
          minWidth: 275,
          padding: '20px',
        }}
      >
        <Grid container direction={'row'} spacing={1} wrap='nowrap'>
          <Grid item>
            <CardMedia
              sx={{
                width: '100%',
                height: '70px',
              }}
              component='img'
              src={profileImage}
              image={profileImage}
              title='Listing Photo'
            />
          </Grid>
          <Grid container wrap='nowrap'>
            <CardHeader
              style={{ overflowWrap: 'break-word' }}
              title={recruiter.user.firstName}
              subheader={subHeader}
            />
          </Grid>
        </Grid>
        <Grid container item justifyContent='flex-start' direction='row'>
          {recruiter.user.email && recruiter.user.showEmail && (
            <Grid container item justifyContent='flex-start' xs={12}>
              <ListDataItem text={recruiter.user.email} icon={<EmailIcon />} />
            </Grid>
          )}
          {recruiter.user.phone && recruiter.user.showPhone && (
            <Grid container item justifyContent='flex-start' xs={12}>
              <ListDataItem text={recruiter.user.phone} icon={<PhoneIphoneIcon />} />
            </Grid>
          )}
          {!employerUserId && (
            <CardActions>
              <div className='joyride_contact_recruiter'>
                <a href={`mailto:${recruiter.user.email}`}>
                  <Button variant='contained' size='medium' onClick={handleContactClick}>
                    Contact
                  </Button>
                </a>
              </div>
            </CardActions>
          )}
        </Grid>
      </Card>
    </Paper>
  )
}

export default RecruiterCard
