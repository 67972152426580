import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'
import { fetchDispatch } from 'data/helpers'

export const CREATE_EMPLOYER_LOADING = 'employer/CREATE_EMPLOYER_LOADING'
export const CREATE_EMPLOYER_SUCCESS = 'employer/CREATE_EMPLOYER_SUCCESS'
export const CREATE_EMPLOYER_FAILURE = 'employer/CREATE_EMPLOYER_FAILURE'

export const GET_EMPLOYERS_LOADING = 'employer/GET_EMPLOYERS_LOADING'
export const GET_EMPLOYERS_SUCCESS = 'employer/GET_EMPLOYERS_SUCCESS'
export const GET_EMPLOYERS_FAILURE = 'employer/GET_EMPLOYERS_FAILURE'

export const UPDATE_EMPLOYER_LOADING = 'employer/UPDATE_EMPLOYER_LOADING'
export const UPDATE_EMPLOYER_SUCCESS = 'employer/UPDATE_EMPLOYER_SUCCESS'
export const UPDATE_EMPLOYER_FAILURE = 'employer/UPDATE_EMPLOYER_FAILURE'

export const createEmployerLoading = () => ({ type: CREATE_EMPLOYER_LOADING })
export const createEmployerSuccess = () => ({ type: CREATE_EMPLOYER_SUCCESS })
export const createEmployerFailure = payload => ({ type: CREATE_EMPLOYER_FAILURE, payload })

export const getEmployersLoading = () => ({ type: GET_EMPLOYERS_LOADING })
export const getEmployersSuccess = payload => ({ type: GET_EMPLOYERS_SUCCESS, payload })
export const getEmployersFailure = payload => ({ type: GET_EMPLOYERS_FAILURE, payload })

export const updateEmployerLoading = () => ({ type: UPDATE_EMPLOYER_LOADING })
export const updateEmployerSuccess = () => ({ type: UPDATE_EMPLOYER_SUCCESS })
export const updateEmployerFailure = payload => ({ type: UPDATE_EMPLOYER_FAILURE, payload })

export const fetchCreateEmployer = query => {
  return async dispatch => {
    dispatch(createEmployerLoading())
    try {
      await fetchDispatch({
        path: '/api/employers',
        values: query,
        method: 'POST',
      })
      dispatch(fetchGetEmployers())
      dispatch(createEmployerSuccess())
      dispatch(showSnackbar('Created employer', snackbarTypes.SUCCESS))
    } catch (error) {
      dispatch(
        showSnackbar(
          'Encountered error creating employers: ' + error.response,
          snackbarTypes.ERROR,
        ),
      )
      dispatch(createEmployerFailure(error.response))
    }
  }
}

export const fetchGetEmployers = () => {
  return async dispatch => {
    dispatch(getEmployersLoading())
    try {
      const response = await fetchDispatch({
        path: '/api/employers',
        method: 'GET',
      })
      dispatch(getEmployersSuccess(response.employers))
    } catch (error) {
      dispatch(
        showSnackbar('Encountered error getting employers: ' + error.response, snackbarTypes.ERROR),
      )
      dispatch(getEmployersFailure(error.response))
    }
  }
}

export const fetchUpdateEmployer = query => {
  return async dispatch => {
    dispatch(updateEmployerLoading())
    try {
      await fetchDispatch({
        path: `/api/employers/${query.id}`,
        values: query,
        method: 'PUT',
        formData: true,
      })
      dispatch(fetchGetEmployers())
      dispatch(updateEmployerSuccess())
      dispatch(showSnackbar('Updated employer', snackbarTypes.SUCCESS))
    } catch (error) {
      dispatch(
        showSnackbar(
          'Encountered error updating employers: ' + error.response,
          snackbarTypes.ERROR,
        ),
      )
      dispatch(updateEmployerFailure(error.response))
    }
  }
}

const initialState = {
  loading: false,
  errors: [],
  employers: [],
}

export const employerReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case CREATE_EMPLOYER_LOADING:
      return {
        ...state,
        loading: true,
      }
    case CREATE_EMPLOYER_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
      }
    case CREATE_EMPLOYER_FAILURE:
      return {
        ...state,
        loading: false,
        errors: payload,
      }
    case GET_EMPLOYERS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_EMPLOYERS_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
        employers: payload,
      }
    case GET_EMPLOYERS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: payload,
      }
    default:
      return {
        ...state,
      }
  }
}
