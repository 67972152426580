import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GenericDialog from 'components/Dialog/GenericDialog'
import { copyToLower, sortAscendingKey } from 'data/helpers'
import { useDialogState } from 'data/dialog/actions'
import { useSpecialties } from 'data/layout/actions'
import { fetchCandidateTypes } from 'src/redux/candidate'
import { States, getHourlySalaryRange, getAnnualSalaryRange } from 'data/constants'
import {
  getRawItems,
  PRACTICE_TYPE_ENUM,
  AREA_ENUM,
  ACADEMIC_ENUM,
  VISA_ENUM,
  SHIFT_TYPE_ENUM,
  SALARY_TYPE_ENUM,
} from 'data/enums'
import { useJob } from 'data/job/hooks'
import { useIdentity } from 'pages/Identity/Login'
import { isEdit, isView } from 'components/Dialog/helpers'
import { CANDIDATE_TYPE_ENUM } from '../../../data/enums'

export const dialogId = 'Job'

const handleValidation = (values, dialogState, initialValues) => {
  var errors = copyToLower(dialogState.errors) // start with server generated errors, ensure all keys start with lowercase letter
  if (values['specialtyId']) {
    if (
      dialogState.formSpecialties.find(s => s.id === values['specialtyId']) === undefined &&
      dialogState.formSpecialties.find(s => s.id === initialValues['specialtyId']) === undefined
    ) {
      errors['specialtyId'] = 'Pick a valid specialty for ' + dialogState['jobType'] + ' type'
    }
  }
  if (values['subspecialtyId']) {
    if (
      dialogState.subspecialties.find(x => x.id === values['subspecialtyId']).specialtyId !==
      values['specialtyId']
    ) {
      errors['subspecialtyId'] = 'Pick a valid Sub-Specialty for the specialty type'
    }
  }

  return errors
}

const defaultValues = {}

export default function JobDialog({ id = dialogId, onResponse: OnResponse = undefined }) {
  const { specialties, subspecialties } = useSpecialties(open)
  const [
    { open, type, values = {}, initialValues, jobType, formSpecialties = specialties, salaryRange },
    setState,
  ] = useDialogState(id)
  const candidateTypes = useSelector(state => state.candidate.candidateTypes) || []
  const dispatch = useDispatch()
  const { employerUserId, isEmployerAdmin } = useIdentity()
  const [, getJob, setJob, createJob] = useJob()
  const jobId = initialValues.id

  useEffect(() => {
    open && (isView(type) || isEdit(type)) && getJob(jobId)
  }, [open, type, getJob, jobId])

  useEffect(() => {
    const allSpecialties = jobType
      ? specialties.filter(s => s.candidateType.name === jobType)
      : specialties
    setState(prev => ({
      ...prev,
      formSpecialties: allSpecialties,
      subspecialties: subspecialties,
    }))
  }, [jobType, open, specialties])

  useEffect(() => {
    if (values['salaryType']) {
      if (values['salaryType'] === 'Annual') {
        setState(prev => ({ ...prev, salaryRange: getAnnualSalaryRange().values }))
      } else {
        setState(prev => ({ ...prev, salaryRange: getHourlySalaryRange().values }))
      }
    }
    if (initialValues.candidateTypeId) {
      setState(prev => ({ ...prev, jobType: CANDIDATE_TYPE_ENUM[initialValues.candidateTypeId] }))
    }
  }, [values, open])

  useEffect(() => {
    dispatch(fetchCandidateTypes({}))
    setState(prev => ({
      ...prev,
      initialValues,
      open,
      type,
      values: {},
      jobType: initialValues.candidateTypeId
        ? CANDIDATE_TYPE_ENUM[initialValues.candidateTypeId]
        : null,
      value: {},
      formSpecialties: specialties,
      subspecialties: subspecialties,
      salaryRange: getAnnualSalaryRange().values,
    }))
  }, [])

  const handleSubmit = React.useCallback(
    values => {
      values = {
        ...values,
        employerUserId,
        areaType: values.area,
        academicType: values.candidateTypeId === 1 ? values.academic : null,
        shiftType: values.candidateTypeId === 2 ? values.shiftType : null,
      }
      isEdit(type)
        ? setJob(values, setState, onResponse)
        : createJob(values, setState, onResponse, isEmployerAdmin)
      setState(prev => ({ ...prev, jobType: null }))
    },
    [type, setJob, createJob, employerUserId, setState, onResponse],
  )

  const onResponse = React.useCallback(
    response => {
      // TODO get jobs: dispatch(getUsers(dispatch, isEmployerAdmin ? { Id: employerId } : {}))
      OnResponse && OnResponse(response)
    },
    [OnResponse],
  )

  const about = isEdit(type) ? initialValues.about : null
  return (
    <GenericDialog
      id={id}
      title={id}
      maxWidth='md'
      onSubmit={handleSubmit}
      validate={handleValidation}
      initialValues={isView(type) || isEdit(type) ? initialValues : defaultValues}
      submitLabel='Save'
      cancelLabel={isView(type) ? 'Close' : 'Cancel'}
      fields={[
        {
          id: 'id',
          hidden: true,
        },
        {
          id: 'employerUserId',
          hidden: true,
        },
        {
          id: 'candidateTypeId',
          label: 'Please choose a job type first',
          dialogVar: 'jobType',
          type: 'autocomplete',
          required: true,
          forceErrorMargin: false,
          variant: 'outlined',
          dialogId: dialogId,
          items: candidateTypes.map(k => ({
            value: k.id,
            label: k.name,
          })),
          margin: 'dense',
          disableClearable: true,
          xs: 6,
        },
        {
          id: 'practiceType',
          label: 'Practice Type',
          type: 'autocomplete',
          required: true,
          forceErrorMargin: false,
          disableClearable: true,
          variant: 'outlined',
          items: getRawItems(PRACTICE_TYPE_ENUM),
          margin: 'dense',
          xs: 6,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },
        {
          id: 'shiftType',
          label: 'Shift Type',
          type: 'autocomplete',
          hidden:
            (values['candidateTypeId'] === undefined &&
              initialValues['candidateTypeId'] === undefined) ||
            (jobType !== 'Nurse' && initialValues['candidateTypeId'] !== 2)
              ? true
              : false,
          required: false,
          forceErrorMargin: false,
          disableClearable: true,
          defaultValue: null,
          variant: 'outlined',
          items: getRawItems(SHIFT_TYPE_ENUM),
          margin: 'dense',
          xs: 12,
        },
        {
          id: 'title',
          label: 'Job Title',
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
          required: true,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
        },

        {
          id: 'city',
          label: 'City',
          placeholder: 'city',
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
          required: true,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'state',
          label: 'State',
          type: 'autocomplete',
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
          required: true,
          forceErrorMargin: false,
          variant: 'outlined',
          items: Object.keys(States).map(k => ({ value: k, label: `${States[k]}` })),
          margin: 'dense',
          disableClearable: true,
          xs: 6,
        },
        {
          id: 'specialtyId',
          type: 'autocomplete',
          label: 'Specialty',
          xs: 6,
          forceErrorMargin: false,
          required: true,
          variant: 'outlined',
          margin: 'dense',
          items: formSpecialties.map(s => ({ value: s.id, label: s.name })),
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },
        {
          id: 'subspecialtyId',
          type: 'autocomplete',
          label: 'Subspecialty',
          xs: 6,
          forceErrorMargin: false,
          required: false,
          variant: 'outlined',
          margin: 'dense',
          items: subspecialties
            .map(s => ({ value: s.id, label: s.name }))
            .sort(sortAscendingKey('label')),
          filter: (items, values) => {
            return items.filter(
              item =>
                Number(values['specialtyId']) ===
                subspecialties.find(x => x.id === item.value).specialtyId,
            )
          },
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },

        {
          id: 'visas',
          label: 'Visa Sponsorship',
          type: 'autocomplete',
          forceErrorMargin: false,
          required: false,
          variant: 'outlined',
          items: getRawItems(VISA_ENUM),
          margin: 'dense',
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
          xs: 4,
        },
        {
          id: 'loanForgiveness',
          label: 'Loan Forgiveness',
          type: 'autocomplete',
          forceErrorMargin: false,
          disableClearable: true,
          required: false,
          variant: 'outlined',
          items: [
            { value: false, label: 'No' },
            { value: true, label: 'Yes' },
          ],
          margin: 'dense',
          xs: 4,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },
        {
          id: 'pslfCompatible',
          label: 'PSLF Compatible',
          type: 'autocomplete',
          forceErrorMargin: false,
          disableClearable: true,
          required: false,
          variant: 'outlined',
          items: [
            { value: false, label: 'No' },
            { value: true, label: 'Yes' },
          ],
          margin: 'dense',
          xs: 4,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },
        {
          id: 'area',
          label: 'Area',
          type: 'autocomplete',
          forceErrorMargin: false,
          required: false,
          variant: 'outlined',
          items: getRawItems(AREA_ENUM),
          margin: 'dense',
          xs: 6,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },
        {
          id: 'academic',
          label: 'Academic',
          type: 'autocomplete',
          forceErrorMargin: false,
          required: false,
          variant: 'outlined',
          items: getRawItems(ACADEMIC_ENUM),
          margin: 'dense',
          xs: 6,
          hidden:
            (values['candidateTypeId'] === undefined &&
              initialValues['candidateTypeId'] === undefined) ||
            jobType === 'Nurse' ||
            initialValues['candidateTypeId'] === 2
              ? true
              : false,
        },
        {
          id: 'salaryType',
          label: 'Salary Cadence',
          type: 'autocomplete',
          forceErrorMargin: false,
          disableClearable: true,
          variant: 'outlined',
          items: getRawItems(SALARY_TYPE_ENUM),
          margin: 'dense',
          xs: 3,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },
        {
          id: 'salaryMin',
          label: 'Minimum Salary',
          type: 'autocomplete',
          forceErrorMargin: false,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined &&
            (values['salaryType'] === undefined || initialValues['salaryType'] === undefined)
              ? true
              : false,
          disableClearable: true,
          variant: 'outlined',
          items: salaryRange,
          filter: (items, values) => {
            return values['salaryMax']
              ? items.filter(item => item.value < values['salaryMax'])
              : items
          },
          margin: 'dense',
          xs: 3,
        },
        {
          id: 'salaryMax',
          label: 'Maximum Salary',
          type: 'autocomplete',
          forceErrorMargin: false,
          disableClearable: true,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined &&
            (values['salaryType'] === undefined || initialValues['salaryType'] === undefined)
              ? true
              : false,
          variant: 'outlined',
          items: salaryRange,
          filter: (items, values) => {
            return values['salaryMin']
              ? items.filter(item => item.value > values['salaryMin'])
              : items
          },
          margin: 'dense',
          xs: 3,
        },
        {
          id: 'salary',
          label: 'Salary (Optional)',
          type: 'money',
          thousandSeparator: true,
          forceErrorMargin: false,
          required: false,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined &&
            (values['salaryType'] === undefined || initialValues['salaryType'] === undefined)
              ? true
              : false,
          variant: 'outlined',
          margin: 'dense',
          xs: 3,
        },
        {
          id: 'link',
          label: 'External Job Link',
          type: 'text',
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          multiline: false,
          required: false,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },
        {
          id: 'about',
          label: 'About',
          type: 'textEditor',
          initialValue: about ? about : 'Enter a description of the job',
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          multiline: true,
          required: false,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },
        {
          id: 'aboutRaw',
          hidden: true,
        },
      ]}
    />
  )
}
