import React from 'react'
import { Box, Grid, Typography, useTheme, useMediaQuery } from '@mui/material'
import StateField from 'components/UserFields/StateField'
import GenericTextField from 'components/UserFields/GenericTextField'

const Locations = ({ values, handleChange }) => {
  const theme = useTheme()
  const below_lg = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box
      sx={[
        { border: '1px solid gray', padding: '20px', margin: '20px 0px' },
        below_lg && { margin: '20px 0px 50px 0px' },
      ]}
    >
      <Typography variant='h6'>Location</Typography>
      <Grid container direction='row' spacing={3} sx={{ padding: '20px 0px' }}>
        <Grid item xs={12} sm={6}>
          <GenericTextField
            name='city'
            label='Current City'
            value={values.city}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StateField
            handleChange={handleChange}
            value={values.state}
            label='Current state'
            name='state'
          />
        </Grid>
      </Grid>
      <Grid container direction='row' spacing={3}>
        <Grid item xs={12} sm={6}>
          <GenericTextField
            name='preferredCity'
            label='Preferred City'
            value={values.preferredCity}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StateField
            handleChange={handleChange}
            value={values.preferredState}
            label='Preferred state'
            name='preferredState'
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Locations
