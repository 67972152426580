import React, { useContext, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Joyride, { STATUS, ACTIONS } from 'react-joyride'
import { useTheme, useMediaQuery } from '@mui/material'
import { JoyrideContext } from 'components/JoyRide/JoyrideContext'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

export const JoyRideStepper = ({ stepperSteps = [], tourName = '' }) => {
  const dispatch = useDispatch()
  const { email } = useSelector(state => state.identity)
  const theme = useTheme()
  const below_lg = useMediaQuery(theme.breakpoints.down('md'))
  const [run, setRun] = React.useState(!below_lg)
  const [steps] = React.useState(stepperSteps)
  const stepperStorageVariable = 'hasSeen_' + tourName
  const { setCurrentStep } = useContext(JoyrideContext)

  const previousIndexRef = useRef(null)
  const previousStatusRef = useRef(null)

  const isElementVisible = selector => {
    const element = document.querySelector(selector)
    if (!element) return false // Element does not exist
    const rect = element.getBoundingClientRect()
    return (
      rect.width > 0 &&
      rect.height > 0 &&
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  const checkElements = () => {
    let elementsExist = true
    steps.forEach(step => {
      elementsExist = elementsExist && isElementVisible(step.target)
    })
    if (elementsExist && !below_lg) {
      setRun(true)
    } else {
      setTimeout(checkElements, 100) // Retry after 100ms if elements are not found
    }
  }

  useEffect(() => {
    checkElements()
  }, [])

  const handleJoyrideCallback = useCallback(
    data => {
      const { status, index, action, step } = data
      const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]
      if (finishedStatuses.includes(status) || action === ACTIONS.CLOSE) {
        setRun(false) // Stop the tutorial immediately
        localStorage.setItem(stepperStorageVariable, true)
        if (action === ACTIONS.CLOSE) {
          dispatch(
            fetchTrackEvent({
              eventName: `tour_${tourName}_step_${index}_skipped`,
              eventType: ProductAnalyticsEventTypes.TOUR_EVENT,
              userId: email,
            }),
          )
        } else {
          setCurrentStep(null)
          dispatch(
            fetchTrackEvent({
              eventName: `tour_${tourName}_finished`,
              eventType: ProductAnalyticsEventTypes.TOUR_EVENT,
              userId: email,
            }),
          )
        }
      } else if (index !== previousIndexRef.current || status !== previousStatusRef.current) {
        previousIndexRef.current = index
        previousStatusRef.current = status
        setCurrentStep(step.target)
        dispatch(
          fetchTrackEvent({
            eventName: `tour_${tourName}_step_${index}`,
            eventType: ProductAnalyticsEventTypes.TOUR_EVENT,
            userId: email,
          }),
        )
      }
    },
    [setCurrentStep, dispatch, email, tourName, stepperStorageVariable],
  )

  return (
    run && (
      <Joyride
        styles={{
          buttonNext: {
            backgroundColor: theme.palette.secondary.main,
            color: '#fff',
            borderRadius: '4px',
            padding: '10px 20px',
          },
          buttonBack: {
            color: '#999',
            marginRight: '10px',
          },
          buttonClose: {
            color: theme.palette.primary.dark,
          },
        }}
        steps={steps}
        run={run}
        continuous
        callback={handleJoyrideCallback}
        locale={{
          last: 'Finish',
          skip: 'Skip tutorial', // Ensure the skip button text is set
        }}
      />
    )
  )
}

export default JoyRideStepper
