import * as React from 'react'
import { Collapse } from '@mui/material'
import InformationalAlert from './InformationalAlert'

export const AlertWrapper = ({ text, closeAction }) => {
  const [open, setOpen] = React.useState(true)

  return (
    <Collapse
      in={open}
      timeout='auto'
      // mountOnEnter <=== not a supported prop
      // unmountOnExit <=== not a supported prop
    >
      <InformationalAlert text={text} closeAction={closeAction} setOpen={setOpen} />
    </Collapse>
  )
}

export default AlertWrapper
