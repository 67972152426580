import React from 'react'
import { Link, Grid, Typography } from '@mui/material'
import RestartAltIcon from '@mui/icons-material/RestartAlt'

export const ClearFiltersButton = ({ handleClearAll }) => {
  return (
    <Link
      sx={{ cursor: 'pointer', color: 'white', textDecoration: 'none' }}
      onClick={() => {
        handleClearAll([])
      }}
    >
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Typography sx={{ fontSize: '.8rem' }}>Clear Selections</Typography>
        <RestartAltIcon sx={{ marginLeft: '5px' }} />
      </Grid>
    </Link>
  )
}
