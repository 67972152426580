import React from 'react'
import { Grid } from '@mui/material'
import NPINumberField from 'components/UserFields/NPINumberField'
import GenericSelectField from 'components/UserFields/GenericSelectField'
import {
  getBoardingEligibilityOptions,
  getLicensedStatesOptions,
  getVisaOptions,
} from 'components/UserFields/helpers'
import GenericMultipleSelect from 'components/UserFields/GenericMultipleSelect'

const Eligibility = ({ values, handleChange, setErrors, errors }) => {
  return (
    <Grid container direction='row' sx={{ padding: '20px 0px' }} spacing={3}>
      <Grid item xs={12} sm={6}>
        <NPINumberField
          handleChange={handleChange}
          value={values.npiNumber}
          label='NPI Number'
          name='npiNumber'
          setErrors={setErrors}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <GenericSelectField
          value={values.boardEligibility}
          label='Board Eligibility'
          name='boardEligibility'
          handleChange={handleChange}
          options={getBoardingEligibilityOptions()}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <GenericMultipleSelect
          value={values.licensedStates}
          label='Licensed States'
          name='licensedStates'
          handleChange={handleChange}
          options={getLicensedStatesOptions()}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <GenericSelectField
          value={values.visaId}
          label='Visa Requirement'
          name='visaId'
          handleChange={handleChange}
          options={getVisaOptions()}
          helperText='Will you now or in the future require visa sponsorship?'
        />
      </Grid>
    </Grid>
  )
}

export default Eligibility
