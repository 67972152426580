import axios from 'axios'
import { buildFormData } from 'src/redux/helpers'
import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'
import { fetchDispatch } from 'data/helpers'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import { capitalizeWords } from 'common/helpers'

export const CREATE_CANDIDATE_LOADING = 'candidate/CREATE_CANDIDATE_LOADING'
export const CREATE_CANDIDATE_SUCCESS = 'candidate/CREATE_CANDIDATE_SUCCESS'
export const CREATE_CANDIDATE_FAILURE = 'candidate/CREATE_CANDIDATE_FAILURE'
export const GET_CANDIDATE_LOADING = 'candidate/GET_CANDIDATE_LOADING'
export const GET_CANDIDATE_SUCCESS = 'candidate/GET_CANDIDATE_SUCCESS'
export const GET_CANDIDATE_FAILURE = 'candidate/GET_CANDIDATE_FAILURE'
export const UPDATE_CANDIDATE_LOADING = 'candidate/UPDATE_CANDIDATE_LOADING'
export const UPDATE_CANDIDATE_SUCCESS = 'candidate/UPDATE_CANDIDATE_SUCCESS'
export const UPDATE_CANDIDATE_FAILURE = 'candidate/UPDATE_CANDIDATE_FAILURE'
export const GET_CANDIDATES_LOADING = 'candidate/GET_CANDIDATES_LOADING'
export const GET_CANDIDATES_SUCCESS = 'candidate/GET_CANDIDATES_SUCCESS'
export const GET_CANDIDATES_FAILURE = 'candidate/GET_CANDIDATES_FAILURE'
export const GET_CANDIDATE_TYPES_LOADING = 'candidate/GET_CANDIDATE_TYPES_LOADING'
export const GET_CANDIDATE_TYPES_SUCCESS = 'candidate/GET_CANDIDATE_TYPES_SUCCESS'
export const GET_CANDIDATE_TYPES_FAILURE = 'candidate/GET_CANDIDATE_TYPES_FAILURE'

const mapPhysicianData = physicians =>
  physicians.map(physician => ({
    ...physician,
    firstName: physician.user.firstName,
    lastName: physician.user.lastName,
    city: physician.user.city,
    state: physician.user.state,
    profileImage: physician.user.profileImage,
    listingTitle: `${physician.user.firstName} ${physician.user.lastName}`,
    listingSubLabel: `${physician.specialties[0].name}${physician.subspecialties && physician.subspecialties.length > 0 ? ' | ' + physician.subspecialties[0].name : ''}`,
    image: physician.user.profileImage,
    phone: physician.user.phone,
    email: physician.user.email,
    showPhone: physician.user.showPhone,
    showEmail: physician.user.showEmail,
    createdAt: physician.user.createdAt,
    lastLoginAt: physician.user.lastLoginAt,
    emailVerifiedAt: physician.user.emailVerifiedAt,
  }))

const cleanPhysicianData = physician => {
  let cleanedPhysician = { ...physician }
  if (cleanedPhysician.firstName) {
    cleanedPhysician.firstName = capitalizeWords(cleanedPhysician.firstName.trim())
  }
  if (cleanedPhysician.lastName) {
    cleanedPhysician.lastName = capitalizeWords(cleanedPhysician.lastName.trim())
  }
  if (cleanedPhysician.city) {
    cleanedPhysician.city = capitalizeWords(cleanedPhysician.city.trim())
  }
  if (cleanedPhysician.preferredCity) {
    cleanedPhysician.preferredCity = capitalizeWords(cleanedPhysician.preferredCity.trim())
  }
  if (cleanedPhysician.email) {
    cleanedPhysician.email = cleanedPhysician.email.trim().toLowerCase()
  }
  if (cleanedPhysician.residency) {
    cleanedPhysician.residency = capitalizeWords(cleanedPhysician.residency.trim())
  }
  return cleanedPhysician
}

export const createCandidateLoading = () => ({ type: CREATE_CANDIDATE_LOADING })
export const createCandidateSuccess = payload => ({
  type: CREATE_CANDIDATE_SUCCESS,
  payload,
})
export const createCandidateFailure = payload => ({
  type: CREATE_CANDIDATE_FAILURE,
  payload,
})
export const getCandidateLoading = () => ({ type: GET_CANDIDATE_LOADING })
export const getCandidateSuccess = payload => ({ type: GET_CANDIDATE_SUCCESS, payload })
export const getCandidateFailure = payload => ({ type: GET_CANDIDATE_FAILURE, payload })
export const updateCandidateLoading = ({ photoLoading, resumeLoading }) => ({
  type: UPDATE_CANDIDATE_LOADING,
  photoLoading,
  resumeLoading,
})
export const updateCandidateSuccess = payload => ({
  type: UPDATE_CANDIDATE_SUCCESS,
  payload,
  isPhotoLoading: false,
  isResumeLoading: false,
})
export const updateCandidateFailure = payload => ({
  type: UPDATE_CANDIDATE_FAILURE,
  payload,
  isPhotoLoading: false,
  isResumeLoading: false,
})
export const getCandidatesLoading = () => ({ type: GET_CANDIDATES_LOADING })
export const getCandidatesSuccess = payload => ({ type: GET_CANDIDATES_SUCCESS, payload })
export const getCandidatesFailure = payload => ({ type: GET_CANDIDATES_FAILURE, payload })

export const getCandidateTypesLoading = () => ({ type: GET_CANDIDATE_TYPES_LOADING })
export const getCandidateTypesSuccess = payload => ({ type: GET_CANDIDATE_TYPES_SUCCESS, payload })
export const getCandidateTypesFailure = payload => ({ type: GET_CANDIDATE_TYPES_FAILURE, payload })

export const fetchCreateCandidate = values => {
  return async dispatch => {
    dispatch(createCandidateLoading())
    try {
      const response = await axios({
        url: '/api/candidates',
        method: 'POST',
        data: cleanPhysicianData(values),
      })
      dispatch(createCandidateSuccess(response.data.candidate))
      dispatch(showSnackbar('Success. Please verify your email to log in.', snackbarTypes.SUCCESS))
    } catch (error) {
      dispatch(showSnackbar('Error creating your profile.', snackbarTypes.ERROR))
      dispatch(createCandidateFailure(error.response.data))
    }
  }
}

export const fetchGetCandidate = id => {
  return async dispatch => {
    dispatch(getCandidateLoading())
    try {
      const response = await axios({
        url: `/api/candidates/${id}`,
        method: 'GET',
      })
      dispatch(getCandidateSuccess(response.data.candidate))
    } catch (error) {
      dispatch(showSnackbar('Error retrieving user data.', snackbarTypes.ERROR))
      dispatch(getCandidateFailure(error.response.data))
    }
  }
}

export const fetchUpdatePassword = data => {
  return fetchUpdateCandidate(
    data,
    'Successfully updated password.',
    'An error occurred updating your password.',
  )
}

export const fetchUpdateCandidate = (data, successMessage, failureMessage, onSuccess) => {
  return async dispatch => {
    const successMsg = successMessage || 'Successfully updated profile.'
    const failureMsg = failureMessage || 'An error occurred updating your profile'
    const isPhotoUpload = !!data.profileImageFile
    const isResumeUpload = !!data.resumeFile
    dispatch(updateCandidateLoading({ photoLoading: isPhotoUpload, resumeLoading: isResumeUpload }))
    try {
      const response = await axios({
        url: `/api/candidates/${data.id}`,
        method: 'PUT',
        data: buildFormData(cleanPhysicianData(data)),
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      if (!data.suppressSnackbar) {
        dispatch(showSnackbar(successMsg, snackbarTypes.SUCCESS))
      }
      dispatch(updateCandidateSuccess(response.data.candidate))
      if (onSuccess) {
        dispatch(
          fetchTrackEvent({
            eventName: 'candidate_profile_update_success',
            eventType: ProductAnalyticsEventTypes.ACTION,
            userId: response.data.candidate.user.email,
          }),
        )
        onSuccess.function(onSuccess.params)
      }
    } catch (error) {
      if (!data.suppressSnackbar) {
        dispatch(showSnackbar(failureMsg, snackbarTypes.ERROR))
      }
      dispatch(updateCandidateFailure(error))
      throw error
    }
  }
}

export const fetchAllCandidates = searchParams => {
  return async dispatch => {
    searchParams = new URLSearchParams(searchParams)
    dispatch(getCandidatesLoading())
    try {
      const response = await fetchDispatch({
        path: `/api/candidates?${searchParams.toString()}`,
        method: 'GET',
      })

      dispatch(getCandidatesSuccess(response))
    } catch (error) {
      dispatch(showSnackbar('Error retrieving user data.', snackbarTypes.ERROR))
      dispatch(getCandidatesFailure(error.response))
    }
  }
}

export const fetchCandidateTypes = () => {
  return async dispatch => {
    dispatch(getCandidateTypesLoading())
    try {
      const response = await fetchDispatch({
        path: '/api/candidates/types',
        method: 'GET',
      })
      dispatch(getCandidateTypesSuccess(response))
    } catch (error) {
      dispatch(getCandidateTypesFailure(error.response))
    }
  }
}

const initialState = {
  loading: false,
  errors: [],
  registrationSubmitted: false,
  candidateTypes: [],
  filters: [],
  photoLoading: false,
}

export const candidateReducer = (state = initialState, action) => {
  const { payload, photoLoading, resumeLoading } = action
  switch (action.type) {
    case GET_CANDIDATE_LOADING:
      return {
        ...state,
        loading: true,
        photoLoading: true,
      }
    case UPDATE_CANDIDATE_LOADING:
      return {
        ...state,
        loading: true,
        photoLoading,
        resumeLoading,
      }
    case GET_CANDIDATES_LOADING:
      return {
        ...state,
        loading: true,
        photoLoading: true,
      }
    case GET_CANDIDATE_SUCCESS:
    case UPDATE_CANDIDATE_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
        id: payload.id,
        firstName: payload.user.firstName,
        lastName: payload.user.lastName,
        email: payload.user.email,
        phone: payload.user.phone,
        city: payload.user.city,
        state: payload.user.state,
        profileImage: payload.user.profileImage,
        npiNumber: payload.npiNumber,
        about: payload.about,
        preferredCity: payload.preferredCity,
        preferredState: payload.preferredState,
        residency: payload.residency,
        residencyStart: payload.residencyStart,
        residencyEnd: payload.residencyEnd,
        resume: payload.resume,
        showEmail: payload.user.showEmail,
        showPhone: payload.user.showPhone,
        specialties: payload.specialties,
        specialtyId: payload.specialties[0].id,
        subspecialties: payload.subspecialties,
        subspecialtyId:
          payload.subspecialties && payload.subspecialties.length > 0
            ? payload.subspecialties[0].id
            : null,
        photoLoading: false,
        resumeLoading: false,
        setupSeen: payload.setupSeen,
        filters: payload.userFilters,
        availabilityDate: payload.availabilityDate,
        searchStatus: payload.searchStatus,
        boardEligibility: payload.boardEligibility,
        visaId: payload.visaInfo ? payload.visaInfo.id : null,
        aboutJob: payload.aboutJob,
        licensedStates: payload.licensedStates,
        verifiedAt: payload.user.emailVerifiedAt,
      }
    case GET_CANDIDATES_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
        totalCandidates: payload.totalCount,
        list: mapPhysicianData(payload.candidates),
      }
    case GET_CANDIDATE_FAILURE:
    case GET_CANDIDATES_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_CANDIDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errors: payload.errors,
        photoLoading: false,
        resumeLoading: false,
      }
    case CREATE_CANDIDATE_LOADING:
      return {
        ...state,
        loading: true,
        registrationSubmitted: false,
      }
    case CREATE_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        registrationSubmitted: true,
      }
    case CREATE_CANDIDATE_FAILURE:
      return {
        ...state,
        loading: false,
        registrationSubmitted: false,
      }

    case GET_CANDIDATE_TYPES_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_CANDIDATE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        candidateTypes: payload.candidateTypes,
      }
    case GET_CANDIDATE_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return {
        ...state,
      }
  }
}
