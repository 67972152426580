import * as React from 'react'
import { Grid, Typography } from '@mui/material'
import { DeleteDialogButton } from 'components/Dialog/DialogButton'
import DialogButton from 'components/Dialog/DialogButton'
import { formatDate } from 'data/helpers'
import * as DeleteDialog from 'components/Dialog/DeleteDialog'
import { fetchUpdateUserFilter } from 'src/redux/filters'
import * as AlertDialog from 'components/Dialog/AlertDialog'

export const transformFiltersData = (filters, employerUserId) => {
  return filters.map(filter => {
    const initialValues = {
      filterId: filter.id,
      cadenceId: filter.alertCadence,
    }
    const deleteData = {
      id: filter.id,
      userId: filter.userId,
      delete: true,
    }
    const cadence =
      filter.alertCadence !== 'None' && filter.alertCadence !== null ? (
        <Grid container>{filter.alertCadence}</Grid>
      ) : employerUserId && filter.filterType.name === 'Job' ? (
        <i>Job filters are not eligible</i>
      ) : (
        <DialogButton
          Module={AlertDialog}
          color='black'
          size='small'
          variant='link'
          type='edit'
          initialValues={{ filterId: filter.id }}
        >
          Add notification
        </DialogButton>
      )
    return {
      name: filter.name,
      type: filter.filterType.name,
      cadence: cadence,
      created: formatDate(filter.createdAt),
      actions: (
        <Grid container>
          <DeleteDialogButton
            Module={DeleteDialog}
            size='small'
            id='Delete Dialog'
            buttonText={'Delete'}
            data={deleteData}
            onDelete={fetchUpdateUserFilter}
            initialValues={initialValues}
            title='⚠️ Delete Filter'
            Content={() => (
              <Grid>
                <Typography variant='body1' sx={{ lineHeight: 2 }}>
                  Are you sure you want to delete this saved filter? <br /> Any alerts and their
                  records will also be removed. <br /> Remember, you can always add a new saved
                  filter later.
                </Typography>
              </Grid>
            )}
          />
        </Grid>
      ),
    }
  })
}
