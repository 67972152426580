import React from 'react'
import {
  Paper,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Divider,
  Grid,
  Alert,
  useTheme,
} from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import ListDataItem from './ListDataItem'
import { getLocation, getSpecialty } from 'pages/Jobs/helpers'

export const ListItemJob = ({ data, isActive, onClick }) => {
  const theme = useTheme()
  const location = getLocation(data.city, data.state)
  const specialty = getSpecialty(data.specialty, data.subspecialty)
  const postedAt = 'Posted: ' + new Date(data.createdAt).toDateString()
  const deletedAt = 'Archived: ' + new Date(data.deletedAt).toDateString()

  return (
    <Paper elevation={5} sx={{ cursor: 'pointer' }} onClick={() => onClick(data.id)}>
      <Card
        sx={{
          minWidth: 275,
          ...(isActive && {
            backgroundColor: theme.palette.secondary.light,
          }),
        }}
      >
        <Grid container direction={'row'} spacing={1} wrap={'nowrap'}>
          <Grid item xs={12}>
            <CardHeader
              style={{ overflowWrap: 'break-word' }}
              title={data.title}
              titleTypographyProps={{ variant: 'h6' }}
              subheader={data.employerName}
              sx={{
                display: 'flex',
                overflow: 'hidden',
                '& .MuiCardHeader-content': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                },
              }}
            />
          </Grid>
          <Grid item style={{ marginLeft: 'auto' }}>
            <Typography sx={{ fontSize: 12, padding: '5px' }} color='text.secondary' gutter='true'>
              {postedAt}
            </Typography>
            {data.deletedAt && (
              <Grid item style={{ marginLeft: 'auto' }}>
                <Alert icon={false} severity='info'>
                  {deletedAt}
                </Alert>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Divider sx={{ height: '1px' }} />
        <CardContent>
          <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid item xs={12} sm={12} md={6}>
              {location && <ListDataItem icon={<LocationOnIcon />} text={location} />}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {' '}
              <ListDataItem icon={<LocalHospitalIcon />} text={specialty} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  )
}
