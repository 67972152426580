import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  TextField,
  Typography,
  MenuItem,
  InputAdornment,
  IconButton,
  useTheme,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot'
import {
  setAppliedUserFilterId,
  clearAppliedUserFilterId,
  fetchUserFilters,
  fetchFilterTypes,
} from 'src/redux/filters'

export const SavedFiltersButton = ({ listType = 'job' }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { filterTypes = [], userFilters = [] } = useSelector(state => state.filters)
  const { userId } = useSelector(state => state.identity)

  const pageUserFilters =
    userFilters.length > 0 && filterTypes.length > 0
      ? userFilters.filter(
          item =>
            item.filterType.id ===
            filterTypes.find(filterType => filterType.name.toLowerCase() === listType.toLowerCase())
              .id,
        )
      : []

  React.useEffect(() => {
    dispatch(fetchFilterTypes())
    dispatch(fetchUserFilters(userId))
  }, [dispatch, userId])

  const appliedFilter =
    pageUserFilters.find(item => item.queryString === searchParams.toString()) || {}

  React.useEffect(() => {
    if (Object.keys(appliedFilter).length !== 0) {
      dispatch(setAppliedUserFilterId(appliedFilter.id))
    }
  }, [appliedFilter, dispatch])

  const handleChange = event => {
    setSearchParams(event.target.value.queryString)
    dispatch(setAppliedUserFilterId(event.target.value.id))
  }

  const handleClear = () => {
    setSearchParams({})
    dispatch(clearAppliedUserFilterId())
  }

  const isDisabled = pageUserFilters.length === 0

  return (
    <TextField
      onChange={handleChange}
      id='select-filters'
      select
      disabled={isDisabled}
      size='small'
      label={appliedFilter.name ? 'Selected Saved Filter' : 'Saved Filters'}
      fullWidth
      variant='outlined'
      placeholder='Saved Filters'
      value={appliedFilter.name ? appliedFilter : ''}
      InputLabelProps={{
        style: {
          color: isDisabled ? theme.palette.text.disabled : 'white',
          fontSize: '0.7rem',
        },
      }}
      sx={{
        color: isDisabled ? theme.palette.text.disabled : 'white',
        '& .MuiSvgIcon-root': { color: isDisabled ? theme.palette.text.disabled : '#FFF' },
        '& .MuiFilledInput-root': {
          backgroundColor: appliedFilter.name
            ? theme.palette.secondary.main
            : isDisabled
              ? theme.palette.action.disabledBackground
              : 'transparent',
          borderColor: isDisabled ? theme.palette.text.disabled : 'white',
          '&:hover': {
            backgroundColor: appliedFilter.name
              ? theme.palette.secondary.dark
              : isDisabled
                ? theme.palette.action.disabledBackground
                : 'transparent',
          },
        },
        '& .MuiOutlinedInput-root': {
          backgroundColor: appliedFilter.name
            ? theme.palette.secondary.main
            : isDisabled
              ? theme.palette.action.disabledBackground
              : 'transparent',
          borderColor: isDisabled ? theme.palette.text.disabled : 'white',
          '&:hover': {
            backgroundColor: appliedFilter.name
              ? theme.palette.secondary.dark
              : isDisabled
                ? theme.palette.action.disabledBackground
                : 'transparent',
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: isDisabled ? theme.palette.text.disabled : 'white',
        },
        '& .MuiInputBase-input': {
          color: isDisabled ? theme.palette.text.disabled : 'white',
          fontSize: '0.8rem', // Adjust font size for small screens
          textAlign: 'left', // Align text to the left
          paddingLeft: '10px', // Add padding to move text closer to the left
        },
        '& .MuiIconButton-root': {
          padding: '4px', // Reduce padding for small screens
        },
        '& .MuiInputAdornment-root': {
          display: 'flex',
          alignItems: 'center',
        },
      }}
      InputProps={{
        endAdornment: appliedFilter.name ? (
          <InputAdornment position='end'>
            <IconButton onClick={handleClear} size='small'>
              <ClearIcon
                fontSize='small'
                sx={{ color: isDisabled ? theme.palette.text.disabled : 'white' }}
              />
            </IconButton>
          </InputAdornment>
        ) : (
          <InputAdornment position='end'>
            <TurnedInNotIcon
              sx={{
                color: isDisabled ? theme.palette.text.disabled : 'white',
                fontSize: '1.2rem', // Adjust icon size for small screens
              }}
            />
          </InputAdornment>
        ),
      }}
      SelectProps={{
        IconComponent: () => null,
        renderValue: () => (
          <Typography
            sx={{
              color: isDisabled ? theme.palette.text.disabled : 'white',
              fontSize: '.8rem', // Adjusted font size for selected value
              textTransform: 'none',
              textAlign: 'left', // Align text to the left
              width: '100%',
              paddingLeft: '10px', // Add padding to move text closer to the left
            }}
          >
            {appliedFilter.name || 'Saved Filters'}
          </Typography>
        ),
      }}
    >
      {pageUserFilters.map((item, index) => (
        <MenuItem id={`saved-filter-${index}`} key={index} value={item}>
          <Typography sx={{ color: theme.palette.primary.dark, fontSize: '.8rem' }}>
            {item.name}
          </Typography>
        </MenuItem>
      ))}
    </TextField>
  )
}

export default SavedFiltersButton
