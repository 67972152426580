import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextField, Autocomplete, useTheme } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { getSpecialties } from 'data/layout/actions'

const SubspecialtyField = ({
  name,
  label,
  value = {},
  specialtyId,
  handleChange,
  disabled,
  ...props
}) => {
  const { subspecialties = [] } = useSelector(state => state.layout)
  let currentValue = {}
  React.useEffect(() => {
    getSpecialties(dispatch)
  }, [])

  React.useEffect(() => {
    handleChange({ target: { name: name, value: {} } })
  }, [specialtyId])

  React.useEffect(() => {
    setSubspecialties(
      subspecialties
        .filter(subspecialty => subspecialty.specialtyId === specialtyId)
        .map(s => ({
          id: s.id,
          name: s.name,
        })),
    )
  }, [specialtyId, subspecialties])

  const dispatch = useDispatch()
  const theme = useTheme()
  const [subspecialtyOptions, setSubspecialties] = React.useState([])
  currentValue = value !== null ? { id: value.id, name: value.name } : {}

  return (
    <Autocomplete
      disablePortal
      id={name}
      options={subspecialtyOptions}
      disabled={disabled}
      popupIcon={disabled ? null : <ArrowDropDownIcon />}
      getOptionLabel={option => option.name || ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={params => (
        <TextField
          {...params}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.primary.dark,
            },
            '& fieldset': disabled && { border: 'none' },
          }}
          label={label}
        />
      )}
      value={currentValue}
      onChange={(event, newValue) => handleChange({ target: { name: name, value: newValue } })}
      {...props}
    />
  )
}

export default SubspecialtyField
