import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withStyles from '@mui/styles/withStyles'
import useComponentSize from '@rehooks/component-size'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import { resizeViewPort } from 'data/layout/actions'
import { mapUrlForAnalytics, mapEmailEvent } from './helpers'

const staticProps = {
  flexGrow: 1,
  zIndex: 1,
  overflow: 'hidden',
  position: 'fixed',
  display: 'static',
  width: '100%',
  height: '100%',
}

const decorate = withStyles(({ layout }) => ({
  root: {},
  dimensions: {
    overflow: 'hidden',
    position: 'fixed',
    width: 'calc(100vw)',
    height: 'calc(100vh)',
  },
  backdrop: {
    ...staticProps,
    background: layout.backdropColor,
  },
  viewport: props => ({
    ...staticProps,
    height: props.height,
    width: props.width,
    background: layout.backgroundColor,
  }),
}))

const ViewPort = ({ resizeViewPort, classes, children }) => {
  let ref = React.useRef(null)
  const { employerId, email } = useSelector(state => state.identity)
  let size = useComponentSize(ref)
  const dispatch = useDispatch()
  const route = window.location.pathname
  const searchParams = new URLSearchParams(window.location.search)

  React.useEffect(() => {
    const urlData = mapUrlForAnalytics(route)
    const event = {
      eventName: urlData.url,
      eventType: ProductAnalyticsEventTypes.PAGE_VIEW,
      eventData: { ...urlData.properties },
    }

    const emailEvent = mapEmailEvent({ searchParams, email })
    if (email) {
      event.userId = email
    }
    if (employerId) {
      event.employerId = employerId
    }

    if (emailEvent) {
      dispatch(fetchTrackEvent(emailEvent))
    }
    dispatch(fetchTrackEvent(event))
  }, [route, email, employerId, dispatch])

  React.useEffect(() => {
    resizeViewPort && resizeViewPort({ ...size })
  }, [resizeViewPort, size.height, size.width])

  return (
    <>
      <div ref={ref} className={classes.dimensions} />
      <div id='backdrop' className={classes.backdrop}>
        <div id='viewport' className={classes.viewport}>
          {children}
        </div>
      </div>
    </>
  )
}

ViewPort.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  resizeViewPort: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    height: state.layout.height,
    width: state.layout.width,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resizeViewPort: payload => dispatch(resizeViewPort(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(decorate(ViewPort))
