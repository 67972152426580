import * as React from 'react'
import { Box, Stepper, Step, StepLabel } from '@mui/material'

const OnboardingStepperMobile = ({ activeStep, setActiveStep, steps }) => {
  const handleNavigate = index => {
    setActiveStep(index)
  }

  return (
    <Box sx={{ width: '100%', padding: '20px 0px' }}>
      <Stepper activeStep={activeStep} orientation='horizontal' alternativeLabel>
        {steps.map((step, index) => (
          <Step key={step.label} onClick={() => handleNavigate(index)} sx={{ cursor: 'pointer' }}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

export default OnboardingStepperMobile
