import React from 'react'
import { Button, Card, CardActions, CardHeader, Grid, Paper } from '@mui/material'
import { useHandleChangeRoute } from 'data/hooks'
const SignUpCard = () => {
  const handleChangeRoute = useHandleChangeRoute()
  const handleClick = () => {
    handleChangeRoute('/register/physicians')()
  }
  return (
    <Paper elevation={5} sx={{ width: '100%' }}>
      <Card
        sx={{
          minWidth: 275,
          padding: '20px',
        }}
      >
        <Grid container direction={'row'} spacing={1} wrap='nowrap' justifyContent='center'>
          <CardHeader
            style={{ overflowWrap: 'break-word', textAlign: 'center' }}
            title='🩺 ☕ MyStethi '
            subheader='Free, no spam, just up-to-date job listings'
          />
        </Grid>
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button variant='contained' size='large' sx={{ width: '20%' }} onClick={handleClick}>
            Sign Up
          </Button>
        </CardActions>
      </Card>
    </Paper>
  )
}

export default SignUpCard
