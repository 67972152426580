import React from 'react'
import { sanitize } from 'dompurify'
import { Grid, Typography, Divider } from '@mui/material'
import ListDataItem from 'components/Listing/ListDataItem'
import { getPhysicianListItems, getSpecialty } from './helpers'
import PhysicianCard from './PhysicianCard'
import { formatName } from 'common/helpers'

const PhysicianContent = ({ item = {}, signedIn }) => {
  const fields = getPhysicianListItems(item) || []
  const name = formatName(item.user.firstName) + ' ' + formatName(item.user.lastName)
  const subspecialty =
    item.subspecialties && item.subspecialties.length > 0 ? item.subspecialties[0].name : null

  const specialty = getSpecialty(item.specialties[0].name, subspecialty)
  const displayableFields = fields.filter(field => field.show)
  return (
    <div className='joyride_candidate_content'>
      <Grid container sx={{ padding: '20px 20px', height: 'auto' }}>
        <Grid container spacing={1} alignItems='center' direction='row' item xs={12}>
          <Grid container item xs={9}>
            <Typography variant='h4'>
              <b>{name}</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ margin: '10px 0px' }}>
          <Divider sx={{ height: '1px', marginBottom: '20px' }} />
        </Grid>
        <Grid container item xs={12}>
          <PhysicianCard
            physician={item.user}
            specialty={specialty}
            physicianResume={item.resume}
            signedIn={signedIn}
          />
        </Grid>
        <Grid container item xs={12} direction='row'>
          <Grid
            container
            item
            xs={12}
            rowSpacing={1}
            sx={{ marginBottom: '10px', marginTop: '10px' }}
          >
            {displayableFields.map((field, index) => (
              <Grid container item sm={12} md={6} key={index}>
                {field.show && (
                  <ListDataItem icon={field.icon} boolValue={field.value} text={field.text} />
                )}
              </Grid>
            ))}
          </Grid>
          {item.about && (
            <Grid item xs={12} justifyContent='flex-start' sx={{ marginTop: '10px' }}>
              <Divider sx={{ height: '1px', margin: '20px 0px' }} />
              <Typography variant='h4'>About {item.firstName}</Typography>
              <Typography
                variant='body1'
                dangerouslySetInnerHTML={{ __html: sanitize(item.about) }}
              ></Typography>
            </Grid>
          )}
          {item.aboutJob && (
            <Grid item xs={12} justifyContent='flex-start' sx={{ marginTop: '10px' }}>
              <Divider sx={{ height: '1px', margin: '20px 0px' }} />
              <Typography variant='h4'>About {item.firstName}'s Ideal Job</Typography>
              <Typography variant='body1'>{item.aboutJob}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default PhysicianContent
