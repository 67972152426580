import React from 'react'
import {
  Grid,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  Link,
  FormControl,
  InputLabel,
} from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'
import { sortAscendingKey } from 'data/helpers'
import { useStyles } from 'common/styles'
import { useFormControls } from './formActions'
import { useGetSpecialties } from 'data/layout/actions'
import { States } from 'data/constants'
import { useHandleChangeRoute } from 'data/hooks'
import CircularProgress from '@mui/material/CircularProgress'

const RegistrationForm = () => {
  const classes = useStyles()
  const { loading, userType, email } = useSelector(state => state.register)
  const { handleInputValue, errors, formIsValid, handleFormSubmit, values, setsSpecialtyId } =
    useFormControls()
  const getSpecialties = useGetSpecialties(1)

  React.useEffect(() => {
    getSpecialties()
  }, [getSpecialties])

  const specialties = (useSelector(state => state.layout.specialties, shallowEqual) || []).sort(
    sortAscendingKey('name'),
  )
  let defaultSpecialtyId = ''

  if (userType === 'app') {
    const specialty = specialties.find(s => s.name === 'Advanced Practice Provider (NP/PA)') || {}
    defaultSpecialtyId = specialty.id || ''
    setsSpecialtyId(defaultSpecialtyId.toString())
  } else if (userType === 'crna') {
    const specialty = specialties.find(s => s.name === 'CRNA (Nurse Anesthetist)') || {}
    defaultSpecialtyId = specialty.id || ''
    setsSpecialtyId(defaultSpecialtyId.toString())
  }

  const subspecialties = (
    useSelector(state => state.layout.subspecialties, shallowEqual) || []
  ).sort(sortAscendingKey('name'))

  const referralSources = useSelector(state => state.layout.referralSources, shallowEqual) || []

  // Find and remove the 'Other' referral source
  const otherReferralIndex = referralSources.findIndex(r => r.name === 'Other')
  let otherReferral = { id: '', name: 'Other' }
  if (otherReferralIndex !== -1) {
    otherReferral = referralSources.splice(otherReferralIndex, 1)[0]
  }

  // Add 'Other' referral source to the end of the list
  referralSources.push(otherReferral)

  const filterSubspecialties = values.specialtyId
    ? subspecialties.filter(s => s.specialtyId === Number(values.specialtyId))
    : []
  const handleChangeRoute = useHandleChangeRoute()
  return (
    <Grid container direction='column'>
      <Grid
        container
        alignItems='center'
        direction='row'
        style={{ paddingTop: '30px', paddingLeft: '50px' }}
      >
        <Grid item style={{ marginLeft: '20px' }}>
          <Typography variant='h4'>Welcome To MyStethi</Typography>
          <Typography variant='caption' style={{ fontStyle: 'normal' }}>
            Already have an account?{' '}
            <Link
              onClick={handleChangeRoute('/login')}
              style={{
                cursor: 'pointer',
                fontStyle: 'italic',
                textDecoration: 'underline',
              }}
              underline='hover'
            >
              Login
            </Link>
          </Typography>
        </Grid>
      </Grid>

      <Grid item className={classes.registrationFieldsContainer}>
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                key='first'
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name='firstName'
                label='First Name'
                multiline={false}
                rows={1}
                required={true}
                variant='outlined'
                autoComplete='none'
                style={{ width: '100%' }}
                {...(errors['firstName'] && {
                  error: true,
                  helperText: errors['firstName'],
                })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                key='last'
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name='lastName'
                label='Last Name'
                required={true}
                multiline={false}
                rows={1}
                autoComplete='none'
                variant='outlined'
                style={{ width: '100%' }}
                {...(errors['lastName'] && {
                  error: true,
                  helperText: errors['lastName'],
                })}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                key='email'
                onBlur={handleInputValue}
                onChange={handleInputValue}
                defaultValue={email}
                name='email'
                label='Email'
                required={true}
                multiline={false}
                rows={1}
                style={{ width: '100%' }}
                autoComplete='none'
                variant='outlined'
                {...(errors['email'] && {
                  error: true,
                  helperText: errors['email'],
                })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                key='city'
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name='city'
                label='City'
                multiline={false}
                rows={1}
                variant='outlined'
                autoComplete='none'
                style={{ width: '100%' }}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant='outlined' style={{ width: '100%' }}>
                <InputLabel id='state-label'>State *</InputLabel>
                <Select
                  labelId='state-label'
                  name='state'
                  id='state'
                  label='State'
                  required={true}
                  onChange={handleInputValue}
                  variant='outlined'
                >
                  <MenuItem value='' disabled>
                    State
                  </MenuItem>
                  {Object.keys(States).map(s => (
                    <MenuItem key={s} value={s}>
                      {States[s]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant='outlined' style={{ width: '100%' }}>
                <InputLabel id='specialty-label' required>
                  Specialty
                </InputLabel>
                <Select
                  labelId='specialty-label'
                  name='specialtyId'
                  id='specialtyId'
                  label='Specialty'
                  required={true}
                  onChange={handleInputValue}
                  variant='outlined'
                  //  defaultValue={defaultSpecialtyId}
                  value={values.specialtyId ? values.specialtyId : defaultSpecialtyId}
                >
                  <MenuItem value='' disabled>
                    Specialty
                  </MenuItem>
                  {specialties.map(s => (
                    <MenuItem key={s.id} value={s.id.toString()}>
                      {s.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors['specialtyId'] && (
                <FormHelperText style={{ color: 'red' }}>{errors['specialtyId']}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant='outlined' style={{ width: '100%' }}>
                <InputLabel id='sub-specialty-label'>Sub-Specialty</InputLabel>
                <Select
                  labelId='sub-specialty-label'
                  name='subspecialtyId'
                  id='subspecialtyId'
                  variant='outlined'
                  value={values.subspecialtyId ? values.subspecialtyId : ''}
                  onChange={handleInputValue}
                  label='Subspecialty'
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {filterSubspecialties.map(s => (
                    <MenuItem key={s.id} value={s.id.toString()}>
                      {s.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                key='password'
                onBlur={handleInputValue}
                onChange={handleInputValue}
                type='password'
                name='password'
                label='Password'
                variant='outlined'
                required={true}
                multiline={false}
                style={{ width: '100%' }}
                rows={1}
                autoComplete='none'
                {...(errors['password'] && {
                  error: true,
                  helperText: errors['password'],
                })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                key='passwordConfirm'
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name='confirmPassword'
                type='password'
                variant='outlined'
                label='Confirm Password'
                required={true}
                multiline={false}
                style={{ width: '100%' }}
                rows={1}
                autoComplete='none'
                {...(errors['confirmPassword'] && {
                  error: true,
                  helperText: errors['confirmPassword'],
                })}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl variant='outlined' style={{ width: '100%' }}>
                <InputLabel id='referral-source' required>
                  How did you hear about us?
                </InputLabel>
                <Select
                  labelId='referral-source-label'
                  name='referralSourceId'
                  id='referralSourceId'
                  label='Referral Source'
                  required={true}
                  displayEmpty
                  onChange={handleInputValue}
                  variant='outlined'
                  value={values.referralSourceId ? values.referralSourceId : ''}
                >
                  <MenuItem value='' disabled>
                    How did you hear about us?
                  </MenuItem>
                  {referralSources.map(s => (
                    <MenuItem key={s.id} value={s.id.toString()}>
                      {s.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                key='referralSourceOther'
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name='referralSourceOther'
                placeholder='Interesting! Tell us more'
                multiline={false}
                rows={1}
                style={
                  values.referralSourceId === otherReferral.id.toString()
                    ? { width: '100%', fontSize: '.8rem' }
                    : { display: 'none' }
                }
                autoComplete='none'
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} className={classes.registrationBtnContainer}>
              <Typography variant='caption' style={{ alignSelf: 'center', fontStyle: 'normal' }}>
                By clicking "Create Account" you are creating a "MyStethi" account and are agreeing
                to our{' '}
                <Link
                  href='/tac'
                  underline='always'
                  color='#1B1B1B'
                  target='_blank'
                  and
                  rel='noopener noreferrer'
                >
                  Terms of use
                </Link>{' '}
                and{' '}
                <Link
                  href='/privacy'
                  underline='always'
                  target='_blank'
                  and
                  rel='noopener noreferrer'
                  color='#1B1B1B'
                >
                  Privacy Policy
                </Link>
              </Typography>
              <Grid container justifyContent='center'>
                <Button
                  type='submit'
                  disabled={!formIsValid() || loading}
                  autoFocus
                  variant='contained'
                  style={{ marginTop: '20px' }}
                >
                  Create Account
                </Button>
                {loading && <CircularProgress className={classes.registrationLoader} />}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <div className={classes.mobileSubmitButtonHeight}></div>
    </Grid>
  )
}

export default RegistrationForm
