import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, useTheme } from '@mui/material'
import { SavedFiltersButton } from './SavedFiltersButton'
import { AlertsButton } from './AlertsButton'
import { useSearchParams } from 'react-router-dom'
import { fetchUserFilterAlerts } from '../../redux/filters'

export const FilterHeader = ({ listType }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const isViewingAlert = Boolean(searchParams.get('userFilterAlertId'))
  const { userFilterAlerts = [] } = useSelector(state => state.filters)
  const { userId } = useSelector(state => state.identity)

  React.useEffect(() => {
    dispatch(fetchUserFilterAlerts(userId))
  }, [dispatch, userId])

  const userFilterAlertTypeName = listType === 'job' ? 'job' : 'candidate'
  const availableAlerts =
    userFilterAlerts.filter(
      alert =>
        alert.filterType.toLowerCase() === userFilterAlertTypeName && Number(alert.recordCount) > 0,
    ) || []

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      spacing={0}
      sx={{ maxWidth: '70em', margin: '0px 18px 0px 18px' }}
    >
      <Grid item xs={4}>
        <Typography variant='h6' color='white'>
          Filters
        </Typography>
      </Grid>
      <Grid container item xs={8} justifyContent='flex-end' alignItems='center' spacing={1}>
        {isViewingAlert && availableAlerts.length > 0 && (
          <>
            <Grid item xs={12} sm={4}>
              <Typography variant='body2' color={theme.palette.warning.main}>
                You are currently viewing the results of an alert
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <AlertsButton availableAlerts={availableAlerts} fullWidth />
            </Grid>
          </>
        )}
        <Grid item xs={6} sm={4}>
          <div className='joyride_saved_filters'>
            <SavedFiltersButton listType={listType} fullWidth />
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FilterHeader
