import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Grid, Divider, useTheme, Button, Collapse } from '@mui/material'
import {
  generateFilters,
  updateSelectedFilters,
  getSelectedFiltersFromQueryString,
} from './helpers'
import { filterDefinitions, UserType } from './filterDefinitions'
import { useIdentity } from 'pages/Identity/Login'
import Loader from 'components/Loader'
import { useStyles } from './styles'
import { ClearFiltersButton } from './ClearFiltersButton'
import { SaveFiltersButton } from './SaveFiltersButton'
import { FilterHeader } from './FilterHeader'
import MoreFiltersButton from './MoreFiltersButton'
import { MobileFilterDropdown } from './FilterDropdown/MobileFilterDropdown'
import { DesktopFilterDropdown } from './FilterDropdown/DesktopFilterDropdown'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

export const Filters = ({ filterType, filterValues, listType, loading, applyFilters }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const styles = useStyles({ mobile })
  const { appliedUserFilterId } = useSelector(state => state.filters)
  let ref = useRef(null)
  const { employerId, isEmployerAdmin } = useIdentity()
  const userType = employerId || isEmployerAdmin ? UserType.EMPLOYER : UserType.CANDIDATE
  const [searchParams, setSearchParams] = useSearchParams()
  const [areFiltersOpen, setAreFiltersOpen] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({})
  const [showAllFilters, setShowAllFilters] = useState(false)
  const filters = generateFilters(filterType, userType, filterValues)

  useEffect(() => {
    setSelectedFilters(getSelectedFiltersFromQueryString(searchParams, filterValues))
  }, [filterValues, searchParams])

  useEffect(() => {
    applyFilters({ searchParams })
  }, [selectedFilters])

  const handleFilterChange = selectedFilter => {
    const { filterDefinition, selectedValues } = selectedFilter
    if (selectedValues.length > 0) {
      if (filterDefinition.isYesNo) {
        searchParams.set(filterDefinition.queryStringKey, selectedValues[0].value === '1')
      } else {
        searchParams.set(
          filterDefinition.queryStringKey,
          selectedValues.map(x => x.value).join('|'),
        )
      }
    } else {
      searchParams.delete(filterDefinition.queryStringKey)
    }

    setSearchParams(searchParams)
    if (!selectedValues || selectedValues.length === 0) {
      setSelectedFilters({})
    } else {
      setSelectedFilters(updateSelectedFilters(selectedFilters, selectedFilter))
    }
  }

  const handleClearAll = () => {
    setSelectedFilters({})
    setSearchParams({})
  }

  const toggleFilters = () => {
    setAreFiltersOpen(!areFiltersOpen)
    setShowAllFilters(!areFiltersOpen)
  }

  const toggleShowAllFilters = () => {
    setShowAllFilters(!showAllFilters)
  }

  const subspecialtyFilterDefinition = filterDefinitions.find(
    filter => filter.name === 'subspecialty',
  )
  const specialtyFilterDefinition = filterDefinitions.find(filter => filter.name === 'specialty')

  const renderFilters = filtersToRender => {
    return filtersToRender.map((filter, index) => {
      const selectedFilter = selectedFilters[filter.filterDefinition.name]
      let matchingSubspecialties = []
      const subspecialtyFilterValues = filterValues[subspecialtyFilterDefinition.valuesResponseKey]
      if (
        filter.filterDefinition.name === subspecialtyFilterDefinition.name &&
        subspecialtyFilterValues
      ) {
        const selectedSpecialtyFilter = selectedFilters[specialtyFilterDefinition.name] || {}
        const selectedSpecialtyIds = (selectedSpecialtyFilter.selectedValues || []).map(
          x => x.value,
        )
        if (selectedSpecialtyIds.length > 0) {
          matchingSubspecialties = subspecialtyFilterValues.filter(x =>
            selectedSpecialtyIds.includes(x.specialtyId),
          )
        }
      }
      const FilterComponent = mobile ? MobileFilterDropdown : DesktopFilterDropdown
      return (
        <Grid
          item
          key={`${filter.filterDefinition.name}-${index}`}
          xs={12}
          sm={12}
          md={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FilterComponent
            filterDefinition={filter.filterDefinition}
            options={
              filter.filterDefinition.name === subspecialtyFilterDefinition.name
                ? matchingSubspecialties
                : filter.filterValues
            }
            checked={selectedFilter ? selectedFilter.selectedValues : null}
            onChange={handleFilterChange}
            styles={styles}
            mobile={mobile}
            filtersOpen={areFiltersOpen}
          />
        </Grid>
      )
    })
  }

  const selectedFiltersCount = Object.keys(selectedFilters).reduce((count, key) => {
    return (
      count + (selectedFilters[key].selectedValues ? selectedFilters[key].selectedValues.length : 0)
    )
  }, 0)

  return (
    <div className='joyride_filters'>
      <Grid container ref={ref} justifyContent='center' sx={{ backgroundColor: '#0E7C7B' }}>
        {loading ? (
          <Loader />
        ) : (
          <Grid
            container
            className={`filterWrapper ${styles.filterWrapper}`}
            sx={{ maxWidth: '70em' }}
          >
            <Grid container item direction='row' justifyContent='space-between' alignItems='center'>
              <FilterHeader listType={listType} />
              <Divider color='white' />
            </Grid>
            <Grid item container direction='row' alignItems='center' spacing={0}>
              {mobile && (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={toggleFilters}
                  endIcon={areFiltersOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  sx={{
                    margin: '16px 0',
                    width: '100%',
                    backgroundColor: theme.palette.primary.dark,
                  }}
                >
                  Filters {selectedFiltersCount > 0 ? `(${selectedFiltersCount})` : ''}
                </Button>
              )}
              <Collapse in={mobile && areFiltersOpen} sx={{ width: '100%' }}>
                <Grid item container direction='row' alignItems='center' spacing={0}>
                  {renderFilters(filters)}
                </Grid>
              </Collapse>
              {!mobile && (
                <>
                  <Grid item container direction='row' alignItems='center' spacing={0}>
                    {renderFilters(showAllFilters ? filters : filters.slice(0, 6))}
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent='flex-end'
                    alignItems='center'
                    xs={12}
                    spacing={1}
                    sx={{ marginTop: '16px' }}
                  >
                    <Grid item>
                      <ClearFiltersButton handleClearAll={handleClearAll} />
                    </Grid>
                    <Grid item>
                      <div className='joyride_save_filters'>
                        <SaveFiltersButton
                          listType={listType}
                          selectedFilters={selectedFilters}
                          disabled={
                            Object.keys(selectedFilters).length === 0 ||
                            appliedUserFilterId !== null
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item>
                      <MoreFiltersButton
                        areFiltersOpen={showAllFilters}
                        toggleFilters={toggleShowAllFilters}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {mobile && (
                <Grid
                  item
                  container
                  justifyContent='center'
                  alignItems='center'
                  xs={12}
                  spacing={1}
                  sx={{ marginTop: '16px' }}
                >
                  <Grid item>
                    <ClearFiltersButton handleClearAll={handleClearAll} />
                  </Grid>
                  <Grid item>
                    <div className='joyride_save_filters'>
                      <SaveFiltersButton
                        listType={listType}
                        selectedFilters={selectedFilters}
                        disabled={
                          Object.keys(selectedFilters).length === 0 || appliedUserFilterId !== null
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
