import axios from 'axios'
import { cleanJobData } from 'src/data/job/hooks'
import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'

export const JOB_CSV_LOADING = 'csv/JOB_CSV_LOADING'
export const JOB_CSV_SUCCESS = 'csv/JOB_CSV_SUCCESS'
export const JOB_CSV_FAILURE = 'csv/JOB_CSV_FAILURE'

export const jobCsvLoading = () => ({ type: JOB_CSV_LOADING })
export const jobCsvSuccess = () => ({ type: JOB_CSV_SUCCESS })
export const jobCsvFailure = () => ({ type: JOB_CSV_FAILURE })

const cleanJobsData = jobs => {
  return jobs.map(job => {
    return cleanJobData(job)
  })
}
export const fetchPostJobCSV = data => {
  return async dispatch => {
    dispatch(jobCsvLoading())
    try {
      await axios({
        url: '/api/jobs/batch',
        method: 'POST',
        data: { jobs: cleanJobsData(data.jobs) },
      })
      dispatch(showSnackbar('Successfully Uploaded.', snackbarTypes.SUCCESS))
      dispatch(jobCsvSuccess())
    } catch (error) {
      dispatch(showSnackbar('Upload was not successful.', snackbarTypes.ERROR))
      dispatch(jobCsvFailure(error.response))
    }
  }
}

const initialState = {
  loading: false,
}

export const csvReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOB_CSV_LOADING:
      return {
        ...state,
        loading: true,
      }
    case JOB_CSV_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case JOB_CSV_FAILURE:
      return {
        ...initialState,
      }
    default:
      return {
        ...state,
      }
  }
}
