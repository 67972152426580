import React from 'react'
import { useDispatch } from 'react-redux'
import { Grid, IconButton, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { fetchUpdateCandidate } from 'src/redux/candidate'
import { hostAddress } from 'data/helpers'
import ResumeUpload from 'components/UserFields/ResumeUpload'

const Resume = ({ user, values, loading }) => {
  const dispatch = useDispatch()
  const buttonText = values.resume ? 'Update Resume' : 'Upload Resume'

  const { key, fileName } = values.resume || {}

  const handleResumeClick = () => {
    fetch(hostAddress(`/api/storage/resume?key=${key}&fileName=${fileName}`), {
      method: 'GET',
      headers: new Headers({ 'content-type': 'application/json' }),
    }).then(response => response.blob().then(blob => window.open(URL.createObjectURL(blob))))
  }

  const handleRemoveResume = () => {
    dispatch(
      fetchUpdateCandidate({
        id: user.id,
        removeResume: true,
      }),
    )
  }

  const handleResumeSave = event => {
    const uploadFiles = event.target.files
    if (uploadFiles) {
      const arrFiles = Array.from(uploadFiles)
      const file = arrFiles.length > 0 ? arrFiles[0] : undefined
      dispatch(
        fetchUpdateCandidate(
          {
            id: user.id,
            resumeFile: file,
          },
          'Uploaded Resume',
          null,
        ),
      )
    }
  }

  return (
    <Box>
      {values.resume && (
        <Grid container direction='column'>
          <Grid
            container
            direction='row'
            alignItems='center'
            spacing={2}
            sx={{ padding: '0 0 0 20' }}
          >
            <a
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => handleResumeClick()}
            >
              {values.resume.fileName}
            </a>
            <IconButton onClick={handleRemoveResume}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <ResumeUpload
        value={values.resume}
        handleChange={handleResumeSave}
        label={buttonText}
        name='resume'
        loading={loading}
      />
    </Box>
  )
}

export default Resume
