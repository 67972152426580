import * as React from 'react'
import { Grid, Box, Stepper, Step, StepLabel, StepContent, Button, Typography } from '@mui/material'

const OnboardingStepper = ({ activeStep, setActiveStep, steps }) => {
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const numberOfSteps = steps.length - 1
  return (
    <Grid
      container
      sx={{ maxWidth: 400, padding: '50px' }}
      justifyContent='flex-start'
      alignItems='center'
      spacing={4}
    >
      <Stepper activeStep={activeStep} orientation='vertical'>
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === numberOfSteps ? (
                  <Typography variant='caption'>Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Box sx={{ mb: 2 }}>
                <div>
                  {index !== numberOfSteps && (
                    <Button onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                      Skip
                    </Button>
                  )}
                  {index !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      Back
                    </Button>
                  )}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Grid>
  )
}

export default OnboardingStepper
