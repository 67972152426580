import * as React from 'react'
import Text from 'components/DialogField/Text'
import Autocomplete from '@mui/material/Autocomplete'
import { useDialogState } from 'data/dialog/actions'

export default function AutoComplete({
  items: options,
  value,
  onChange = undefined,
  defaultValue,
  freeSolo = false,
  // eslint-disable-next-line
  getFieldState = undefined,
  getOptionLabel = option => (option ? option.label : '') || '',
  disableClearable = undefined,
  dialogId,
  dialogVar,
  hidden,
  ...other
}) {
  const [, setState] = useDialogState(dialogId)
  const handleChange = React.useCallback(
    (e, option) => {
      onChange && onChange({ target: { value: option ? option.value : option } }) // Inject value to mimic behavior of other input on change events

      if (dialogId) {
        const values = {}
        values[dialogVar] = option.label
        setState(prev => ({ ...prev, ...values }))
      }
    },
    [onChange],
  )
  const val = value
    ? options.find(
        option =>
          option.value === value ||
          option.value === `${value}` ||
          option.label === value ||
          option.value === value.toString(),
      )
    : null

  if (hidden) {
    return <></>
  } else {
    return (
      <Autocomplete
        freeSolo={freeSolo}
        onChange={handleChange}
        value={val}
        defaultValue={defaultValue}
        options={options}
        getOptionLabel={getOptionLabel}
        disableClearable={disableClearable}
        renderInput={params => (
          <Text
            {...params}
            variant='outlined'
            autoComplete='off'
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
            {...other}
          />
        )}
      />
    )
  }
}
