import React from 'react'
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material'
import GenericTextField from 'components/UserFields/GenericTextField'
import GenericDatePicker from 'components/UserFields/GenericDatePicker'

const Residency = ({ handleChange, values }) => {
  const theme = useTheme()
  const below_lg = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <Typography variant='h6'>Residency Information</Typography>
      <Grid
        container
        direction='column'
        spacing={3}
        sx={[{ padding: '20px 0px' }, below_lg && { marginBottom: '100px' }]}
      >
        <Grid item xs={12}>
          <GenericTextField
            name='residency'
            label='Residency'
            value={values.residency}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <GenericDatePicker
            name='residencyEnd'
            label='Residency End Date'
            value={values.residencyEnd}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Residency
