import React from 'react'
import {
  Select,
  MenuItem,
  Radio,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Box,
  Chip,
  Typography,
  useTheme,
  IconButton,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { onFilterDropDownChange } from './helpers'

export const DesktopFilterDropdown = ({ filterDefinition, options, onChange, checked, styles }) => {
  checked = checked || []
  let disabledProps = {}
  if (options.length === 0) {
    disabledProps = {
      disabled: true,
    }
  }
  const clearFilter = () => {
    onChange({
      filterDefinition,
      selectedValues: [],
    })
  }
  const theme = useTheme()
  const isMultiple = filterDefinition.isMultiple

  const handleDelete = valueToRemove => event => {
    event.stopPropagation() // Ensure the event doesn't bubble up and open the dropdown
    const newChecked = checked.filter(item => item.name !== valueToRemove)
    onChange({
      filterDefinition,
      selectedValues: newChecked,
    })
  }

  const selectedValues = checked.map(item => item.name)
  const selectValue = isMultiple ? selectedValues : selectedValues[0] || ''

  return (
    <FormControl sx={{ m: 1, width: 150 }} size='small' {...disabledProps}>
      <InputLabel
        sx={{
          fontSize: '.7rem',
          color: theme.palette.primary.dark,
          '&.MuiInputLabel-shrink': {
            color: 'white',
          },
        }}
        id={`label=${filterDefinition.name}`}
      >
        {filterDefinition.displayName}
      </InputLabel>
      <Select
        labelId={`label-${filterDefinition.name}`}
        id={filterDefinition.name}
        value={selectValue}
        multiple={isMultiple}
        sx={{
          '& .MuiSelect-iconOutlined': { display: checked.length > 0 && !isMultiple ? 'none' : '' },
        }}
        endAdornment={
          <IconButton
            sx={{ display: checked.length > 0 && !isMultiple ? '' : 'none' }}
            onClick={clearFilter}
            size='small'
          >
            <ClearIcon fontSize='8px' />
          </IconButton>
        }
        onChange={event => onFilterDropDownChange(event, options, filterDefinition, onChange)}
        displayEmpty
        renderValue={selected =>
          isMultiple ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.2 }}>
              {selected.map(value => (
                <Chip
                  sx={{
                    height: '24px',
                    '& .MuiChip-label': {
                      fontSize: '.8rem',
                    },
                  }}
                  key={value}
                  label={value}
                  onDelete={handleDelete(value)}
                  deleteIcon={<ClearIcon sx={{ fontSize: '.8rem' }} />}
                  onMouseDown={e => e.stopPropagation()} // Prevent the dropdown from opening on delete icon click
                />
              ))}
            </Box>
          ) : (
            <Typography sx={{ color: theme.palette.primary.dark, fontSize: '.8rem' }}>
              {selected}
            </Typography>
          )
        }
      >
        {options.map(({ name, value }) => (
          <MenuItem
            classes={{
              root: styles.menuItem,
            }}
            name={name}
            key={`${value}-${name}`}
            value={name}
            id={value}
          >
            {!isMultiple ? (
              <Radio checked={checked.some(item => item.name === name)} />
            ) : (
              <Checkbox checked={checked.some(item => item.name === name)} />
            )}
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
