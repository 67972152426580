import * as React from 'react'
import { snackbarTypes, UPDATE_CANDIDATE } from 'data/constants'
import { fetchDispatch } from 'data/helpers'
import { useSelector, useDispatch } from 'react-redux'
import { useDispatchPayload } from 'data/hooks'
import { showSnackbar } from 'data/snackbar/actions'

export const GET_CANDIDATE_LOADING = 'GET_CANDIDATE_LOADING'

export const GET_CANDIDATE_SUCCESS = 'GET_CANDIDATE_SUCCESS'

export const GET_CANDIDATE_ERROR = 'GET_CANDIDATE_ERROR'

export function getCandidateLoading() {
  return {
    type: GET_CANDIDATE_LOADING,
  }
}

export function getCandidateSuccess(payload) {
  return {
    type: GET_CANDIDATE_SUCCESS,
    payload,
  }
}

export function getCandidateError() {
  return {
    type: GET_CANDIDATE_ERROR,
  }
}

export function useCandidate(formatter = undefined) {
  const dispatch = useDispatch()
  const dispatchPayload = useDispatchPayload()
  const candidate = useSelector(s => (formatter ? formatter(s.candidate) : s.candidate))
  const candidateId = useSelector(s => s.identity.candidateId)

  // Updates the redux store with new candidate information
  const onResponse = React.useCallback(
    ({ candidate }) => dispatchPayload(UPDATE_CANDIDATE, { ...candidate, loading: false }),
    [dispatchPayload],
  )

  // Gets the candidate information from the server and updates the redux store with the results.  If no id is specified, use the current candidate id
  const getCandidate = React.useCallback(
    (id = candidateId) => {
      dispatchPayload(UPDATE_CANDIDATE, prev => ({ ...prev, loading: true }))
      fetchDispatch({ path: `/api/candidates/${id}`, onResponse: onResponse, method: "GET" })
    },
    [onResponse, candidateId],
  )

  // Updates the database with new candidate information, closes dialog state or handles errors, and updates the redux store with the response
  const setCandidate = React.useCallback(
    (values, setState) => {
      dispatchPayload(UPDATE_CANDIDATE, prev => ({ ...prev, loading: true }))
      fetchDispatch({
        path: `/api/candidates/${values.id}`,
        values,
        setState,
        onResponse: json => {
          showSnackbar(dispatch, 'Successfully updated your profile.', snackbarTypes.SUCCESS)
          onResponse(json) // Call internal onResponse to update the redux store
        },
        onError: () => {
          showSnackbar(
            dispatch,
            'An error occurred when updating your profile.',
            snackbarTypes.ERROR,
          )
        },
        formData: true,
        method: "PUT",
      })
    },
    [onResponse],
  )

  return [candidate, getCandidate, setCandidate]
}

export function useGetCandidates() {
  const [{ candidates, fetchingCandidates }, setState] = React.useState({
    candidates: [],
    fetchingCandidates: false,
  })

  // Update local state with candidates and request status
  const onResponse = React.useCallback(
    ({ candidateListing }) =>
      setState(prev => ({ ...prev, candidates: candidateListing, fetchingCandidates: false })),
    [setState],
  )

  const onError = () => {
    setState(prev => ({ ...prev, fetchingCandidates: false }))
  }

  // Gets list of candidates
  const getCandidates = React.useCallback(() => {
    setState(prev => ({ ...prev, fetchingCandidates: true }))
    fetchDispatch({
      path: '/api/candidates/list',
      onResponse,
      onError,
      setState,
      responseOnError: false,
    })
  }, [setState, onResponse])

  return [candidates, getCandidates, fetchingCandidates]
}

export function getCandidate(id) {
  return async dispatch => {
    dispatch(getCandidateLoading())
    const response = await fetchDispatch({ path: `/api/candidates/${id}`, method: "GET" })
    if (response.candidate) {
      return dispatch(getCandidateSuccess(response.candidate))
    }

    showSnackbar(
      dispatch,
      'An error occurred attempting to get candidate information.',
      snackbarTypes.ERROR,
    )
    return dispatch(getCandidateError())
  }
}
