import 'core-js/stable'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { JoyrideProvider } from 'components/JoyRide/JoyrideContext'

import ViewPort from 'components/Layout/ViewPort'
import Snackbar from 'components/SnackBar/SnackBar.js'

import getStore from 'src/store'
import getRouter from 'src/router'
import theme from 'src/theme'

// need this in the bundle to get an entry in the manifest for server email templates
import 'static/images/logo.png'

export const history = createBrowserHistory()
export const store = getStore(history)
export const router = getRouter(history, store)

ReactDOM.render(
  <Provider store={store}>
    <JoyrideProvider>
      <Snackbar />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ViewPort>{router}</ViewPort>
        </ThemeProvider>
      </StyledEngineProvider>
    </JoyrideProvider>
  </Provider>,
  document.getElementById('app'),
)
