export function hostAddress(append) {
  return window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '') +
    append !==
    undefined
    ? append
    : ''
}

export function imageAddress(key, fileName) {
  const encodedFileName = encodeURIComponent(fileName)
  return hostAddress(`/api/storage/image?key=${key}&fileName=${encodedFileName}`)
}

const buildFormData = values => {
  var body = new FormData()
  Object.keys(values).forEach(k => {
    body.append(k, values[k])
  })
  return {
    headers: new Headers({ enctype: 'multipart/form-data' }),
    body,
  }
}

const buildJsonData = values => ({
  headers: values && new Headers({ 'content-type': 'application/json' }),
  body: values && JSON.stringify(values),
})

const buildData = (values, formData) => {
  if (formData) {
    return buildFormData(values)
  } else if (values) {
    return buildJsonData(values)
  }

  return null
}

export function fetchDispatch({
  path,
  values,
  setState,
  onResponse,
  onError,
  formData = false,
  responseOnError = true,
  method = 'POST',
}) {
  setState && setState(prev => ({ ...prev, submitting: true, errors: {} }))

  return fetch(hostAddress(path), {
    method,
    ...buildData(values, formData),
  })
    .then(res => {
      if (res.status === 401) {
        onError && onError(res) // Unauthorized
      }
      return res.json()
    })
    .then(json => {
      if (json) {
        if (json.errors && Object.keys(json.errors).length > 0) {
          var errors = {}
          // If multiple errors, only process the first one
          Object.keys(json.errors).forEach(
            k =>
              (errors[k] =
                Array.isArray(json.errors[k]) && json.errors[k].length > 0
                  ? json.errors[k][0]
                  : json.errors[k]),
          )
          setState && setState(prev => ({ ...prev, submitting: false, errors }))
          return json
        } else {
          setState && setState(prev => ({ ...prev, open: false, submitting: false, errors: {} }))
          onResponse && onResponse(json)
          return json
        }
      } else {
        setState && setState(prev => ({ ...prev, open: false, submitting: false, errors: {} })) // Empty json result, close form and dispatch.
        onResponse && onResponse()
        return json
      }
    })
    .catch(error => {
      console.log('No json received in response')
      console.log(error)
      setState && setState(prev => ({ ...prev, open: false, submitting: false, errors: {} })) // Either a json error or no json recieved.  Close form and dispatch.
      responseOnError && onResponse && onResponse()
      !responseOnError && onError && onError(error)
      return error
    })
    .catch(error => {
      console.error('Error fetching data')
      console.error(error)
      onError && onError(error)
      return error
    })
}

export function isEmpty(str) {
  return !str || 0 === str.length || str === 'null'
}

export function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function capitalize(s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const uncapitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toLowerCase() + s.slice(1)
}

export function hasChanged(current = {}, initial = {}) {
  var changed = false
  Object.keys(current).forEach(k => {
    if (current[k] !== initial[k]) {
      changed = true
    }
  })
  return changed
}

export function copyToLower(obj = {}) {
  var key,
    keys = Object.keys(obj)
  var n = keys.length
  var newobj = {}
  while (n--) {
    key = keys[n]
    newobj[uncapitalize(key)] = obj[key]
  }
  return newobj
}

export function sortDescending(a, b) {
  if (a < b) return 1
  if (a > b) return -1
  return 0
}

export function sortAscending(a, b) {
  if (a < b) return -1
  if (a > b) return 1
  return 0
}

export const sortAscendingKey = key => (a, b) => sortAscending(a[key], b[key])

export function getContentHeight(state, theme) {
  return state.layout.height - theme.layout.toolbarHeight
}

export function getContentHeightWithJobFilters(state, theme) {
  return (
    state.layout.height -
    (theme.layout.toolbarHeight + theme.layout.footerHeight + state.layout.jobFiltersHeight)
  )
}

export function sum(total, amount) {
  return total + amount
}

export function spread(final, current) {
  return { ...final, ...current }
}

export function getLayoutHeight(state) {
  return state.layout.height
}

//This merges default props with ownprops, ensuring that null or undefined ownprop values do not override the default props
export function mergeProps(defaultProps, ownProp) {
  var mergedProps = {}
  Object.keys(defaultProps).forEach(k => {
    mergedProps[k] = ownProp[k] !== undefined && ownProp[k] != null ? ownProp[k] : defaultProps[k]
  })
  return mergedProps
}

export function setIfEmpty(value) {
  return value === undefined || value === null ? '' : value
}

export function checkEmpty(value) {
  return value === undefined || value === null || value == ''
}

export const isDefined = value => value !== undefined && value !== null

export function convertValueToArray(value) {
  return checkEmpty(value) ? [] : Array.isArray(value) ? value : [value]
}

export const getLabel = f => (f.label ? f.label : f.id)

export const isFunction = obj => typeof obj === 'function'

export const removeNull = obj => {
  var newObj = { ...obj }
  Object.keys(newObj).forEach(key => obj[key] === null && delete newObj[key])
  return newObj
}

export const mergeDefaults = (source, defaults) => ({ ...defaults, ...removeNull(source) })

export const getCamelCaseLabel = key => {
  var result = key.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const evalFunc = (value, props = undefined) =>
  typeof value === 'function' ? value(props) : value

export const isError = value =>
  value === undefined || value === null || value === '' ? false : true

export const formatDate = date => (!isEmpty(date) && date.includes('T') ? date.split('T')[0] : date)

// NOTE: does not work properly with values longer than
// single-digit trillions
//Converts whole number cents to money
export function toMoney(cents) {
  return '$' + cents.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function splitCamelCaseToString(string) {
  return string.split(/(?=[A-Z])/).join(' ')
}
