import * as React from 'react'
import {
  useTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserFilters } from 'src/redux/filters'
import { transformFiltersData } from './helpers'

export default function FilterTable() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const { userFilters = [] } = useSelector(state => state.filters)
  const { userId, employerUserId } = useSelector(state => state.identity)

  const rows = transformFiltersData(userFilters, employerUserId)

  React.useEffect(() => {
    dispatch(fetchUserFilters(userId))
  }, [])
  const handleChangePage = newPage => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }
  const columns = [
    { id: 'name', label: 'Filter Name' },
    { id: 'type', label: 'Type' },
    { id: 'cadence', label: 'Alert Notifications' },
    { id: 'created', label: 'Created' },
    { id: 'actions', label: 'Actions' },
  ]

  return (
    <Paper
      sx={{
        width: '100%',
        display: 'table',
        tableLayout: 'fixed',
        overflow: 'scroll',
        marginBottom: '50px',
      }}
    >
      <TableContainer>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead sx={{ '&.MuiTableCell-stickyHeader': { backgroundColor: 'black' } }}>
            <TableRow key='filter-header'>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    '&.MuiTableCell-stickyHeader': {
                      backgroundColor: theme.palette.secondary.light,
                    },
                  }}
                >
                  <Typography variant='body1'>{column.label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={row.name}>
                  {columns.map(column => {
                    const value = row[column.id]
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
