import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import { Grid, useTheme, Button, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import CloseIcon from '@mui/icons-material/Close'
import { fetchUpdateCandidate } from 'src/redux/candidate'
import ResumeUpload from 'components/UserFields/ResumeUpload'
import { hostAddress } from 'data/helpers'
import NPINumberField from 'components/UserFields/NPINumberField'
import GenericTextField from 'components/UserFields/GenericTextField'
import StateField from 'components/UserFields/StateField'
import SpecialtyField from 'components/UserFields/SpecialtyField'
import SubspecialtyField from 'components/UserFields/SubspecialtyField'
import {
  getBoardingEligibilityOptions,
  getLicensedStatesOptions,
  getAvailabilityOptions,
} from 'components/UserFields/helpers'
import GenericSelectField from 'components/UserFields/GenericSelectField'
import GenericMultipleSelect from 'components/UserFields/GenericMultipleSelect'
import GenericMultiLineTextField from 'components/UserFields/GenericMultiLineTextField'
import GenericRadioGroup from 'components/UserFields/GenericRadioGroup'
import GenericDatePicker from '../../../components/UserFields/GenericDatePicker'
import { getVisaOptions } from '../../../components/UserFields/helpers'

const AboutJob = ({ candidate }) => {
  const [errors, setErrors] = React.useState({})

  const theme = useTheme()
  const user = useSelector(state => state.user)
  const { id, loading, resumeLoading } = candidate
  user.type = 'candidate'
  user.id = id
  const [readOnly, setReadOnly] = React.useState(true)
  const [values, setValues] = React.useState({})
  const dispatch = useDispatch()
  const setDefaultValues = () => {
    setValues({
      preferredCity: candidate.preferredCity,
      preferredState: candidate.preferredState,
      resume: candidate.resume,
      aboutJob: candidate.aboutJob,
      npiNumber: candidate.npiNumber,
      specialties: candidate.specialties[0] || {},
      subspecialties: candidate.subspecialties[0] ? candidate.subspecialties[0] : {},
      boardEligibility: candidate.boardEligibility,
      visaId: candidate.visaId,
      licensedStates: candidate.licensedStates ? candidate.licensedStates : [],
      searchStatus: candidate.searchStatus,
      availabilityDate: candidate.availabilityDate,
    })
  }
  React.useEffect(() => {
    if (!candidate.loading) {
      setDefaultValues()
    }
  }, [candidate])

  const handleEditClick = () => {
    setReadOnly(false)
  }
  const handleCancelClick = () => {
    setDefaultValues()
    setErrors({})
    setReadOnly(true)
  }

  const handleSubmit = () => {
    const data = {
      ...values,
      specialties: [values.specialties.id],
      subspecialties:
        values.subspecialties !== null && Object.keys(values.subspecialties).length > 0
          ? [values.subspecialties.id]
          : [],
      id: candidate.id,
    }
    dispatch(
      fetchUpdateCandidate(data, 'Updated Basic Info', null, {
        function: setReadOnly,
        params: true,
      }),
    )
  }

  const handleResumeClick = () => {
    const { key, fileName } = values.resume || {}
    fetch(hostAddress(`/api/storage/resume?key=${key}&fileName=${fileName}`), {
      method: 'GET',
      headers: new Headers({ 'content-type': 'application/json' }),
    }).then(response => response.blob().then(blob => window.open(URL.createObjectURL(blob))))
  }

  const handleRemoveResume = () => {
    dispatch(
      fetchUpdateCandidate(
        {
          id: candidate.id,
          removeResume: true,
        },
        'Removed Resume',
      ),
    )
  }

  const handleResumeUpload = event => {
    const uploadFiles = event.target.files
    if (uploadFiles) {
      const arrFiles = Array.from(uploadFiles)
      const file = arrFiles.length > 0 ? arrFiles[0] : undefined
      dispatch(
        fetchUpdateCandidate(
          {
            id: candidate.id,
            resumeFile: file,
          },
          'Uploaded Resume',
          null,
        ),
      )
    }
  }
  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  const resumeButtonText = values.resume ? 'Change Resume' : 'Upload Resume'
  return (
    <Grid
      container
      direction='column'
      justifyContent='flex-start'
      sx={{
        border: '1px solid',
        borderTop: 'none',
        borderRadius: '0 0 20 20',
        height: '70%',
        padding: '50px',
        backgroundColor: theme.palette.primary.light,
      }}
    >
      <Grid container direction='row' justifyContent='space-between' item xs={12}>
        <Grid container item justifyContent='flex-end'>
          {readOnly ? (
            <Button
              variant='contained'
              size='small'
              onClick={handleEditClick}
              startIcon={<EditIcon />}
            >
              Edit Info
            </Button>
          ) : (
            <Grid item>
              <Button size='small' sx={{ marginRight: '20px' }} onClick={handleCancelClick}>
                Cancel
              </Button>
              <LoadingButton
                variant='contained'
                size='small'
                onClick={handleSubmit}
                startIcon={<SaveIcon />}
                loading={loading}
                disabled={Object.keys(errors).length > 0}
              >
                Save Changes
              </LoadingButton>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={2}
          sx={{ marginBottom: '20px' }}
        >
          {values.resume && (
            <Grid container direction='column'>
              <Grid
                container
                direction='row'
                alignItems='center'
                spacing={2}
                sx={{ padding: '0 0 0 20' }}
              >
                <a
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => handleResumeClick()}
                >
                  {values.resume.fileName}
                </a>
                <IconButton onClick={handleRemoveResume}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
          <ResumeUpload
            value={values.resume}
            handleChange={handleResumeUpload}
            label={resumeButtonText}
            name='resume'
            loading={resumeLoading}
          />
          <Grid
            container
            rowSpacing={2}
            sx={{
              backgroundColor: 'white',
              marginTop: '20px',
              padding: '20px',
              borderRadius: '10px',
            }}
          >
            <Grid item xs={12} md={6}>
              <SpecialtyField
                value={values.specialties || {}}
                disabled={readOnly}
                handleChange={handleChange}
                name='specialties'
                label='Specialty'
                errors={errors}
                setErrors={setErrors}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SubspecialtyField
                value={values.subspecialties || {}}
                disabled={readOnly}
                handleChange={handleChange}
                name='subspecialties'
                specialtyId={values.specialties && values.specialties.id}
                label='Sub-specialty'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GenericTextField
                name='preferredCity'
                label='Preferred City'
                value={values.preferredCity}
                disabled={readOnly}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StateField
                name='preferredState'
                label='Preferred State'
                value={values.preferredState}
                disabled={readOnly}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <NPINumberField
                value={values.npiNumber}
                disabled={readOnly}
                label='NPI Number'
                name='npiNumber'
                handleChange={handleChange}
                errors={errors}
                setErrors={setErrors}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GenericSelectField
                value={values.boardEligibility}
                disabled={readOnly}
                label='Board Eligibility'
                name='boardEligibility'
                handleChange={handleChange}
                options={getBoardingEligibilityOptions()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GenericSelectField
                value={values.visaId}
                disabled={readOnly}
                label='Visa Requirement'
                name='visaId'
                handleChange={handleChange}
                options={getVisaOptions()}
                helperText='Will you now or in the future require visa sponsorship?'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GenericMultipleSelect
                value={values.licensedStates}
                disabled={readOnly}
                label='Licensed States'
                name='licensedStates'
                handleChange={handleChange}
                options={getLicensedStatesOptions()}
              />
            </Grid>
            <Grid item xs={12}>
              <GenericRadioGroup
                value={values.searchStatus}
                disabled={readOnly}
                label='I am'
                name='searchStatus'
                handleChange={handleChange}
                options={getAvailabilityOptions()}
              />
            </Grid>
            <Grid item xs={12}>
              <GenericDatePicker
                value={values.availabilityDate}
                handleChange={handleChange}
                label='Availability Date'
                name='availabilityDate'
                disabled={readOnly}
              />
            </Grid>
            <Grid item xxs={12} md={6}>
              <GenericMultiLineTextField
                name='aboutJob'
                label='Describe your ideal job'
                placeholder='I am looking for a neurologist position in a city with a great education system and academic opportunities'
                value={values.aboutJob}
                disabled={readOnly}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default AboutJob
