import * as React from 'react'
import { Alert, Stack } from '@mui/material'

export default function InformationalAlert({ text, closeAction, setOpen }) {
  const handleClose = () => {
    setOpen(false)
    closeAction()
  }

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity='info' icon={false} onClose={handleClose}>
        {text}
      </Alert>
    </Stack>
  )
}
