import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { isNil } from 'lodash'
import { Grid, Typography, Box } from '@mui/material'
import { List } from 'components/Listing/List'
import Loader from 'components/Loader'
import { Filters } from '../../components/Filters'
import { FilterType } from '../../components/Filters/filterDefinitions'
import { fetchAllCandidates } from 'src/redux/candidate'
import PhysicianContent from './PhysicianContent'
import { fetchProviderFilterValues } from 'src/redux/filters'
import { applyFilters } from 'components/Filters/helpers'
import CandidatesPageTutorial from './CandidatesPageTutorial'

const Physicians = () => {
  const signedIn = useSelector(state => state.identity.signedIn)
  const dispatch = useDispatch()
  const [activeItem, setActiveItem] = useState()
  const [page, setPage] = useState(0)
  const pageSize = 50
  const physicianList = useSelector(state => state.candidate.list) || []
  const { isLoadingFilters } = useSelector(state => state.filters).loading
  const filterValues = useSelector(state => state.filters.providerFilterValues) || {}
  const totalCandidates = useSelector(state => state.candidate.totalCandidates)
  const totalPages = Math.ceil(totalCandidates / pageSize)
  const { loading: isLoadingCandidates } = useSelector(state => state.candidate)
  const [searchParams] = useSearchParams()

  const handleListItemClick = currId => {
    const activePhysician = !isNil(currId)
      ? physicianList.find(p => `${p.id}` === `${currId}`)
      : physicianList.length > 0
        ? physicianList[0]
        : null
    setActiveItem(activePhysician)
  }

  useEffect(() => {
    dispatch(fetchProviderFilterValues())
  }, [])

  useEffect(() => {
    setActiveItem(physicianList[0])
  }, [physicianList, searchParams])

  const leftPanelHeader =
    'Results: ' + `${totalCandidates ? totalCandidates.toLocaleString() : 0} ` + 'Providers'

  // TODO: Update fetchAllCandidates to do activeOnly: true
  const applyCandidateFilters = ({ searchParams }) => {
    applyFilters({
      searchParams,
      dispatch,
      getData: fetchAllCandidates,
      page,
      pageSize,
    })
  }

  return (
    <>
      <Grid container justifyContent='center'>
        <CandidatesPageTutorial />
        {signedIn && Object.keys(filterValues).length > 0 && (
          <Grid item xs={12}>
            <Filters
              filterType={FilterType.CANDIDATE}
              filterValues={filterValues}
              listType={'provider'}
              loading={isLoadingFilters}
              applyFilters={applyCandidateFilters}
            />
          </Grid>
        )}
        {isLoadingCandidates ? (
          <Loader />
        ) : (
          <Grid container sx={{ maxWidth: '70em' }}>
            <Grid container item sm={12} md={5} direction='row' sx={{ height: '100%' }}>
              <Grid item>
                <Typography variant='h6' sx={{ padding: '10px 16px' }}>
                  {leftPanelHeader}
                </Typography>
              </Grid>
              <Grid container alignItems='flex-start' sx={{ maxHeight: '100vh', overflow: 'auto' }}>
                <div className='joyride_candidate_list'>
                  <List
                    setPage={setPage}
                    page={page}
                    data={physicianList}
                    listType={'provider'}
                    isPhysicianList={true}
                    handleListItemClick={handleListItemClick}
                    dataTotal={totalCandidates}
                    totalPages={totalPages}
                    signedIn={signedIn}
                    isLoading={isLoadingFilters}
                  />
                </div>
              </Grid>
            </Grid>
            <Box display={{ xs: 'none', sm: 'none', md: 'contents' }}>
              <Grid
                container
                item
                md={7}
                direction='column'
                sx={{ maxHeight: '100vh', overflow: 'auto' }}
              >
                {activeItem && <PhysicianContent item={activeItem} signedIn={signedIn} />}
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default Physicians
