import React from 'react'
import { Link, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import Toolbar from '@mui/material/Toolbar'
import { useHandleChangeRoute } from 'data/hooks'

const useStyles = makeStyles(({ layout, breakpoints, palette }) =>
  createStyles({
    bottomToolBar: {
      backgroundColor: palette.primary.light,
      minHeight: layout.footerHeight,
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [breakpoints.down('xs')]: {
        justifyContent: 'center',
        textAlign: 'center',
        flexWrap: 'wrap',
      },
    },
    footerColumn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center', // Center items on mobile
      flexWrap: 'wrap',
      width: '100%', // Ensure it takes up full width
    },
    footerItem: {
      display: 'flex',
      cursor: 'pointer',
      padding: '0 20px',
      [breakpoints.down('xs')]: {
        width: '50%', // Two items per row on xs
        justifyContent: 'center',
        textAlign: 'center',
        padding: '10px',
        boxSizing: 'border-box',
        borderRight: 'none', // Ensure no divider on xs
      },
      [breakpoints.up('sm')]: {
        width: '20%', // Fit all items on one row on sm and larger
        borderRight: `4px solid ${palette.secondary.main}`, // Divider on sm and larger
      },
      [breakpoints.up('md')]: {
        width: '16.66%', // Adjust to fit on one row on md and larger
      },
      [breakpoints.up('lg')]: {
        width: '14.28%', // Adjust to fit on one row on lg and larger
      },
      '&:last-child': {
        borderRight: 'none', // Remove divider on the last item
      },
    },
    footerItemNoBorder: {
      display: 'flex',
      cursor: 'pointer',
      padding: '0 20px',
      [breakpoints.down('xs')]: {
        width: '50%', // Two items per row on xs
        justifyContent: 'center',
        textAlign: 'center',
        padding: '10px',
        boxSizing: 'border-box',
        borderRight: 'none', // Ensure no divider on xs
      },
      [breakpoints.up('sm')]: {
        width: '20%', // Fit all items on one row on sm and larger
        borderRight: `4px solid ${palette.secondary.main}`, // Divider on sm and larger
      },
      [breakpoints.up('md')]: {
        width: '16.66%', // Adjust to fit on one row on md and larger
      },
      [breakpoints.up('lg')]: {
        width: '14.28%', // Adjust to fit on one row on lg and larger
      },
      '&:last-child': {
        borderRight: 'none', // Remove divider on the last item
      },
    },
    logoStyle: {
      cursor: 'pointer',
      textAlign: 'center', // Center text on all screen sizes
      width: '100%', // Ensure logo takes full width on all screens
      marginTop: '20px', // Add some space on top when on small screens
      [breakpoints.up('sm')]: {
        marginTop: '0px', // Remove top margin on larger screens
      },
    },
  }),
)

export default function Footer(props) {
  const classes = useStyles({ props })
  const handleChangeRoute = useHandleChangeRoute()

  return (
    <Grid>
      <Toolbar className={classes.bottomToolBar}>
        <Grid container className={classes.footer}>
          <Grid item className={classes.footerColumn}>
            <Grid item className={classes.footerItem}>
              <Typography inverted='true'>
                <Link color='contrastText' onClick={handleChangeRoute('/about')} underline='hover'>
                  About Us
                </Link>
              </Typography>
            </Grid>
            <Grid item className={classes.footerItem}>
              <Typography>
                <Link
                  color='contrastText'
                  onClick={handleChangeRoute('/contact')}
                  underline='hover'
                >
                  Contact Us
                </Link>
              </Typography>
            </Grid>
            <Grid item className={classes.footerItem}>
              <Typography>
                <Link
                  color='contrastText'
                  onClick={handleChangeRoute('/support')}
                  underline='hover'
                >
                  Support
                </Link>
              </Typography>
            </Grid>
            <Grid item className={classes.footerItem}>
              <Typography>
                <Link
                  color='contrastText'
                  onClick={handleChangeRoute('/register/hospitals')}
                  underline='hover'
                >
                  Partner With Us
                </Link>
              </Typography>
            </Grid>
            <Grid item className={classes.footerItem}>
              <Typography>
                <Link
                  href='/tac'
                  underline='hover'
                  color='#1B1B1B'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms of use
                </Link>
              </Typography>
            </Grid>
            <Grid item className={classes.footerItemNoBorder}>
              <Typography>
                <Link
                  href='/privacy'
                  underline='hover'
                  target='_blank'
                  rel='noopener noreferrer'
                  color='#1B1B1B'
                >
                  Privacy Policy
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.logoStyle}>
            <Typography>
              <Link color='contrastText' onClick={handleChangeRoute('/')} underline='hover'>
                © {new Date().getFullYear()} MyStethi, LLC
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </Grid>
  )
}
