import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextField, Autocomplete, useTheme } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { getSpecialties } from 'data/layout/actions'

const SpecialtyField = ({
  name,
  label,
  setErrors,
  errors,
  value = {},
  handleChange,
  disabled,
  ...props
}) => {
  React.useEffect(() => {
    getSpecialties(dispatch)
  }, [])

  const dispatch = useDispatch()
  const theme = useTheme()
  const { specialties = [] } = useSelector(state => state.layout)
  const specialtyOptions = specialties
    .filter(specialty => specialty.candidateType.name === 'Provider')
    .map(s => ({ id: s.id, name: s.name }))
  const currentValue = value !== null ? { id: value.id, name: value.name } : {}

  const validate = (event, newValue) => {
    if (!newValue) {
      setErrors({ ...errors, specialties: 'Specialty is required' })
    } else {
      delete errors.specialties
    }

    handleChange({
      target: { name: name, value: newValue },
    })
  }
  return (
    <Autocomplete
      disablePortal
      id={name}
      options={specialtyOptions}
      disabled={disabled}
      popupIcon={disabled ? null : <ArrowDropDownIcon />}
      getOptionLabel={option => (option.name ? option.name : '')}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => validate(event, newValue)}
      value={currentValue || {}}
      renderInput={params => (
        <TextField
          {...params}
          error={errors.specialties !== undefined}
          helperText={errors.specialties}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.primary.dark,
            },
            '& fieldset': disabled && { border: 'none' },
          }}
          label={label}
        />
      )}
      {...props}
    />
  )
}

export default SpecialtyField
