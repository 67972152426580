import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'
import { fetchDispatch } from 'data/helpers'
import { mapJobData } from '../data/jobs/reducer'

export const GET_JOB_LOADING = 'job/GET_JOB_LOADING'
export const GET_JOB_SUCCESS = 'job/GET_JOB_SUCCESS'
export const GET_JOB_FAILURE = 'job/GET_JOB_FAILURE'

export const getJobLoading = () => ({ type: GET_JOB_LOADING })
export const getJobSuccess = payload => ({ type: GET_JOB_SUCCESS, payload })
export const getJobFailure = payload => ({ type: GET_JOB_FAILURE, payload })

export const fetchGetJob = jobId => {
  return async dispatch => {
    dispatch(getJobLoading())
    try {
      const response = await fetchDispatch({
        path: `/api/jobs/${jobId}`,
        method: 'GET',
      })
      dispatch(getJobSuccess(response))
    } catch (error) {
      dispatch(
        showSnackbar(
          'Sorry! We encountered an error finding you job. Check back again!',
          snackbarTypes.ERROR,
        ),
      )
      dispatch(getJobFailure(error.response))
    }
  }
}

const initialState = { job: {}, loading: false }

export function jobReducer(state = initialState, action) {
  switch (action.type) {
    case GET_JOB_LOADING:
      return { ...state, loading: true }
    case GET_JOB_SUCCESS:
      return { job: mapJobData([action.payload.job])[0], loading: false }
    case GET_JOB_FAILURE:
      return { ...state, loading: false }
    default:
      return state
  }
}
