import React, { useState, useEffect } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { Grid, Button, useTheme, useMediaQuery } from '@mui/material'
import { useDispatch } from 'react-redux'
import { TextEditor } from 'components/TextEditor/TextEditor'
import { fetchUpdateCandidate } from 'src/redux/candidate'
import Locations from './Locations'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

const AboutYou = ({ user, candidate, setActiveStep, activeStep }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const below_lg = useMediaQuery(theme.breakpoints.down('md'))

  const [values, setValues] = useState({
    city: '',
    state: '',
    preferredCity: '',
    preferredState: '',
    about: '',
    aboutRaw: '',
  })

  useEffect(() => {
    if (!candidate.loading) {
      setValues({
        city: candidate.city,
        state: candidate.state,
        preferredCity: candidate.preferredCity,
        preferredState: candidate.preferredState,
        about: candidate.about,
        aboutRaw: candidate.aboutRaw,
      })
    }
  }, [user, candidate])

  const handleChange = event => {
    setValues(prevValues => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSave = () => {
    const data = {
      state: values.state,
      city: values.city,
      preferredCity: values.preferredCity,
      preferredState: values.preferredState,
      availabilityDate: values.availabilityDate,
      about: values.about,
      aboutRaw: values.aboutRaw,
      id: user.id,
    }
    dispatch(
      fetchUpdateCandidate(data, 'Updated your about you!', null, {
        function: setActiveStep,
        params: activeStep + 1,
      }),
    )
    dispatch(
      fetchTrackEvent({
        eventName: 'onboarding_about_you_submit_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: user.email,
      }),
    )
  }

  const handleSkip = () => {
    dispatch(
      fetchUpdateCandidate({ id: user.id, setupSeen: true, suppressSnackbar: true }, null, null, {
        function: setActiveStep,
        params: activeStep + 1,
      }),
    )
    dispatch(
      fetchTrackEvent({
        eventName: 'onboarding_about_you_skip_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: user.email,
      }),
    )
  }

  const handleEditorChange = event => {
    const { about, aboutRaw } = event
    setValues(prevValues => ({
      ...prevValues,
      about,
      aboutRaw,
    }))
  }

  return (
    <>
      <Locations values={values} handleChange={handleChange} />
      <Grid item sx={{ marginBottom: '50px' }}>
        <TextEditor
          initialValue={values.about}
          placeholder='Tell us a little about yourself...'
          onChange={handleEditorChange}
        />
      </Grid>
      <Grid
        container
        direction='row'
        alignItems='center'
        sx={[
          {
            padding: '10px',
            justifyContent: 'flex-start',
            marginBottom: '20px',
          },
          below_lg && {
            zIndex: 1000,
            justifyContent: 'center',
            marginTop: '50px',
            position: 'fixed',
            bottom: '0px',
            left: '0px',
            right: '0px',
            marginBottom: '0px',
            backgroundColor: theme.palette.secondary.light,
          },
        ]}
      >
        <Grid item sx={{ paddingRight: '20px' }}>
          <Button onClick={handleSkip}>Skip</Button>
        </Grid>
        <Grid item>
          <LoadingButton
            type='submit'
            disabled={false}
            onClick={handleSave}
            autoFocus
            variant='contained'
            loading={candidate.loading}
          >
            Save & Continue
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  )
}

export default AboutYou
