import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GenericDialog from 'components/Dialog/GenericDialog'
import { convertValueToArray, copyToLower, sortAscendingKey } from 'data/helpers'
import { useDialogState } from 'data/dialog/actions'
import { getSpecialties } from 'data/layout/actions'
import { fetchUpdateCandidate } from 'src/redux/candidate'
import { States } from 'data/constants'

export const dialogId = 'Edit Profile'

const handleValidation = (values, dialogState) => {
  let errors = copyToLower(dialogState.errors)
  if ('npiNumber' in values) {
    if (values.npiNumber) {
      if (values.npiNumber.trim().length !== 10) {
        errors.npiNumber = 'npi number must be 10 digits.'
      }
    } else {
      if (errors.npiNumber) {
        errors.delete('npiNumber')
      }
    }
  }
  return errors
}

export default function EditProfileDialog({ id = dialogId }) {
  const [, setState] = useDialogState(id)
  const dispatch = useDispatch()

  React.useEffect(() => {
    getSpecialties(dispatch)
  }, [])

  const candidate = useSelector(state => state.candidate)
  const { specialties, subspecialties } = useSelector(state => state.layout)
  let specialtyValue
  let subspecialtyValue
  if(!Object.keys(candidate).length === 0) {
    const candidateSpecialty = candidate.specialties[0]
    specialtyValue = { value: candidateSpecialty.id, label: candidateSpecialty.name }
    const candidateSubspecialty = candidate.subspecialties && candidate.subspecialties.length > 0 ? candidate.subspecialties[0] : null
    subspecialtyValue = candidateSubspecialty
      ? { value: candidateSubspecialty.id, label: candidateSubspecialty.name, specialtyId: candidateSubspecialty.specialtyId}
      : null
  }

  const handleSubmit = ({ specialtyId, subspecialtyId, ...other }) => {
    dispatch(
      fetchUpdateCandidate({
        ...other,
        specialties: convertValueToArray(specialtyId),
        subspecialties: convertValueToArray(subspecialtyId),
      }),
    )
    setState()
  }

  return (
    <GenericDialog
      id={id}
      title={id}
      maxWidth='sm'
      submitLabel={'Save Profile'}
      onSubmit={handleSubmit}
      validate={handleValidation}
      initialValues={candidate}
      type='edit'
      fields={[
        {
          id: 'id',
          hidden: true,
        },
        {
          id: 'firstName',
          label: 'First Name',
          required: true,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'lastName',
          label: 'Last Name',
          forceErrorMargin: false,
          required: true,
          variant: 'outlined',
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'phone',
          label: 'Phone',
          type: 'phoneNumber',
          forceErrorMargin: false,
          required: false,
          variant: 'outlined',
          margin: 'dense',
        },
        {
          id: 'specialtyId',
          type: 'autocomplete',
          label: 'Specialty',
          xs: 6,
          forceErrorMargin: false,
          required: false,
          variant: 'outlined',
          margin: 'dense',
          value: specialtyValue,
          items: specialties.map(s => ({ value: s.id, label: s.name })),
        },
        {
          id: 'subspecialtyId',
          type: 'autocomplete',
          label: 'Subspecialty',
          xs: 6,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          required: false,
          value: subspecialtyValue,
          items: subspecialties
            .map(s => ({
              value: s.id,
              label: s.name,
              specialtyId: s.specialtyId,
            }))
            .sort(sortAscendingKey('label')),
          filter: (items, values) =>
            items.filter(
              ss =>
                values['specialtyId'] ===
                subspecialties.find(x => x.id === ss.value).specialtyId,
            ),
        },
        {
          id: 'city',
          label: 'City',
          required: false,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'state',
          label: 'State',
          type: 'autocomplete',
          required: false,
          forceErrorMargin: false,
          variant: 'outlined',
          items: Object.keys(States).map(k => ({ value: k, label: `${States[k]}` })),
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'preferredCity',
          label: 'Preferred City',
          required: false,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'preferredState',
          label: 'Preferred State',
          type: 'autocomplete',
          required: false,
          forceErrorMargin: false,
          variant: 'outlined',
          items: Object.keys(States).map(k => ({ value: k, label: `${States[k]}` })),
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'npiNumber',
          label: 'NPI Number',
          required: false,
          forceErrorMargin: false,
          type: 'number',
          variant: 'outlined',
          margin: 'dense',
        },
        {
          id: 'residency',
          label: 'Residency',
          type: 'text',
          required: false,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
        },
        {
          id: 'residencyStart',
          label: 'Start Date',
          required: false,
          forceErrorMargin: false,
          type: 'datepicker',
          variant: 'outlined',
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'residencyEnd',
          label: 'End Date',
          required: false,
          forceErrorMargin: false,
          type: 'datepicker',
          variant: 'outlined',
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'about',
          label: 'About',
          type: 'textEditor',
          initialValue: candidate.about,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          multiline: true,
        },
        {
          id: 'aboutRaw',
          hidden: true,
        },
      ]}
    />
  )
}
