import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Box, IconButton, Avatar, Grid, useTheme, useMediaQuery } from '@mui/material'
import { useDispatch } from 'react-redux'
import Loader from 'components/Loader'
import { UploadPhotoButton } from 'pages/Users/Profile/Buttons'
import { getPhysicianProfileImage } from 'src/pages/Physicians/helpers'
import { fetchUpdateCandidate } from 'src/redux/candidate'
import Timeline from './Timeline'
import PhoneNumberField from 'components/UserFields/PhoneNumberField'
import GenericSwitchField from 'components/UserFields/GenericSwitchField'
import GenericTextField from 'components/UserFields/GenericTextField'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

const TheBasics = ({ user, candidate, setActiveStep, activeStep }) => {
  const dispatch = useDispatch()
  const imageURL = getPhysicianProfileImage(candidate.profileImage)
  const theme = useTheme()
  const [values = {}, setValues] = React.useState()
  const below_lg = useMediaQuery(theme.breakpoints.down('md'))

  React.useEffect(() => {
    if (!candidate.loading) {
      setValues({
        availabilityDate: candidate.availabilityDate,
        searchStatus: candidate.searchStatus,
        about: candidate.about,
        phone: candidate.phone,
        showPhone: candidate.showPhone,
        showEmail: candidate.showEmail,
        email: candidate.email,
      })
    }
  }, [user, candidate])

  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }
  const handleSave = () => {
    const data = {
      availabilityDate: values.availabilityDate,
      searchStatus: values.searchStatus,
      id: user.id,
      setupSeen: true,
      phone: values.phone,
      showPhone: values.showPhone,
      showEmail: values.showEmail,
    }
    dispatch(
      fetchUpdateCandidate(data, 'Updated Basic Info', null, {
        function: setActiveStep,
        params: activeStep + 1,
      }),
    )
    dispatch(
      fetchTrackEvent({
        eventName: 'onboarding_basics_submit_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: user.email,
      }),
    )
  }

  const handleSkip = () => {
    dispatch(
      fetchUpdateCandidate({ id: user.id, setupSeen: true, suppressSnackbar: true }, null, null, {
        function: setActiveStep,
        params: activeStep + 1,
      }),
    )
    dispatch(
      fetchTrackEvent({
        eventName: 'onboarding_basics_skip_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: user.email,
      }),
    )
  }

  return (
    <>
      <Box sx={{ border: '0px' }}>
        <IconButton color='primary' aria-label='upload picture' component='span'>
          {candidate.photoLoading ? (
            <Loader />
          ) : (
            <Avatar src={imageURL} sx={{ width: 100, height: 100 }} />
          )}
        </IconButton>
        <UploadPhotoButton user={user} />
      </Box>
      <Box sx={{ border: '1px solid gray', padding: '20px', margin: '20px 0px' }}>
        <Grid container direction='row' rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <GenericTextField value={values.email} disabled={true} label='Email' name='email' />
          </Grid>
          <Grid item xs={6}>
            <PhoneNumberField
              value={values.phone}
              handleChange={handleChange}
              label='Phone Number'
              name='phone'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GenericSwitchField
              name='showEmail'
              label='Display Email'
              value={values.showEmail}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GenericSwitchField
              name='showPhone'
              label='Display Phone Number'
              value={values.showPhone}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Box>
      <Timeline values={values} handleChange={handleChange} />
      <Grid
        container
        direction='row'
        alignItems='center'
        sx={[
          {
            padding: '10px',
            justifyContent: 'flex-start',
            marginBottom: '20px',
          },
          below_lg && {
            zIndex: 1000,
            justifyContent: 'center',
            marginTop: '50px',
            position: 'fixed',
            bottom: '0px',
            left: '0px',
            right: '0px',
            marginBottom: '0px',
            backgroundColor: theme.palette.secondary.light,
          },
        ]}
      >
        <Grid item sx={{ paddingRight: '20px' }}>
          <Button onClick={handleSkip}>Skip</Button>
        </Grid>
        <Grid item>
          <LoadingButton
            type='submit'
            disabled={false}
            onClick={handleSave}
            autoFocus
            variant='contained'
            loading={candidate.loading}
          >
            Save & Continue
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  )
}

export default TheBasics
