// legacy redux -- need to eventually convert everything to new
// all new redux actions should go through the new format
import legacyCandidateReducer from 'data/candidate/reducers'
import contactReducer from 'data/contact/reducers'
import dialogReducer from 'data/dialog/reducers'
import legacyEmployerReducer from 'data/employer/reducers'
import legacyEmployerUserReducer from 'data/employerUser/reducers'
import jobsReducer from 'data/jobs/reducer'
import layoutReducer from 'data/layout/reducers'
import popoverReducer from 'data/popover/reducers'
import superUserReducer from 'data/superUser/reducers'
import tableReducer from 'data/table/reducers'
import userReducer from 'data/user/reducers'

// new redux
import { candidateReducer } from 'src/redux/candidate'
import { csvReducer } from 'src/redux/csv'
import { employerUserReducer } from 'src/redux/employerUser'
import { identityReducer } from 'src/redux/identity'
import { snackbarReducer } from 'src/redux/snackbar'
import { usersReducer } from 'src/redux/users'
import { profileReducer } from 'src/redux/profile'
import { emailReducer } from 'src/redux/email'
import { signupHospitalReducer } from 'src/redux/signupHospital'
import { registerReducer } from 'src/redux/register'
import { homeReducer } from 'src/redux/home'
import { realtorReducer } from 'src/redux/realtor'
import { newsletterSubscriptionReducer } from 'src/redux/newsletterSubscription'
import { candidateJobReducer } from 'src/redux/candidateJob'
import { filtersReducer } from 'src/redux/filters'
import { employerReducer } from 'src/redux/employer'
import { jobReducer } from 'src/redux/job'
import { productAnalyticsReducer } from './productAnalytics'

const createTransitionalReducer = (legacyReducer, newReducer) => {
  return (state, action) => {
    state = legacyReducer(state, action)
    return newReducer(state, action)
  }
}

const reducers = {
  candidate: createTransitionalReducer(legacyCandidateReducer, candidateReducer),
  register: registerReducer,
  contact: contactReducer,
  csv: csvReducer,
  employer: createTransitionalReducer(legacyEmployerReducer, employerReducer),
  employerUser: createTransitionalReducer(legacyEmployerUserReducer, employerUserReducer),
  dialogs: dialogReducer,
  job: jobReducer,
  jobs: jobsReducer,
  layout: layoutReducer,
  popovers: popoverReducer,
  identity: identityReducer,
  snackbar: snackbarReducer,
  superUser: superUserReducer,
  tables: tableReducer,
  user: userReducer,
  users: usersReducer,
  profile: profileReducer,
  email: emailReducer,
  signupHospital: signupHospitalReducer,
  home: homeReducer,
  realtors: realtorReducer,
  newsletterSubscription: newsletterSubscriptionReducer,
  candidateJobs: candidateJobReducer,
  filters: filtersReducer,
  productAnalyticsReducer: productAnalyticsReducer,
}

export default reducers
