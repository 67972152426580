import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import { Grid, useTheme, Button } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import PasswordField from 'components/UserFields/PasswordField'
import SaveIcon from '@mui/icons-material/Save'
import { fetchUpdatePassword } from 'src/redux/candidate'

const Security = ({ candidate }) => {
  const theme = useTheme()
  const [readOnly, setReadOnly] = React.useState(true)
  const dispatch = useDispatch()
  const [values, setValues] = React.useState({})
  const [errors, setErrors] = React.useState({})
  const user = useSelector(state => state.user)
  const loading = candidate.loading
  const setDefaultValues = () => {
    if (Object.keys(candidate.errors).length > 0) {
      Object.keys(candidate.errors).map(key => {
        setErrors({ ...errors, [key]: candidate.errors[key][0] })
      })
    }
  }

  React.useEffect(() => {
    if (!candidate.loading) {
      setDefaultValues()
    }
  }, [candidate])
  const handleEditClick = () => {
    setReadOnly(false)
  }

  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  const handleSubmit = () => {
    dispatch(fetchUpdatePassword({ ...values, id: user.id }))
  }

  const handleCancelClick = () => {
    setReadOnly(true)
  }

  return (
    <Grid
      container
      direction='column'
      justifyContent='flex-start'
      sx={{
        border: '1px solid',
        borderTop: 'none',
        borderRadius: '0 0 20 20',
        height: '70%',
        padding: '50px',
        backgroundColor: theme.palette.primary.light,
      }}
    >
      <Grid container direction='row' justifyContent='space-between' item xs={12}>
        <Grid container item justifyContent='flex-start'>
          {readOnly ? (
            <Button
              variant='contained'
              size='small'
              onClick={handleEditClick}
              startIcon={<EditIcon />}
            >
              Update Password
            </Button>
          ) : (
            <Grid item>
              <Button size='small' sx={{ marginRight: '20px' }} onClick={handleCancelClick}>
                Cancel
              </Button>
              <LoadingButton
                variant='contained'
                size='small'
                onClick={handleSubmit}
                startIcon={<SaveIcon />}
                loading={loading}
                disabled={
                  Object.keys(errors).length > 0 ||
                  !values.currentPassword ||
                  !values.password ||
                  !values.confirmPassword
                }
              >
                Save Password
              </LoadingButton>
            </Grid>
          )}
        </Grid>
        {!readOnly && (
          <PasswordField
            handleChange={handleChange}
            values={values}
            errors={errors}
            setErrors={setErrors}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default Security
