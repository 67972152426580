import React from 'react'
import { Grid } from '@mui/material'
import SpecialtyField from 'components/UserFields/SpecialtyField'
import SubspecialtyField from 'components/UserFields/SubspecialtyField'

const SpecialtyInfo = ({ errors, setErrors, values, handleChange }) => {
  return (
    <Grid container direction='column' spacing={3}>
      <Grid item xs={12} sm={6}>
        <SpecialtyField
          handleChange={handleChange}
          value={values.specialties}
          label='Specialty'
          name='specialties'
          setErrors={setErrors}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SubspecialtyField
          handleChange={handleChange}
          value={values.subspecialties}
          label='Sub-specialty'
          name='subspecialties'
          specialtyId={values.specialties && values.specialties.id}
        />
      </Grid>
    </Grid>
  )
}

export default SpecialtyInfo
