import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Button, Typography, useTheme, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import * as EditHospitalDialog from 'components/Dialog/EditHospitalDialog'
import DialogButton from 'components/Dialog/DialogButton'
import { useEmployer } from 'data/employer/actions'
import { useIsUser } from 'pages/Identity/Login'
import { showSnackbar } from 'data/snackbar/actions'
import { snackbarTypes } from 'data/constants'

const variant = 'contained'

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      padding: '12px',
    },
    hidden: {
      display: 'none',
    },
    submitProgress: {
      color: 'white',
    },
    editHospitalPictureWrapper: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
    maxFileText: {
      marginTop: '5px',
      fontSize: '.8em',
    },
  }),
)

export default function Buttons({
  spacing = 1,
  marginTop = undefined,
  alignItems = 'flexStart',
  justify = 'center',
  loading,
}) {
  const { isEmployerAdmin } = useIsUser()

  return !isEmployerAdmin ? (
    <></>
  ) : (
    <Grid
      container
      spacing={spacing}
      style={{ marginTop }}
      alignItems={alignItems}
      justifyContent={justify}
    >
      <Grid item>
        <UploadPhotoButton loading={loading} />
      </Grid>
      <Grid item>
        <EditProfileButton loading={loading} />
      </Grid>
    </Grid>
  )
}

export const EditProfileButton = () => {
  const classes = useStyles()
  const employerUser = useSelector(state => state.employerUser) || {}

  return (
    <DialogButton
      Module={EditHospitalDialog}
      className={classes.button}
      size='small'
      tooltip=''
      variant={variant}
      employerId={employerUser.employerId}
    >
      Edit Hospital Profile
    </DialogButton>
  )
}

export const UploadPhotoButton = ({ loading }) => {
  const dispatch = useDispatch()
  const classes = useStyles({ ierrornverted: true })
  const [{ id, name }, , setUser] = useEmployer()
  const [error, setError] = React.useState(null)
  const { layout } = useTheme()

  const onInput = React.useCallback(
    e => {
      setError(null)
      const uploadFiles = e.target.files
      const arrFiles = Array.from(uploadFiles)
      var file = arrFiles.length > 0 ? arrFiles[0] : undefined
      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          showSnackbar(dispatch, 'File exceeds 5 MB upload limit.', snackbarTypes.ERROR)
        } else {
          setUser({ id, name, imageFile: file }) // Proceed to upload file
        }
      } else {
        // Error: No file specified or user hit cancel
      }
    },
    [id, setError, setUser],
  )
  return (
    <div className={classes.editHospitalPictureWrapper}>
      {error && <Typography color='error'>{error}</Typography>}
      <input
        className={classes.hidden}
        name='fileselect'
        type='file'
        accept='*'
        id='fileselect'
        onChange={onInput}
      />
      <label htmlFor='fileselect'>
        <Button
          className={classes.button}
          onClick={onInput}
          size='small'
          component='span'
          variant={variant}
        >
          {loading ? (
            <CircularProgress size={layout.progressSize} className={classes.submitProgress} />
          ) : (
            <>Upload Hospital Photo</>
          )}
        </Button>
      </label>
      <Typography style={{ color: 'white'}}>
        <span className={classes.maxFileText}>Max file size: 5 MB</span>
      </Typography>
    </div>
  )
}
