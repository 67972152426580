import React from 'react'
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  useTheme,
} from '@mui/material'

const GenericRadioGroup = ({ value, label, name, handleChange, options, disabled }) => {
  const theme = useTheme()
  return (
    <FormControl
      variant='outlined'
      sx={{
        padding: '14px',
      }}
      disabled={disabled}
    >
      <FormLabel id={`${name}-label`} sx={{ color: theme.palette.primary.main }}>
        {label}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby={`${name}-label`}
        name={name}
        value={value || ''}
        defaultValue={value}
        onChange={event => handleChange(event)}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.id}
            value={option.id}
            control={<Radio checked={value === option.id} />}
            label={option.name}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default GenericRadioGroup
