export const buildFormData = obj => {
  var formData = new FormData()
  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key])) {
      if (obj[key].length === 0) {
        // Hate this, but will fix it when we fic the form data issues. Thanks GPT for the suggestion
        formData.append(key, '_EMPTY_')
      } else {
        obj[key].map(item => {
          formData.append(`${key}`, item)
        })
      }
    } else {
      const value = obj[key]
      // https://stackoverflow.com/questions/62303002/angular-9-formdata-appendkey-null-actually-appends-null-string
      if (value === undefined) {
        return
      }
      if (value === null) {
        formData.append(key, '')
      } else {
        formData.append(key, value)
      }
    }
  })
  return formData
}
