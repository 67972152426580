import axios from 'axios'
import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'
import { fetchDispatch } from 'data/helpers'
import { setJobsUpdated } from '../data/jobs/actions'
export const UPDATE_CANDIDATE_JOB_LOADING = 'candidateJob/UPDATE_CANDIDATE_JOB_LOADING'
export const UPDATE_CANDIDATE_JOB_SUCCESS = 'candidateJob/UPDATE_CANDIDATE_JOB_SUCCESS'
export const UPDATE_CANDIDATE_JOB_FAILURE = 'candidateJob/UPDATE_CANDIDATE_JOB_FAILURE'

export const FETCH_ALL_CANDIDATE_JOBS_LOADING = 'candidateJob/FETCH_ALL_CANDIDATE_JOBS_LOADING'
export const FETCH_ALL_CANDIDATE_JOBS_SUCCESS = 'candidateJob/FETCH_ALL_CANDIDATE_JOBS_SUCCESS'
export const FETCH_ALL_CANDIDATE_JOBS_FAILURE = 'candidateJob/FETCH_ALL_CANDIDATE_JOBS_FAILURE'
export const updateCandidateJobLoading = () => ({ type: UPDATE_CANDIDATE_JOB_LOADING })
export const updateCandidateJobSuccess = () => ({ type: UPDATE_CANDIDATE_JOB_SUCCESS })
export const updateCandidateJobFailure = payload => ({
  type: UPDATE_CANDIDATE_JOB_FAILURE,
  payload,
})

export const fetchAllCandidateJobsLoading = () => ({ type: FETCH_ALL_CANDIDATE_JOBS_LOADING })
export const fetchAllCandidateJobsSuccess = payload => ({
  type: FETCH_ALL_CANDIDATE_JOBS_SUCCESS,
  payload,
})
export const fetchAllCandidateJobsFailure = payload => ({
  type: FETCH_ALL_CANDIDATE_JOBS_FAILURE,
  payload,
})
export const fetchUpdateCandidateJob = query => {
  return async dispatch => {
    dispatch(updateCandidateJobLoading())
    try {
      const response = await axios({
        url: '/api/candidate_jobs',
        data: query,
        method: 'PUT',
      })
      dispatch(updateCandidateJobSuccess(response))
      dispatch(setJobsUpdated())
      const successMessage = query.interestedInContact
        ? 'You have successfully added this job to your list'
        : 'You have successfully removed this job from your list'
      dispatch(showSnackbar(successMessage, snackbarTypes.SUCCESS))
    } catch (error) {
      dispatch(showSnackbar('Encountered an error, please try again later ', snackbarTypes.ERROR))
      dispatch(updateCandidateJobFailure(error.response))
    }
  }
}

export const fetchAllCandidateJobData = () => {
  return async dispatch => {
    dispatch(fetchAllCandidateJobsLoading())
    try {
      const response = await fetchDispatch({
        path: '/api/candidate_jobs/all',
        method: 'GET',
      })
      dispatch(fetchAllCandidateJobsSuccess(response))
    } catch (error) {
      dispatch(showSnackbar('Failed to load data', snackbarTypes.ERROR))
      dispatch(fetchAllCandidateJobsFailure(error.response))
    }
  }
}

const initialState = {
  loading: false,
  errors: [],
  candidateJobs: [],
}

export const candidateJobReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case UPDATE_CANDIDATE_JOB_LOADING:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_CANDIDATE_JOB_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
      }
    case UPDATE_CANDIDATE_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        errors: payload,
      }
    case FETCH_ALL_CANDIDATE_JOBS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case FETCH_ALL_CANDIDATE_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        candidateJobs: payload.candidateJobs,
      }
    case FETCH_ALL_CANDIDATE_JOBS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: payload,
      }
    default:
      return {
        ...state,
      }
  }
}
