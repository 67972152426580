import React from 'react'
import { Modal, Grid, Typography, Button } from '@mui/material'

const FeatureIntroDialog = ({ open, onSkip, onClose, header, content, buttonText }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Grid
        container
        justifyContent='center'
        spacing={3}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          aligntext: 'center',
        }}
      >
        <Grid item>
          <Typography variant='h6' component='h2' gutterBottom>
            {header}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' gutterBottom>
            {content}
          </Typography>
        </Grid>
        <Grid container item direction='row' justifyContent='center' spacing={3}>
          <Grid item>
            <Button onClick={onSkip}>Skip Tour</Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose} variant='contained' color='primary'>
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default FeatureIntroDialog
