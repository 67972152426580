import React from 'react'
import DialogButton from 'components/Dialog/DialogButton'
import * as EmployerDialog from 'components/Dialog/EmployerDialog'
import AddIcon from '@mui/icons-material/Add'

export const defaultProps = {
  name: 'Employers',
  columns: [
    { name: 'id', header: 'Id' },
    { name: 'name', header: 'Name' },
    { name: 'userCount', header: '# Users' },
    { name: 'jobCount', header: '# Jobs' },
    {
      name: 'actions',
      header: 'Actions',
    },
  ],
  addComponent: (
    <DialogButton Module={EmployerDialog} variant='outlined'>
      <AddIcon />
      Add Employer
    </DialogButton>
  ),
  showCSV: true,
  searchableFields: ['name'],
}
