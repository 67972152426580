import HeaderUserInfo from 'components/Layout/Header/HeaderUserInfo'
import * as config from 'config'

export const candidateButtons = () => {
  const headers = [
    { Component: HeaderUserInfo, id: 'userInfo', type: 'rightstatic', width: 160 },
    { label: 'Jobs', link: '/jobs', width: 64 },
  ]
  if (config.get('showRealtors')) {
    headers.push({ label: 'Housing', link: '/realtors', isBeta: true, width: 64 })
  }
  return headers
}

export const adminButtons = [
  { label: 'Employers', link: '/super/employers', width: 110 },
  { label: 'Employer Users', link: '/super/employer_users', width: 80 },
  { label: 'Realtors', link: '/super/realtors', width: 80 },
  { label: 'Candidates', link: '/super/candidates', width: 80 },
  { label: 'Partner Interest', link: '/super/partner_interest', width: 80 },
  { label: 'Job Interest', link: '/super/job_interest', width: 80 },
]

export const publicButtons = handleRegister => {
  const headers = [
    { label: 'Jobs', link: '/jobs', width: 64 },
    { label: 'Housing', link: '/realtors', isBeta: true, width: 64 },
    { label: 'Health Systems', link: '/register/hospitals', width: 64 },
    { label: 'Sign In', link: '/login', variant: 'outlined', width: 85, type: 'collapse' },
    {
      label: 'Sign Up',
      type: 'collapse',
      link: '/register/physicians',
      variant: 'contained',
      onClick: handleRegister,
      width: 100,
    },
  ]
  return headers
}

export const hospitalButtons = [
  { label: 'Jobs', link: '/jobs', width: 64 },
  { label: 'Providers', link: '/physicians', width: 64 },
  { Component: HeaderUserInfo, id: 'userInfo', type: 'rightstatic', width: 160 },
]

export const hospitalButtonsV1 = [
  { label: 'Jobs', link: '/hospitals/jobs', width: 64 },
  { Component: HeaderUserInfo, id: 'userInfo', type: 'rightstatic', width: 160 },
]

export const profileHeaderButtons = ({ isEmployerAdmin, isEmployerUser, handleLogout }) => {
  const profileLink = isEmployerUser
    ? { label: 'My Profile', link: `/users/employer_user/profile` }
    : { label: 'My Profile', link: `/users/candidate/profile` }
  return [
    profileLink,
    {
      label: 'Hospital',
      link: '/hospitals/profile',
      hidden: !(isEmployerAdmin || isEmployerUser),
    },
    {
      label: 'Users',
      link: '/admin/users',
      hidden: !isEmployerAdmin,
    },
    {
      label: 'Filters',
      link: '/users/filters',
    },
    {
      label: 'Alerts',
      link: '/users/alerts',
    },
    { label: 'Logout', onClick: handleLogout },
  ]
}
