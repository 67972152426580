import React from 'react'
import { useDispatch } from 'react-redux'
import * as JobDialog from 'components/Dialog/JobDialog'
import { archiveJob } from 'data/jobs/actions'
import DialogButton, { EditDialogButton, DeleteDialogButton } from 'components/Dialog/DialogButton'

const EditableJobButtons = ({ job }) => {
  const dispatch = useDispatch()
  const archive = job.deletedAt === null
  return (
    <>
      <div className='joyride_add_job'>
        <DialogButton
          Module={JobDialog}
          size='small'
          variant={'iconbutton'}
          tooltip='Add new job'
          initialValues={{}}
        >
          Add Job
        </DialogButton>
      </div>
      <div className='joyride_edit_job'>
        <EditDialogButton
          Module={JobDialog}
          size='small'
          tooltip='Edit job'
          initialValues={job}
          mount={false}
        >
          Edit Job
        </EditDialogButton>
      </div>
      <div className='joyride_delete_job'>
        <DeleteDialogButton
          size='small'
          tooltip={job.deletedAt !== null ? 'Unarchive job' : 'Archive Job'}
          isDeleted={job.deletedAt !== null}
          onClick={() =>
            dispatch(archiveJob({ id: job.id, archive: archive, employerId: job.employer.id }))
          }
        >
          Delete Job
        </DeleteDialogButton>
      </div>
    </>
  )
}

export default EditableJobButtons
