import React from 'react'
import { useDispatch } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Grid, Box, useTheme, useMediaQuery } from '@mui/material'
import { fetchUpdateCandidate } from 'src/redux/candidate'
import { useChangeRoute } from 'data/hooks'
import GenericMultiLineTextField from 'components/UserFields/GenericMultiLineTextField'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

const AboutJob = ({ user, candidate }) => {
  const theme = useTheme()
  const below_lg = useMediaQuery(theme.breakpoints.down('md'))
  const changeRoute = useChangeRoute()

  const dispatch = useDispatch()

  const initialFormValues = {
    aboutJob: candidate.aboutJob,
  }

  const [values = {}, setValues] = React.useState(initialFormValues)

  React.useEffect(() => {
    setValues(initialFormValues)
  }, [candidate])

  const handleChange = event => {
    const { id, value } = event.target
    setValues({
      ...values,
      [id]: value,
    })
  }

  const handleSave = () => {
    const data = {
      aboutJob: values.aboutJob,
      id: user.id,
    }
    dispatch(
      fetchUpdateCandidate(data, 'Thanks for completing your profile, happy job hunting!', null, {
        function: changeRoute,
        params: '/jobs',
      }),
    )
    dispatch(
      fetchTrackEvent({
        eventName: 'onboarding_about_job_submit_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: user.email,
      }),
    )
  }

  const handleSkip = () => {
    dispatch(
      fetchTrackEvent({
        eventName: 'onboarding_about_job_skip_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: user.email,
      }),
    )
    changeRoute('/jobs')
  }

  return (
    <Box sx={{ border: '1px solid gray', padding: '20px', marginTop: '20px' }}>
      <Grid container direction='column'>
        <Grid item xs={12}>
          <GenericMultiLineTextField
            name='aboutJob'
            label='Describe your ideal job'
            placeholder='I am looking for a neurologist position in a city with a great education system and academic opportunities'
            value={values.aboutJob}
            handleChange={handleChange}
          />
        </Grid>
        <Grid
          container
          direction='row'
          alignItems='center'
          sx={[
            {
              padding: '10px',
              justifyContent: 'flex-start',
              marginBottom: '20px',
              marginTop: '20px',
            },
            below_lg && {
              zIndex: 1000,
              justifyContent: 'center',
              marginTop: '50px',
              position: 'fixed',
              bottom: '0px',
              left: '0px',
              right: '0px',
              marginBottom: '0px',
              backgroundColor: theme.palette.secondary.light,
            },
          ]}
        >
          <Grid item sx={{ paddingRight: '20px' }}>
            <Button onClick={handleSkip}>Complete Later</Button>
          </Grid>
          <LoadingButton
            type='submit'
            disabled={false}
            autoFocus
            variant='contained'
            onClick={handleSave}
            loading={candidate.loading}
          >
            Save & Start Your Search!
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AboutJob
