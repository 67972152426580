import React from 'react'
import Loader from 'components/Loader'
import { useSelector, useDispatch } from 'react-redux'
import { EmployersTable } from 'components/Table/EmployersTable'
import { EditDialogButton } from 'components/Dialog/DialogButton'
import * as EmployerDialog from 'components/Dialog/EmployerDialog'
import { fetchGetEmployers } from 'src/redux/employer'

const Employers = () => {
  const dispatch = useDispatch()
  const { loading = false, employers = [] } = useSelector(state => state.employer)
  const employerData = employers.map(employer => ({
    ...employer,
    actions: (
      <EditDialogButton
        id={EmployerDialog.dialogId}
        initialValues={employer}
        Module={EmployerDialog}
      />
    ),
  }))
  React.useEffect(() => {
    dispatch(fetchGetEmployers())
  }, [])

  return <>{loading ? <Loader /> : <EmployersTable data={employerData} />}</>
}
export default Employers
